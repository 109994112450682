import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getPayingNowAmount } from '../../../helpers/getPayingNowAmount';
import * as goal from '../../Goal/_redux/goalReducers';
import InvestmentPaymentDetailsPage from './InvestmentPaymentDetailsPage';
import './PaymentDetails/index.scss';

import './PaymentDetails/index.scss';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { fetchPromotionDetails } from '../_redux/investmentAction';
import { goalTopUp, investmentInGoal } from '../../Goal/_redux/goalActions';

import { getCardPaymentModal } from '../../../helpers/cardPaymentModal';
import AppyVoucher from './ApplyVoucher';
import PayWithBankTransfer from './PayWithBankTransfer';
import PayWithMpesa from './PayWithMpesa';

const handleGoalInvestment = async (id, textReference, channel) => {
  const response = await investmentInGoal(parseInt(id), textReference, channel);
  // This will help to know which congratulation text to dispay
  localStorage.setItem('investedIn', 'goal');
  return response;
};

const handleGoalTopUp = async (id, textReference, channel) => {
  const response = await goalTopUp(parseInt(id), textReference, channel);
  // This will help to know which congratulation text to dispay
  localStorage.setItem('investedIn', 'goal');
  return response;
};

const actions = [
  { id: 2, name: 'goalTopUp', invest: handleGoalTopUp },
  { id: 5, name: 'goalInvestment', invest: handleGoalInvestment },
];

const InvestorProfile = () => {
  const { id: oneGoalId } = useParams();
  let history = useHistory();

  const [goalData, setGoalData] = useState();
  const [useVoucher, setUseVoucher] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [payWithMpesa, showPayWithMpesa] = useState(false);
  const [payWithBankTransfer, showPayWithBankTransfer] = useState(false);
  const [actionName, setActionName] = useState();
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [showPaymentComponent, setShowPaymentComponent] = useState(true);

  const setPayWithBankTransfer = (bool) => {
    setShowPaymentComponent(!bool);
    showPayWithBankTransfer(bool);
  };

  const setPayWithMpesa = (bool) => {
    setShowPaymentComponent(!bool);
    showPayWithMpesa(bool);
  };

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem('goalDiscountInfo')
    );
    setDiscount({
      amount: localStorageData?.discountAmount,
      currency: localStorageData?.currency,
    });
    const localData = JSON.parse(localStorage.getItem('goalDataForPayment'));
    const action = JSON.parse(localStorage.getItem('action'));
    setActionName(action.name);
    if (localData?.amount && localStorageData?.discountAmount) {
      setTotalAmount(
        getPayingNowAmount(localData?.amount, localStorageData?.discountAmount)
      );
    }

    if (localData?.goalId) {
      setGoalData(localData);
    }
  }, []);

  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string()
      .min(8, 'Promo code must be 8 character long')
      .max(8, 'Promo code must be 8 character long'),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const initialValues = {
    promoCode: goalData?.promoCode,
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PromoCodeSchema,
  });

  const applyPromoCode = async () => {
    setAlertOn(false);
    if (!goalData.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Promo code can not be empty',
        alertMessageType: 'error',
      });
      return;
    }

    if (formik.errors.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: formik.errors.promoCode,
        alertMessageType: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      localStorage.setItem('promoCode', goalData.promoCode);
      const response = await fetchPromotionDetails(
        goalData.promoCode,
        goalData.currency
      );
      const {
        data: { data: { amount: discountAmount, currency } = {} } = {},
      } = response;
      setDiscount({ amount: discountAmount, currency });
      const amountTOPay = getPayingNowAmount(goalData?.amount, discountAmount);
      setTotalAmount(amountTOPay);

      localStorage.setItem(
        'goalDiscountInfo',
        JSON.stringify({
          discountAmount,
          currency,
          totalAmount: amountTOPay,
        })
      );
      setUseVoucher(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const payWithCreditCard = async (channel) => {
    setLoading(true);
    const { amount, currency } = goalData;
    try {
      const response = await getCardPaymentModal(
        convertAmountToNumber(amount),
        currency,
        goalData?.promoCode
          ? goalData?.promoCode
          : localStorage.getItem('promoCode'),
        channel
      );

      if (!response.data.data) {
        const { data } = response;
        actions.forEach(async (item) => {
          if (item.name === `${actionName}`) {
            const result = await item.invest(
              parseInt(oneGoalId),
              data.tx_ref,
              channel
            );

            if (result.status === 201) {
              setLoading(false);
              localStorage.removeItem('goalDataForPayment');
              localStorage.removeItem('goalDiscountInfo');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('action');
              history.push('/new-investment/congratulations');
              return;
            }
            return;
          }
        });
        return;
      }

      const { data: { data: { link } = {} } = {} } = response;
      localStorage.removeItem('goalDiscountInfo');
      localStorage.removeItem('promoCode');
      window.location.replace(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const renderPaymentOptions = () => {
    if (payWithMpesa) {
      return <PayWithMpesa setPayWithMpesa={setPayWithMpesa} />;
    } else if (payWithBankTransfer) {
      return (
        <PayWithBankTransfer
          totalAmount={totalAmount}
          discount={discount}
          amount={goalData?.amount}
          currency={goalData?.currency}
          profile={goalData?.promoCode}
          setPayWithBankTransfer={setPayWithBankTransfer}
        />
      );
    }
  };

  return (
    <>
      {showPaymentComponent ? (
        <>
          {useVoucher ? (
            <AppyVoucher
              alertOn={alertOn}
              alert={alert}
              formik={formik}
              getInputClasses={getInputClasses}
              goalPayload={goalData}
              handleChange={handleChange}
              setUseVoucher={setUseVoucher}
              applyPromoCode={applyPromoCode}
              loading={loading}
              setAlertOn={setAlertOn}
            />
          ) : (
            <InvestmentPaymentDetailsPage
              loading={loading}
              className="goal-payment-btn"
              currency={goalData?.currency}
              setAlertOn={setAlertOn}
              setAlert={setAlert}
              setUseVoucher={setUseVoucher}
              alert={alert}
              alertOn={alertOn}
              goalInvesting={true}
              goalData={goalData}
              discount={discount}
              totalAmount={totalAmount}
              setPayWithMpesa={setPayWithMpesa}
              setPayWithBankTransfer={setPayWithBankTransfer}
              payWithCreditCard={payWithCreditCard}
              activePaymentMethod={activePaymentMethod}
              setActivePaymentMethod={setActivePaymentMethod}
            />
          )}
        </>
      ) : (
        renderPaymentOptions()
      )}
    </>
  );
};

const mapStateToProps = ({ goal, profile }) => ({
  goal,
  profile,
});

export default connect(mapStateToProps, goal.actions)(InvestorProfile);
