/* eslint-disable jsx-a11y/anchor-is-valid */

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import '../styles/registration.scss';

import { Link, Redirect, Switch } from 'react-router-dom';

import AfterRegMessage from './AfterRegMessage';
import AppFooter from '../../../utils/appFooter';
import { ContentRoute } from '../../../../_metronic/layout';
import DisplayAppropriateContent from '../../Common/DisplayAppropriateContent';
import ExternaLeft from '../../Common/ExternalLeft';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import React from 'react';
import Registration from './Registration';
import ResendEmailVerification from './ResendEmailVerification';
import ResetPassword from './ResetPassword';
import SignupEmailVerification from './SignupEmailVerification';
import { useHistory } from 'react-router-dom';

export const AuthPage = () => {
  const history = useHistory();
  history.listen((location, action) => {});

  const displayAuthActionLink = () => {
    if (window.location.pathname === '/auth/login') {
      return (
        <>
          <span>Don't have an account yet ?</span>
          <Link
            to="/auth/registration"
            className="font-weight-bolder ml-2"
            id="kt_login_signup"
            style={{ color: '#0071CE' }}
          >
            Sign up!
          </Link>
        </>
      );
    } else if (window.location.pathname === '/auth/registration') {
      return (
        <>
          <span>Already have an account ?</span>
          <Link
            to="/auth/login"
            className="font-weight-bolder ml-2"
            id="kt_login_signup"
            style={{ color: '#0071CE' }}
          >
            Log in!
          </Link>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-15">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/success"
                  component={AfterRegMessage}
                />
                <ContentRoute
                  path="/auth/resendVerification"
                  component={ResendEmailVerification}
                />
                <ContentRoute
                  path="/auth/resetpassword"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/resetpasswordconfirmation"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/verify"
                  component={SignupEmailVerification}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};
