import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PaymentMethodRadioButton from '../../../Common/RadioButton/paymentMethod';

import InvestmentPaymentBreakdown from './PaymentBreakdown';
import './index.scss';
import { paymentMethod } from '../../../../helpers/constants';
import Alert from '../../../Common/Alert';

const NewPaymentDetails = ({
  setUseVoucher,
  payWithCreditCard,
  discount,
  amount,
  currency,
  subscription,
  planDetails,
  totalAmount,
  setPayWithMpesa,
  setPayWithBankTransfer,
  alert,
  alertOn,
  goalInvesting,
  goalData,
  profile,
  activePaymentMethod,
  setActivePaymentMethod,
  alertRef,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [payNowAmount, setpayNowAmount] = useState(0);

  const checkCurrency = () => {
    return currency === 'KES' || goalData?.currency === 'KES';
  };

  const cancelOperation = () => {
    localStorage.removeItem('goalDataForPayment');
    localStorage.removeItem('goal');
    localStorage.removeItem('discountInfo');
    localStorage.removeItem('goalDiscountInfo');
    localStorage.removeItem('action');
    localStorage.removeItem('planId');
    localStorage.removeItem('planMinimumInvestableAmounts');
    localStorage.removeItem('investedIn');
    localStorage.removeItem('promoCode');
    history.push('/dashboard');
  };

  const renderPaymentOptions = () => {
    return (
      <div>
        <p style={{ color: '#0071CE', fontWeight: '500' }}>
          Select a payment method
        </p>
        {checkCurrency() && (
          <PaymentMethodRadioButton
            label={'Mpesa '}
            processingDays="Transfer can take up to 1 day to complete"
            fee="+0 Fee"
            activePaymentMethod={activePaymentMethod}
            name={paymentMethod.MPESA}
            setActivePaymentMethod={setActivePaymentMethod}
          />
        )}
        {/* <PaymentMethodRadioButton
          label={'Kenyan credit card'}
          processingDays="Transfer can take up to 3 days to complete"
          fee="+2.9% Fees"
          activePaymentMethod={activePaymentMethod}
          name={paymentMethod.KENYAN_CARD}
          setActivePaymentMethod={setActivePaymentMethod}
        />

        <PaymentMethodRadioButton
          label={'International credit card'}
          processingDays="Transfer can take up to 3 days to complete"
          fee="+3.8% Fees"
          activePaymentMethod={activePaymentMethod}
          name={paymentMethod.INTERNATIONAL_CARD}
          setActivePaymentMethod={setActivePaymentMethod}
        /> */}

        <PaymentMethodRadioButton
          label={'Bank transfer '}
          processingDays="Transfer can take up to 2 days to complete"
          fee="+0 Fee"
          activePaymentMethod={activePaymentMethod}
          name={paymentMethod.BANK_TRANSFER}
          setActivePaymentMethod={setActivePaymentMethod}
        />
      </div>
    );
  };

  const dispatchPaymentMethod = () => {
    setLoading(true);

    if (activePaymentMethod === paymentMethod.MPESA) {
      setPayWithMpesa(true);
    } else if (
      activePaymentMethod === paymentMethod.KENYAN_CARD ||
      activePaymentMethod === paymentMethod.INTERNATIONAL_CARD
    ) {
      payWithCreditCard(activePaymentMethod);
    } else if (activePaymentMethod === paymentMethod.BANK_TRANSFER) {
      setPayWithBankTransfer(true);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="my-10 card card-custom  card-gutter-b">
      <div className="py-10 mx-auto responsive-width">
        {alertOn && (
          <div ref={alertRef} className="mb-5">
            <Alert alert={alert} />{' '}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold"></span>
        </div>
        <div className="d-flex justify-content-between">
          <span style={{ fontWeight: '600' }}></span>
        </div>

        {renderPaymentOptions()}
        {activePaymentMethod && (
          <>
            {goalInvesting ? (
              <InvestmentPaymentBreakdown
                discount={discount}
                amount={goalData.amount}
                currency={goalData.currency}
                subscription={subscription}
                totalAmount={totalAmount}
                activePaymentMethod={activePaymentMethod}
                profile={profile}
                setpayNowAmount={setpayNowAmount}
              />
            ) : (
              <InvestmentPaymentBreakdown
                discount={discount}
                amount={amount}
                currency={currency}
                subscription={subscription}
                planDetails={planDetails}
                totalAmount={totalAmount}
                activePaymentMethod={activePaymentMethod}
                profile={profile}
                setpayNowAmount={setpayNowAmount}
              />
            )}
          </>
        )}
      </div>

      {!planDetails?.isCurrentOffer ? ( //Plans on offer shouldn't have voucher redemption
        <p className="text-center">
          Do you have a discount code?{' '}
          <span
            onClick={() => setUseVoucher(true)}
            className="cursor-pointer"
            style={{ color: '#0071CE', fontWeight: '700' }}
          >
            Apply code
          </span>
        </p>
      ) : null}

      <div className="text-center mx-5 my-10">
        <span
          onClick={cancelOperation}
          className="px-7 py-4 text-center cursor-pointer margin-left display-small-block btn-size"
          style={{
            background: '#CAE2F6',
            borderRadius: '5px',
            color: '#0071CE',
          }}
        >
          Cancel
        </span>
        {activePaymentMethod ? (
          <span
            className={`px-5 py-4 text-center cursor-pointer margin-left display-small-block btn-size common-btn`}
            onClick={() => dispatchPaymentMethod()}
          >
            Pay now
            {loading && activePaymentMethod && !alertOn && (
              <span className="mx-3 spinner spinner-white" />
            )}
          </span>
        ) : (
          <span className="px-5 py-4 text-center margin-left display-small-block btn-size disabled-btn">
            Pay now
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ profile }) => ({
  profile,
});

export default connect(mapStateToProps, null)(NewPaymentDetails);
