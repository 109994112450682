import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../Common/AllButtons';
import GoalPieChart from '../../Common/GoalPieChart';
import { PageTitle } from '../../Common/PageTitle';
import RiskProfile from '../components/RiskProfile';
import { getPieChartDataByProfile } from '../_redux/profileaxiosRequest';
import * as pieChartActions from '../_redux/profileReducers';

const RiskProfilePage = ({ intl, getPieChartData }) => {
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const { pieChartData } = useSelector((state) => state.pieChartData);

  const generatePieChartData = async () => {
    try {
      const response = await getPieChartDataByProfile(profile.riskProfile.id);
      getPieChartData(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    localStorage.removeItem('retakingWizard');
    profile.riskProfile.id && generatePieChartData();
  }, []);
  return (
    <>
      <PageTitle title={'Your Investment Personality'} insideContainer />
      <div className="mt-25">
        <RiskProfile profile={profile} />

        {pieChartData ? (
          <div className="profile-container py-10 px-5 mt-5 text-center">
            <h2 className="chart-title font-weight-bold">
              {intl.formatMessage({ id: 'PROFILE_PAGE.PORTFOLIO' })}
            </h2>
            <div className="chart-sub-title font-weight-bold">
              {intl.formatMessage({ id: 'PROFILE_PAGE.PORTFOLIO_SUBTITLE' })}
            </div>

            <GoalPieChart
              data={[pieChartData['FIXED'], pieChartData['VARIABLE']]}
            />
          </div>
        ) : null}

        <div className="mt-5">
          <Button
            buttonText={intl.formatMessage({ id: 'BUTTONS.RETAKE_WIZARD' })}
            onClick={() => {
              localStorage.setItem('retakingWizard', true);
              history.push('/wizard/investment-experience');
            }}
            classes={'w-100 bg-red white-text font-size-20 py-5'}
          />
        </div>

        <p className="font-size-11 mt-5 text-justify">
          {intl.formatMessage({ id: 'PROFILE_PAGE.DISCLAIMER' })}
        </p>
      </div>
    </>
  );
};

export default injectIntl(
  connect(null, { ...pieChartActions.actions })(RiskProfilePage)
);
