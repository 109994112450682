import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { getUserProfile } from '../../Account/_redux/profile/profileActions';
import * as profile from '../../Account/_redux/profile/profielReducers';
import RiskProfile from '../../Onboarding/pages/RiskProfile';
import Button from '../../Common/Button';
import InvestAndSaveIcon from '../../../icons/investBtnIcon.svg';

function InvestorType({ userData, profileAction }) {
  const history = useHistory();
  const [onInvestorType, setOnInvestorType] = useState(false);

  useEffect(() => {
    if (history.location.pathname === '/investor-type') {
      setOnInvestorType(true);
    }

    const getData = async () => {
      const { data: { data: userProfile } = {} } = await getUserProfile();
      profileAction(userProfile);
    };
    getData();
  }, [history.location.pathname, profileAction]);

  const toWizardBtn = (
    <div className="text-center my-10 mx-10">
      <Link
        to={{
          pathname: '/wizard/age',
          state: { prevPath: history.location.pathname },
        }}
      >
        <Button
          btnText="Re-take test to recalculate your risk profile"
          className="common-btn pl-13 pr-15 py-3 mb-3"
          onClick={() => localStorage.setItem('retakingTheWizard', true)}
        />
      </Link>

      <Button
        btnText={[
          <img
            className="mr-3"
            src={InvestAndSaveIcon}
            alt={'Invest and save icon'}
            key="image"
          />,
          'Invest now',
        ]}
        className="common-btn pl-13 pr-15 py-3 ml-5"
        onClick={() => history.push('/new-investment')}
      />
    </div>
  );
  return (
    <>
      <RiskProfile
        toWizardBtn={toWizardBtn}
        onInvestorType={onInvestorType}
        userProfileInfo={userData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  profile: state.auth.authToken,
  userData: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(InvestorType);
