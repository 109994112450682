import React from 'react';
import { useHistory } from 'react-router-dom';

export const Button = ({
  link,
  buttonText,
  classes,
  buttonType,
  onClick,
  buttonIcon,
  disabled,
  externalLink,
  loading,
}) => {
  const history = useHistory();
  return (
    <button
      onClick={
        link
          ? () =>
              externalLink
                ? window.open('https://ndovu.co/ndovu-academy/', '_blank')
                : history.push(link)
          : onClick
          ? (e) => onClick(e)
          : null
      }
      className={`${classes} next-button border-radius-12 font-weight-bolder`}
      type={buttonType ? buttonType : 'button'}
      disabled={disabled}
    >
      <div
        className={
          buttonIcon
            ? 'd-flex justify-content-between align-items-center'
            : null
        }
      >
        <div>
          {buttonText}{' '}
          {loading ? <span className="ml-5 spinner spinner-white" /> : null}
        </div>
        {buttonIcon ? (
          <img className="ml-3" src={buttonIcon} alt="New Goal Icon" />
        ) : null}
      </div>
    </button>
  );
};
