import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../_metronic/layout';
import * as goal from './modules/Goal/_redux/goalReducers';
import InvestorType from './modules/Account/components/InvestorType';
import ReferralPromo from './modules/Account/components/ReferralPromo';
import Loader from './modules/Common/Loader';
import Dashboard from './modules/Dashboard/pages/dashboard';
import Portfolio from './modules/Portfolio/index';
import {
  getRiskProfileLevels,
  getUserProfile,
} from './modules/Account/_redux/profile/profileActions';
import * as profile from './modules/Account/_redux/profile/profielReducers';
import Transaction from './modules/Transaction/pages/Transaction';
import { BuilderPage } from './pages/BuilderPage';
import SessionTimeout from './utils/sessionTimeout';
import { useClearCache } from 'react-clear-cache';
import { fetchCurrencies } from './modules/Account/_redux/currencies/currencyActions';
import * as currenciesActions from './modules/Account/_redux/currencies/currenciesReducers';
import * as conversionActions from './modules/Account/_redux/conversions/conversionReducers';
import { fetchConversions } from './modules/Account/_redux/conversions/conversionActions';
import Invest from './modules/Invest';
import Plan from './modules/Plan';
import FinalPage from './modules/Invest/components/FinalPage';
import Goal from './modules/Goal';
import InvestorProfile from './modules/Profile/pages/profile';
import RiskProfilePage from './modules/Profile/pages/RiskProfile';

import HowItWorks from './modules/Instructions/Pages/HowItWorks';
import SetupYourGoal from './modules/Instructions/Pages/SetupYourGoal';
import WelcomePage from './modules/Instructions/Pages/WelcomePage';
import Onboarding from './modules/Onboarding';
import WizardPage from './modules/Wizard/pages/Wizard';

const BasePage = ({
  profileAction,
  profile,
  riskProfileLevels,
  currencyAction,
  conversionAction,
}) => {
  const [waiting, setWaiting] = useState(true);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
    const getData = async () => {
      const {
        data: { data },
      } = await getRiskProfileLevels();
      const {
        data: { data: allCurrencies },
      } = await fetchCurrencies();
      const { data: { data: allConverstions } = {} } = await fetchConversions();
      currencyAction(allCurrencies);
      conversionAction(allConverstions);
      riskProfileLevels(data);
    };

    getData();
  }, []);

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      const { data: { data: userProfile } = {} } = await getUserProfile();
      localStorage.setItem(
        'referralCompleted',
        userProfile?.isReferralCompleted
      );
      profileAction(userProfile);
      isMounted && setWaiting(false);
    };
    getData();
    return () => {
      isMounted = false;
    };
  }, [profileAction]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SessionTimeout isAuthenticated={true} />
      {waiting ? (
        <div className="mx-auto my-auto">
          <Loader />
        </div>
      ) : (
        <Switch>
          <ContentRoute path="/dashboard" component={Dashboard} />
          <ContentRoute
            path="/referal-promo"
            component={() => <ReferralPromo profile={profile} />}
          />
          <ContentRoute path="/wizard" component={WizardPage} />
          <ContentRoute path="/onboarding" component={Onboarding} />
          <ContentRoute path="/welcome" component={WelcomePage} />
          <ContentRoute path="/how-it-works" component={HowItWorks} />
          <ContentRoute path="/setup-your-goal" component={SetupYourGoal} />
          <ContentRoute path="/investor-type" component={InvestorType} />
          <ContentRoute path="/profile" component={InvestorProfile} />
          <ContentRoute path="/risk-profile" component={RiskProfilePage} />
          <ContentRoute path="/new-investment" component={Invest} />{' '}
          {/* TODO: To be removed */}
          <ContentRoute path="/plans" component={Plan} />
          <ContentRoute path="/portfolio/goal" component={Goal} />
          <ContentRoute path="/portfolio" component={Portfolio} />
          <ContentRoute path="/transaction" component={Transaction} />
          <ContentRoute path="/payment-status" component={FinalPage} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <Redirect to="error/error-v1" />
        </Switch>
      )}
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  ...profile.actions,
  ...goal.actions,
  ...currenciesActions.actions,
  ...conversionActions.actions,
})(BasePage);
