import React, { useEffect, useState } from "react";

import DiscountActive from "./DiscountActive";
import PropTypes from "prop-types";
import Referral from "./Referal";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function ReferralPromo({ profile }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    return () => {
      setValue(1);
    };
  }, []);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
            className="mt-5 text-center"
          >
            <Tab label="Referral Code" />
            <Tab label="Corporate Code" />
          </Tabs>

          {value === 0 && <Referral profile={profile} />}
          {value === 1 && (
            <DiscountActive profile={profile} setValue={setValue} />
          )}
        </div>
      </div>
    </>
  );
}

export default ReferralPromo;
