import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CommonButton from '../../../Common/Button';
import investBtnIcon from '../../../../icons/investBtnIcon.svg';
import yellowStar from '../../../../icons/yellowStar.svg';
import whiteStar from '../../../../icons/whiteStar.svg';
import MessageAlert from '../../../Common/MessageAlert';

const AddToWatchlist = ({
  isWatchList,
  deleteWatchlist,
  addToTheWatchList,
  isInvested,
  planId,
  fundManager,
  planName,
  withdrawalError,
  currency,
  investable,
}) => {
  const [showWithdrawalError, setShowWithdrawalError] = useState(false);

  const history = useHistory();
  return (
    <div className="my-5 pb-5 card blue-border">
      <div className="text-center mt-10 fund-title">Fund manager</div>
      <div className="p-5 d-flex align-items-center performance-container">
        <div className="width">
          <div className="d-flex align-items-center mb-2">
            <div
              style={{
                width: '150px',
                padding: '5px',
                border: '1px solid #c4c4c4',
                borderRadius: '5px',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src={fundManager?.logo}
                alt="Fund Manager Logo"
              />
            </div>
            <div className="ml-3 d-flex flex-column">
              <span className="title">{planName}</span>
              <small className="sub-title">
                {fundManager?.longName
                  ? fundManager?.longName
                  : fundManager?.fundname}
              </small>
            </div>
          </div>
        </div>
        <div className="width margin-left-50">
          <CommonButton
            icon={investBtnIcon}
            alt="Dollar Icon"
            btnText={
              investable
                ? isInvested
                  ? 'Top Up'
                  : 'Invest Now'
                : 'Investment not available'
            }
            className={
              investable
                ? 'margin-top-20 text-center btn-size btn-responsive-width common-btn mb-2 mr-2'
                : 'mt-5 text-center btn btn-secondary center-btn px-3 mb-5 mr-2'
            }
            disabled={investable ? false : true}
            onClick={() => {
              if (withdrawalError) {
                return setShowWithdrawalError(true);
              }
              localStorage.removeItem('promoCode');
              localStorage.removeItem('discountInfo');
              localStorage.removeItem('subscriptionId');
              localStorage.removeItem('goalDiscountInfo');
              const setPlanAction = isInvested
                ? 'planInvestmentTopup'
                : 'directInvestment';
              localStorage.setItem(
                'action',
                JSON.stringify({
                  name: setPlanAction,
                  id: isInvested?.id,
                })
              );
              localStorage.setItem('planId', planId);
              localStorage.setItem('planCurrency', currency);
              const redirectUrl = isInvested
                ? `/plans/topup/${isInvested?.id}`
                : `/plans/initialInvestmentAmount/${planId}`;
              history.push(redirectUrl);
            }}
          />

          <CommonButton
            icon={isWatchList ? yellowStar : whiteStar}
            alt="Dollar Icon"
            btnText={isWatchList ? 'Remove from Watchlist' : 'Add to Watchlist'}
            className={`margin-top-10 text-center watchlist-btn`}
            onClick={
              isWatchList
                ? () => deleteWatchlist(isWatchList?.id)
                : addToTheWatchList
            }
          />
        </div>
      </div>
      {showWithdrawalError && (
        <div className="pl-5 pr-5">
          <MessageAlert
            closeAlert={() => setShowWithdrawalError(false)}
            alert={withdrawalError}
          />
        </div>
      )}
    </div>
  );
};

export default AddToWatchlist;
