import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { operationType, transactionType } from '../../../helpers/constants';
import { currencyHelper } from '../../../helpers/currencyHelper';
import PopoverComponent from '../../Common/PopoverComponent';
import transactionPopup from '../../Common/Popup/transactionPopup';
import NumberOfRows from '../../Common/SelectNumberOfRows/index';
import {
  capitalizeTheFirstLetterOfEachWord,
  firstLetterUpperCase,
} from '../../../helpers/investmentHelpers';
// import {
//   capitalizeTheFirstLetterOfEachWord,
//   firstLetterUpperCase,
// } from '../../GoalSetting/pages/Goals/Helper';
import NoIinvestmentMade from '../../Portfolio/components/NoInvestmentMade';
// import NoIinvestmentMade from '../../GoalSetting/pages/MyGoals/NoInvestmentMade';
import { fetchTransactions } from '../_redux/transactionCrud';
import './index.scss';
import {
  archivedTransactionsHeader,
  generalTransactionsHeader,
  goalTransactionsHeader,
} from './transactionsHeader';

const Transaction = () => {
  const [goalTransactions, setGoalTransactions] = useState([]);
  const [investmentTransactions, setInvestmentTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [goalPage, setGoalPage] = useState(1);
  const [goalLimit, setGoalLimit] = useState(10);
  const [goalTotalRecord, setGoalTotalRecord] = useState(0);
  const [goalTotalPages, setGoalTotalPages] = useState(0);
  const [investmentPage, setInvestmentPage] = useState(1);
  const [investmentLimit, setInvestmentLimit] = useState(10);
  const [investmentTotalRecord, setInvestmentTotalRecord] = useState(0);
  const [investmentTotalPages, setInvestmentTotalPages] = useState(0);
  const [archivedPage, setArchivedPage] = useState(1);
  const [archivedLimit, setArchivedLimit] = useState(10);
  const [archivedTotalRecord, setArchivedTotalRecord] = useState(0);
  const [archivedTotalPages, setArchivedTotalPages] = useState(0);
  const [archivedTransactions, setArchivedTransactions] = useState([]);
  useEffect(() => {
    setLoading(true);
    const getTransactions = async (investmentType) => {
      if (investmentType === transactionType.GOAL) {
        let res = await fetchTransactions(investmentType, goalPage, goalLimit);
        setGoalTotalRecord(res.data.totalItems);
        setGoalTotalPages(res.data.totalPages);
        setGoalTransactions(res.data.data);
      } else if (investmentType === transactionType.DIRECT) {
        let res = await fetchTransactions(
          investmentType,
          investmentPage,
          investmentLimit
        );
        setInvestmentTotalRecord(res.data.totalItems);
        setInvestmentTotalPages(res.data.totalPages);
        setInvestmentTransactions(res.data.data);
      } else {
        const res = await fetchTransactions(
          investmentType,
          investmentPage,
          archivedLimit
        );
        setArchivedTotalRecord(res.data.totalItems);
        setArchivedTotalPages(res.data.totalPages);
        setArchivedTransactions(res.data.data);
      }
      setLoading(false);
    };

    getTransactions(transactionType.GOAL);
    getTransactions(transactionType.DIRECT);
    getTransactions(transactionType.ARCHIVED);
  }, []);

  const handleGoalChangePage = async (event, value) => {
    let res = await fetchTransactions(transactionType.GOAL, value, goalLimit);
    setGoalPage(value);
    if (res.status === 200) {
      setGoalTotalRecord(res.data.totalItems);
      setGoalTransactions(res.data.data);
    }
  };

  const handleInvestmentChangePage = async (event, value) => {
    const res = await fetchTransactions(
      transactionType.DIRECT,
      value,
      investmentLimit
    );
    setInvestmentPage(value);
    if (res.status === 200) {
      setInvestmentTotalRecord(res.data.totalItems);
      setInvestmentTransactions(res.data.data);
    }
  };

  const handleArchivedChangePage = async (event, value) => {
    const res = await fetchTransactions(
      transactionType.ARCHIVED,
      value,
      archivedLimit
    );
    setArchivedPage(value);
    if (res.status === 200) {
      setArchivedTotalRecord(res.data.totalItems);
      setArchivedTransactions(res.data.data);
    }
  };

  const handleChangeLimit = async (event) => {
    const { value: selectedValue } = event.target;
    if (event.target.name === 'goalLimit') {
      let res = await fetchTransactions(transactionType.GOAL, 1, selectedValue);
      setGoalLimit(selectedValue);
      setGoalPage(1);
      if (res.status === 200) {
        setGoalTotalRecord(res.data.totalItems);
        setGoalTotalPages(res.data.totalPages);
        setGoalTransactions(res.data.data);
      }
    } else if (event.target.name === 'investmentLimit') {
      let res = await fetchTransactions(
        transactionType.DIRECT,
        1,
        selectedValue
      );
      setInvestmentLimit(selectedValue);
      setInvestmentPage(1);
      if (res.status === 200) {
        setInvestmentTotalRecord(res.data.totalItems);
        setInvestmentTotalPages(res.data.totalPages);
        setInvestmentTransactions(res.data.data);
      }
    } else {
      let res = await fetchTransactions(
        transactionType.ARCHIVED,
        1,
        selectedValue
      );
      setArchivedLimit(selectedValue);
      setArchivedPage(1);
      if (res.status === 200) {
        setArchivedTotalRecord(res.data.totalItems);
        setArchivedTotalPages(res.data.totalPages);
        setArchivedTransactions(res.data.data);
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <div className="card card-custom card-gutter-b">
      <div className="card-header">
        <div className="card-title">
          <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
            Transactions
          </h2>
        </div>
      </div>
      {goalTransactions &&
      goalTransactions.length < 1 &&
      investmentTransactions &&
      investmentTransactions.length < 1 &&
      archivedTransactions &&
      archivedTransactions.length < 1 ? (
        <NoIinvestmentMade />
      ) : (
        <>
          {investmentTransactions && investmentTransactions.length > 0 && (
            <>
              <h6
                style={{ fontSize: '18px' }}
                className="mt-10 text-center text-dark"
              >
                General Investing
              </h6>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {generalTransactionsHeader.map((header) => (
                          <th
                            style={{ color: '#0071CE', minWidth: header.width }}
                            scope="col"
                          >
                            {header.columnName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {investmentTransactions?.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="td-font-size-14">
                            {transaction?.createdAt
                              ? transaction?.createdAt.split('T')[0]
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.coolName
                              ? transaction?.planSubscription?.plan?.coolName
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.fundManager
                              ?.fundname
                              ? transaction?.planSubscription?.plan?.fundManager
                                  ?.fundname
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.convertedFinalAmount &&
                            transaction?.finalCurrency
                              ? currencyHelper(
                                  transaction?.convertedFinalAmount,
                                  transaction?.finalCurrency
                                )
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.operationType
                              ? firstLetterUpperCase(transaction?.operationType)
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.transactionStatus ? (
                              <span
                                className={`badge badge_${transaction.transactionStatus?.toLowerCase()} text-white`}
                                style={{ width: '138px' }}
                              >
                                {capitalizeTheFirstLetterOfEachWord(
                                  transaction?.transactionStatus?.replace(
                                    '_',
                                    ' '
                                  )
                                )}
                              </span>
                            ) : (
                              '---'
                            )}
                          </td>

                          {transaction?.operationType ===
                            operationType.DEPOSIT && (
                            <td className="td-font-size-14">
                              <PopoverComponent
                                popoverContent={transactionPopup(
                                  transaction,
                                  transaction.planSubscription.plan
                                )}
                              >
                                <span style={{ cursor: 'pointer' }}>
                                  <i
                                    style={{
                                      color: '#0071CE',
                                    }}
                                    className="ki ki-bold-more-hor"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </PopoverComponent>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{ borderBottom: '0.6px solid #ebedf3' }}
                    className="divider"
                  />
                  <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                    {investmentTotalPages && investmentTotalPages > 0 ? (
                      <Pagination
                        page={investmentPage}
                        count={investmentTotalPages}
                        onChange={handleInvestmentChangePage}
                      />
                    ) : (
                      ''
                    )}
                    {investmentTotalRecord && investmentTotalRecord > 10 ? (
                      <NumberOfRows
                        handleChangeLimit={handleChangeLimit}
                        totalRecord={investmentTotalRecord}
                        name="investmentLimit"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {goalTransactions && goalTransactions.length > 0 && (
            <>
              <h6
                style={{ fontSize: '18px' }}
                className="mt-10 text-center text-dark"
              >
                Goal-based Investing
              </h6>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {goalTransactionsHeader.map((header) => (
                          <th
                            key={header.columnName}
                            style={{ color: '#0071CE', minWidth: header.width }}
                            scope="col"
                          >
                            {header.columnName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {goalTransactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="td-font-size-14">
                            {transaction?.createdAt
                              ? moment(transaction?.createdAt).format(
                                  'YYYY-MM-DD'
                                )
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.coolName
                              ? transaction?.planSubscription?.plan?.coolName
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.fundManager
                              ?.fundname
                              ? transaction?.planSubscription?.plan?.fundManager
                                  ?.fundname
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.goal?.name
                              ? transaction?.goal?.name
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.convertedFinalAmount
                              ? currencyHelper(
                                  transaction?.convertedFinalAmount,
                                  transaction?.finalCurrency
                                )
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.operationType
                              ? firstLetterUpperCase(transaction?.operationType)
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.transactionStatus ? (
                              <span
                                className={`badge badge_${transaction?.transactionStatus?.toLowerCase()} text-white`}
                                style={{ width: '138px' }}
                              >
                                {capitalizeTheFirstLetterOfEachWord(
                                  transaction?.transactionStatus?.replace(
                                    '_',
                                    ' '
                                  )
                                )}
                              </span>
                            ) : (
                              '---'
                            )}
                          </td>

                          {transaction?.operationType ===
                            operationType.DEPOSIT && (
                            <td className="td-font-size-14">
                              <PopoverComponent
                                popoverContent={transactionPopup(
                                  transaction,
                                  transaction.planSubscription?.plan
                                )}
                              >
                                <span>
                                  <i
                                    style={{
                                      color: '#0071CE',
                                    }}
                                    className="ki ki-bold-more-hor"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </PopoverComponent>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{ borderBottom: '0.6px solid #ebedf3' }}
                    className="divider"
                  />
                  <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                    {goalTotalPages && goalTotalPages > 0 ? (
                      <Pagination
                        page={goalPage}
                        count={goalTotalPages}
                        onChange={handleGoalChangePage}
                      />
                    ) : (
                      ''
                    )}
                    {goalTotalRecord && goalTotalRecord > 10 ? (
                      <NumberOfRows
                        handleChangeLimit={handleChangeLimit}
                        totalRecord={goalTotalRecord}
                        name="goalLimit"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {archivedTransactions && archivedTransactions.length > 0 && (
            <>
              <h6
                style={{ fontSize: '18px' }}
                className="mt-10 text-center text-dark"
              >
                Archived Transactions
              </h6>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {archivedTransactionsHeader.map((header) => (
                          <th
                            key={header.columnName}
                            style={{ color: '#0071CE', minWidth: header.width }}
                            scope="col"
                          >
                            {header.columnName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {archivedTransactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="td-font-size-14">
                            {transaction?.createdAt
                              ? moment(transaction?.createdAt).format(
                                  'YYYY-MM-DD'
                                )
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.coolName
                              ? transaction?.planSubscription?.plan?.coolName
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.fundManager
                              ?.fundname
                              ? transaction?.planSubscription?.plan?.fundManager
                                  ?.fundname
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.goal?.name
                              ? transaction?.goal?.name
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.convertedFinalAmount
                              ? currencyHelper(
                                  transaction?.convertedFinalAmount,
                                  transaction?.finalCurrency
                                )
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.operationType
                              ? firstLetterUpperCase(transaction?.operationType)
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.archivedReason
                              ? capitalizeTheFirstLetterOfEachWord(
                                  transaction?.archivedReason?.replace('_', ' ')
                                )
                              : '---'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction.refunded ? 'Yes' : 'No'}
                          </td>

                          <td className="td-font-size-14">
                            {transaction?.transactionStatus ? (
                              <span
                                className="badge badge_archived text-white"
                                style={{ width: '138px' }}
                              >
                                {capitalizeTheFirstLetterOfEachWord(
                                  transaction?.transactionStatus?.replace(
                                    '_',
                                    ' '
                                  )
                                )}
                              </span>
                            ) : (
                              '---'
                            )}
                          </td>

                          {transaction?.operationType ===
                            operationType.DEPOSIT && (
                            <td className="td-font-size-14">
                              <PopoverComponent
                                popoverContent={transactionPopup(
                                  transaction,
                                  transaction.planSubscription?.plan
                                )}
                              >
                                <span>
                                  <i
                                    style={{
                                      color: '#0071CE',
                                    }}
                                    className="ki ki-bold-more-hor"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </PopoverComponent>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{ borderBottom: '0.6px solid #ebedf3' }}
                    className="divider"
                  />
                  <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                    {archivedTotalPages && archivedTotalPages > 0 ? (
                      <Pagination
                        page={archivedPage}
                        count={archivedTotalPages}
                        onChange={handleArchivedChangePage}
                      />
                    ) : (
                      ''
                    )}
                    {archivedTotalRecord && archivedTotalRecord > 10 ? (
                      <NumberOfRows
                        handleChangeLimit={handleChangeLimit}
                        totalRecord={archivedTotalRecord}
                        name="archivedLimit"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Transaction;
