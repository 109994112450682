import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import PayWithMpesa from './components/PayWithMpesa';
import BackArrow from '../Common/BackArrow';
import { getPayingNowAmount } from '../../helpers/getPayingNowAmount';
import convertAmountToNumber from '../../helpers/convertAmountToNumber';
import {
  directInvestment,
  fetchSinglePlanDetails,
  fundTopUp,
} from '../Plan/_redux/plansActions';
import { fetchPromotionDetails } from './_redux/investmentAction';
import { getCardPaymentModal } from '../..//helpers/cardPaymentModal';
import PayWithBankTransfer from './components/PayWithBankTransfer';
import InvestmentPaymentDetailsPage from './components/InvestmentPaymentDetailsPage';
import AppyVoucher from './components/ApplyVoucher';

const handleFundTopUp = async (
  planSubscribtionId,
  textReference,
  channel,
  transactionId
) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel,
    parseInt(transactionId)
  );
  // This will help to know which congratulation text to dispay
  localStorage.setItem('investedIn', 'fund');
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId)
  );
  // This will help to know which congratulation text to dispay
  localStorage.setItem('investedIn', 'fund');
  return response;
};

const actions = [
  { id: 3, name: 'investmentTopup', invest: handleFundTopUp },
  { id: 4, name: 'planInvestmentTopup', invest: handleFundTopUp },
  { id: 6, name: 'directInvestment', invest: handleDirectInvestment },
];

const PlanInvestmentPayment = () => {
  let history = useHistory();
  const { subscription } = useSelector((state) => state.profile);
  const [goalPayload, setGoalPayload] = useState();
  const [planDetails, setPlanDetails] = useState();

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [useVoucher, setUseVoucher] = useState(false);
  const [planId, setPlanId] = useState();
  const [payWithMpesa, setPayWithMpesa] = useState(false);
  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  useEffect(() => {
    const prevGoalData = JSON.parse(localStorage.getItem('goalDataForPayment'));
    const localStorageData = JSON.parse(localStorage.getItem('discountInfo'));
    const getPlanId = localStorage.getItem('planId');
    getPlanId && setPlanId(getPlanId);
    setDiscount({
      amount: localStorageData?.discountAmount,
      currency: localStorageData?.currency,
    });
    if (prevGoalData?.amount && localStorageData?.discountAmount) {
      setTotalAmount(
        getPayingNowAmount(
          convertAmountToNumber(prevGoalData?.amount),
          localStorageData?.discountAmount
        )
      );
    }

    if (prevGoalData?.planId || prevGoalData?.fundId) {
      setGoalPayload(prevGoalData);
    }
  }, []);

  const initialValues = {
    promoCode: goalPayload?.promoCode,
  };

  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string()
      .min(8, 'Promo code must be 8 character long')
      .max(8, 'Promo code must be 8 character long'),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  // Change Voucher code
  const handleChange = (event) => {
    const { name, value } = event.target;
    setGoalPayload({
      ...goalPayload,
      [name]: value,
    });
  };

  const fetchPlanDetails = async (id) => {
    try {
      const res = await fetchSinglePlanDetails(id);
      if (res.status === 200) {
        setPlanDetails(res.data.data);
      }
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const alertRef = useRef(null);

  useEffect(() => {
    planId && fetchPlanDetails(planId);
  }, [planId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PromoCodeSchema,
  });

  const payWithCreditCard = async (channel) => {
    const action = JSON.parse(localStorage.getItem('action'));
    if (!goalPayload?.planId && !goalPayload?.fundId) {
      history.push('/new-investment/plans');
      return;
    }
    setLoading(true);
    const { amount, currency } = goalPayload;
    try {
      const response = await getCardPaymentModal(
        convertAmountToNumber(amount),
        currency,
        goalPayload?.promoCode || localStorage.getItem('promoCode'),
        channel
      );

      if (!response.data.data) {
        const { data } = response;
        const id = goalPayload.planId ? goalPayload.planId : goalPayload.fundId;
        actions.forEach(async (item) => {
          if (item.name === `${action.name}`) {
            const result = await item.invest(
              parseInt(id),
              data.tx_ref,
              channel
            );

            if (result.status === 201) {
              setLoading(false);
              localStorage.removeItem('goalDataForPayment');
              localStorage.removeItem('discountInfo');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('planCurrency');
              localStorage.removeItem('promoPlanOnOffer');
              history.push('/new-investment/congratulations');
              return;
            }
          }
        });
        return;
      }
      localStorage.removeItem('discountInfo');
      localStorage.removeItem('promoCode');
      const { data: { data: { link } = {} } = {} } = response;
      window.location.replace(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      alertRef.current.scrollIntoView();
    }
  };

  const applyPromoCode = async () => {
    setAlertOn(false);
    if (!goalPayload.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Promo code can not be empty',
        alertMessageType: 'error',
      });
      return;
    }

    if (formik.errors.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: formik.errors.promoCode,
        alertMessageType: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      localStorage.setItem('promoCode', goalPayload.promoCode);
      const response = await fetchPromotionDetails(
        goalPayload.promoCode,
        goalPayload.currency
      );
      const {
        data: { data: { amount: discountAmount, currency } = {} } = {},
      } = response;
      setDiscount({ amount: discountAmount, currency });
      const amountNumber = convertAmountToNumber(goalPayload?.amount);
      const amountTOPay = getPayingNowAmount(amountNumber, discountAmount);
      setTotalAmount(amountTOPay);
      localStorage.setItem(
        'discountInfo',
        JSON.stringify({
          discountAmount,
          currency,
          totalAmount: amountTOPay,
        })
      );
      setUseVoucher(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const renderPaymentOptions = () => {
    if (payWithMpesa) {
      return <PayWithMpesa setPayWithMpesa={setPayWithMpesa} />;
    } else if (payWithBankTransfer) {
      return (
        <PayWithBankTransfer
          totalAmount={totalAmount}
          discount={discount}
          amount={goalPayload?.amount}
          currency={goalPayload?.currency}
          promoCode={goalPayload?.promoCode}
          setPayWithBankTransfer={setPayWithBankTransfer}
        />
      );
    } else if (useVoucher) {
      return (
        <AppyVoucher
          alertOn={alertOn}
          setAlertOn={setAlertOn}
          alert={alert}
          formik={formik}
          getInputClasses={getInputClasses}
          goalPayload={goalPayload}
          handleChange={handleChange}
          setUseVoucher={setUseVoucher}
          applyPromoCode={applyPromoCode}
          loading={loading}
        />
      );
    } else {
      return (
        <InvestmentPaymentDetailsPage
          payWithCreditCard={payWithCreditCard}
          setUseVoucher={setUseVoucher}
          loading={loading}
          discount={discount}
          amount={goalPayload?.amount}
          currency={goalPayload?.currency}
          subscription={subscription}
          planDetails={planDetails}
          totalAmount={totalAmount}
          payWithMpesa={payWithMpesa}
          setPayWithMpesa={setPayWithMpesa}
          payWithBankTransfer={payWithBankTransfer}
          setPayWithBankTransfer={setPayWithBankTransfer}
          setAlert={setAlert}
          setAlertOn={setAlertOn}
          alert={alert}
          alertOn={alertOn}
          activePaymentMethod={activePaymentMethod}
          setActivePaymentMethod={setActivePaymentMethod}
          alertRef={alertRef}
        />
      );
    }
  };

  return (
    <>
      <BackArrow
        text="Back"
        onClick={() =>
          history.push(
            goalPayload?.fundId
              ? `/plans/topup/${goalPayload?.fundId}`
              : `/plans/${goalPayload?.planId}`
          )
        }
      />
      {renderPaymentOptions()}
    </>
  );
};

export default PlanInvestmentPayment;
