export const actionTypes = {
  Purpose: '[Purpose] Action',
  FamiliarInvestments: '[FamiliarInvestments] Action',
  InvestmentExperience: '[InvestmentExperience] Action',
};

const initialAuthState = {
  purpose: null,
  familiarInvestments: [],
  investmentExperience: null,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Purpose: {
      const { purpose } = action.payload;
      return { ...state, purpose };
    }

    case actionTypes.FamiliarInvestments: {
      const { familiarInvestments } = action.payload;
      return { ...state, familiarInvestments };
    }

    case actionTypes.InvestmentExperience: {
      const { investmentExperience } = action.payload;
      return { ...state, investmentExperience };
    }

    default:
      return state;
  }
};

export const actions = {
  purpose: (purpose) => ({
    type: actionTypes.Purpose,
    payload: { purpose },
  }),

  familiarInvestments: (familiarInvestments) => {
    return {
      type: actionTypes.FamiliarInvestments,
      payload: { familiarInvestments },
    };
  },

  investmentExperience: (investmentExperience) => {
    return {
      type: actionTypes.InvestmentExperience,
      payload: { investmentExperience },
    };
  },
};

export function* saga() {}
