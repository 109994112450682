export const actionTypes = {
  ProfitPieChartData: '[ProfitPieChartData] Action',
};

const initialPieChartData = {};

export const reducer = (state = initialPieChartData, action) => {
  switch (action.type) {
    case actionTypes.ProfitPieChartData: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  getPieChartData: (pieChartData) => ({
    type: actionTypes.ProfitPieChartData,
    payload: { pieChartData },
  }),
};

export function* saga() {}
