import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchConversions } from './conversionActions';

export const actionTypes = {
  Conversions: '[Conversions] Action',
  FetchConversions: '[FetchConversions] Action',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.Conversions: {
      const { payload } = action;
      return {
        ...state,
        conversions: payload,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  conversionAction: (conversions) => ({
    type: actionTypes.Conversions,
    payload: conversions,
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.FetchConversions,
    function* fetchConversionsAction() {
      const {
        data: { data },
      } = yield call(fetchConversions);
      yield put(actions.conversionAction(data));
    }
  );
}
