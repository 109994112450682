import React from 'react';
import { useHistory } from 'react-router-dom';
import RedArrow from '../../../icons/red-arrow.svg';

export const Goal = ({
  goalName,
  goalDescription,
  link,
  goalIcon,
  onClick,
  goal,
}) => {
  const history = useHistory();
  return (
    <div
      className="d-flex justify-content-between align-items-center mt-5 goal-conatiner mx-auto py-4 px-8 cursor-pointer"
      onClick={link ? () => history.push(link) : (e) => onClick(e, goal)}
    >
      <div className="text-left mr-5">
        <div className="goal-name">{goalName}</div>
        <p>{goalDescription}</p>
      </div>
      <div className="d-flex align-items-center">
        <div>
          {goalIcon ? (
            <img src={goalIcon} alt="goal icon" width={48} height={48} />
          ) : null}
        </div>
        <div className="ml-3">
          <img src={RedArrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};
