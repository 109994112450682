import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as profile from '../../Account/_redux/profile/profielReducers';
import * as goalActions from '../../Goal/_redux/goalReducers';
import * as videosActions from '../_redux/dashboardReducers';

import DashboardCard from './dashboardCard';
import PortfolioIcon from '../dashboard-images/Portfolio.svg';
import RecurringIcon from '../dashboard-images/Recurring.svg';
import DashboardCompanyLogos from './DashboardCompanyLogos';
import { getUserProfile } from '../../Account/_redux/profile/profileActions';
import { PageTitle } from '../../Common/PageTitle';
import AirInvestLogo from '../../../images/AirInvestLogo.svg';
import { Button } from '../../Common/AllButtons';
import NewGoalIcon from '../../../icons/newGoalIcon.svg';
import { fetchLearningVideos } from '../_redux/dashboardAxiosRequest';
import LearnCard from './LearnCard';

const Dashboard = ({
  profileAction,
  plansDailyTradingAction,
  intl,
  updateGoalDetails,
  fetchLearningVideos: fetchAllLearningVideos,
}) => {
  const history = useHistory();
  const {
    apprPortfolioValue,
    recurringGoalsInvestment,
    firstName,
  } = useSelector((state) => state.profile);
  const { learningVideos } = useSelector((state) => state.learningVideos);

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);

  const fetchVideos = async () => {
    try {
      setLoading(true);
      const res = await fetchLearningVideos();
      const data = res.data;
      fetchAllLearningVideos(data.items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data: { data: userProfile } = {} } = await getUserProfile();
        profileAction(userProfile);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error?.response?.data?.message,
          alertMessageType: 'error',
        });
      }
    };
    getUserInfo();
    fetchVideos();
  }, [profileAction, plansDailyTradingAction]);

  const handleAddGoalButton = () => {
    localStorage.setItem('previousPage', '/dashboard');
    updateGoalDetails(null);
    history.push('/portfolio/goal/select-goal');
  };

  return (
    <>
      <PageTitle
        title={'airInvest'}
        leftIcon={AirInvestLogo}
        insideContainer
        languageSwitcher
      />
      <div className="d-flex justify-content-between align-items-center mt-25 mb-5">
        <div className="font-size-17">
          {intl.formatMessage({
            id: 'DASHBOARD.WELCOME',
          })}
          , <span className="red-text font-weight-bolder">{firstName}</span>
        </div>

        <Button
          buttonText={intl.formatMessage({
            id: 'DASHBOARD.ADD_GOAL',
          })}
          classes={'bg-red white-text font-size-17 px-5 py-3'}
          onClick={handleAddGoalButton}
          buttonIcon={NewGoalIcon}
        />
      </div>

      <>
        <div className="dashboard-cards-container">
          <DashboardCard
            title={intl.formatMessage({
              id: 'DASHBOARD.TOTAL_PORTFOLIO',
            })}
            amount={`${apprPortfolioValue?.currency} ${parseFloat(
              apprPortfolioValue?.portfolioValue
            )}`}
            imgSrc={PortfolioIcon}
            link={'/portfolio'}
            profit={
              <>
                <span className="red-text">{`${apprPortfolioValue?.profit}%`}</span>{' '}
                <span>
                  {intl.formatMessage({ id: 'DASHBOARD.LABEL.PROFIT' })}
                </span>
              </>
            }
            className="cursor-pointer"
            label={''}
          />
          {recurringGoalsInvestment?.amount &&
          Number(recurringGoalsInvestment?.amount?.replaceAll(',', '')) >= 0 ? (
            <DashboardCard
              title={intl.formatMessage({
                id: 'DASHBOARD.RECURING_GOAL',
              })}
              amount={`${recurringGoalsInvestment?.currency} ${recurringGoalsInvestment?.amount}`}
              imgSrc={RecurringIcon}
              label={intl.formatMessage({ id: 'DASHBOARD.LABEL.MONTHLY' })}
            />
          ) : null}
        </div>
        {learningVideos?.length ? <LearnCard videos={learningVideos} /> : null}
        <div className="d-flex flex-column">
          <div className="licenced-Content">
            {intl.formatMessage({
              id: 'DASHBOARD.LINCED_CONTENT',
            })}
          </div>
          <DashboardCompanyLogos
            containerClass="dashboard-company-logos"
            dashboardCompanyClass="desktop-dashboard-company"
          />
        </div>
      </>
    </>
  );
};

export default injectIntl(
  connect(null, {
    ...profile.actions,
    ...goalActions.actions,
    ...videosActions.actions,
  })(Dashboard)
);
