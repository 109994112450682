import * as goal from '../../Goal/_redux/goalReducers';
import React, { useEffect, useState } from 'react';
import HistogramChart from '../components/HistogramChart';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import investBtnIcon from '../../../icons/investBtnIcon.svg';
import CommonButton from '../../Common/Button';
import CompanyBrand from '../components/CompanyBrand';
import Documents from '../components/Documents';
import HistoricalPerformance from '../components/HistoricalPerformance';
import AddToWatchlist from '../components/AddToWatchlist';
import MessageAlert from '../../Common/MessageAlert';
import Back from '../../Common/BackArrow';
import FundCard from '../../Common/FundCard';
import { investmentsCopy } from '../../../utils/investmentsCopy';
import ErrorCard from '../../Common/ErrorCard';
import { FundType, operationType, status } from '../../../helpers/constants';
import { messages } from '../../../utils/messages';
import { handleAlert } from '../../../helpers/handleSuccessNotification';
import {
  addToWatchList,
  fetchGraphData,
  fetchSinglePlanDetails,
  removeFromWatchList,
} from '../_redux/plansActions';
import * as planActions from '../../Plan/_redux/planReducers';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import SavingAccountMesssage from '../components/SavingAccoutMessage';

const PlanDetails = ({ updatePlanDetails }) => {
  const history = useHistory();
  const { id: planId } = useParams();
  const [loading, setLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState({});
  const [alertOn, setAlertOn] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const { pathname } = history.location;

  const [withdrawalError, setWithdrawalError] = useState(null);
  const [showWithdrawalError, setShowWithdrawalError] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const closeAlert = () => {
    setAlertOn(false);
  };

  const addToTheWatchList = async () => {
    try {
      setLoading(true);
      let res = await addToWatchList(parseInt(planId));
      if (res.status === 201) {
        fetchPlanDetails(parseInt(planId));
        handleAlert(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.error,
        alertMessageType: 'alert-light-danger',
      });
    }
  };

  const fetchPlanDetails = async (id) => {
    try {
      setLoading(true);
      const res = await fetchSinglePlanDetails(id);
      if (res.status === 200) {
        const plan = res.data.data;
        setPlanDetails(plan);
        updatePlanDetails(plan);

        const transaction = plan.transactions.find(
          (item) =>
            item.transactionStatus === status.PENDING &&
            item.operationType === operationType.WITHDRAW
        );

        if (transaction) {
          setWithdrawalError({
            alertMessage: messages.withdraw.unpprovedWithdraw,
            alertMessageType: 'alert-light-danger',
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const getGraphData = async () => {
    try {
      const res = await fetchGraphData(planId);
      if (res.status === 200) {
        setGraphData([
          {
            name: 'Year 1',
            value: res.data.data.performance1 ? res.data.data.performance1 : 0,
          },
          {
            name: 'Year 3',
            value: res.data.data.performance3 ? res.data.data.performance3 : 0,
          },
          {
            name: 'Year 5',
            value: res.data.data.performance5 ? res.data.data.performance5 : 0,
          },
          {
            name: 'Year 10',
            value: res.data.data.performance10
              ? res.data.data.performance10
              : 0,
          },
        ]);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchPlanDetails(planId);
  }, [planId]);

  useEffect(() => {
    getGraphData();
  }, [planId]);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  const deleteWatchlist = async (id) => {
    try {
      setLoading(true);
      const res = await removeFromWatchList(id);
      fetchPlanDetails(parseInt(planId));
      handleAlert(res.data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.error,
        alertMessageType: 'alert-light-danger',
      });
    }
  };
  const {
    isInvested,
    isWatchList,
    planRiskLevel,
    brands,
    name,
    flag,
    factSheet,
    prospectus,
    sector,
    fundManager,
    coolName,
    about,
    currency,
    imageUrl,
    investable,
    isCurrentOffer,
    tenor,
    fundType,
    minInvestableAmounts,
  } = planDetails;

  const goToPreviousPage = () => {
    if (pathname.includes('watchlist')) {
      history.push('/watchlist');
    } else if (pathname.includes('fixed-return')) {
      history.push('/plans/fixed-return');
    } else {
      history.push('/plans');
    }
  };

  const generateBackButtonText = () => {
    let buttonText = '';
    if (pathname.includes('watchlist')) {
      buttonText = 'Back to Watchlist';
    } else if (pathname.includes('fixed-return')) {
      buttonText = 'Back to Fixed Returns';
    } else {
      buttonText = 'Back to Funds';
    }

    return buttonText;
  };

  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : (
        <>
          <Back
            text={generateBackButtonText()}
            onClick={() => goToPreviousPage()}
          />
          <div className="portfolio-card card card-custom gutter-b">
            {alertOn ? (
              <MessageAlert closeAlert={closeAlert} alert={alert} />
            ) : null}

            <FundCard
              sector={sector}
              name={name}
              coolName={coolName}
              planRiskLevel={planRiskLevel}
              countryFlag={flag}
              currency={currency}
              imageUrl={imageUrl}
              annualPerformance={planDetails?.performance1}
              isCurrentOffer={isCurrentOffer}
              tenor={tenor}
              fundType={fundType}
            />
            {fundType === FundType.SAVE ? (
              <SavingAccountMesssage
                message={messages.savingFundAlert}
                lockInPeriod={planDetails?.lockInPeriod}
                classes="mt-5 mb-2"
              />
            ) : null}
            <div className="mt-5 card blue-border">
              <div className="p-5 mt-5 text-center d-flex flex-column">
                <div className="fund-title">
                  {investmentsCopy?.fund?.action}
                </div>
                <div className="mt-5 fund-description center-container">
                  {about}
                </div>
                <CommonButton
                  icon={investBtnIcon}
                  alt="Dollar Icon"
                  btnText={
                    investable
                      ? isInvested
                        ? 'Top Up'
                        : fundType === FundType.SAVE
                        ? 'Start'
                        : 'Invest Now'
                      : 'Investment not available'
                  }
                  className={
                    investable
                      ? 'mt-5 text-center common-btn btn-size center-btn'
                      : 'mt-5 text-center btn btn-secondary center-btn'
                  }
                  disabled={investable ? false : true}
                  onClick={() => {
                    if (withdrawalError) {
                      return setShowWithdrawalError(true);
                    }
                    localStorage.removeItem('promoCode');
                    localStorage.removeItem('discountInfo');
                    localStorage.removeItem('subscriptionId');
                    localStorage.removeItem('goalDiscountInfo');
                    const setPlanAction = isInvested
                      ? 'planInvestmentTopup'
                      : 'directInvestment';
                    localStorage.setItem(
                      'action',
                      JSON.stringify({
                        name: setPlanAction,
                        id: isInvested?.id,
                      })
                    );
                    localStorage.setItem('planId', planId);
                    localStorage.setItem('planCurrency', currency);
                    localStorage.setItem('promoPlanOnOffer', isCurrentOffer);
                    localStorage.setItem(
                      'planMinimumInvestableAmounts',
                      JSON.stringify(minInvestableAmounts)
                    );
                    !isInvested && localStorage.setItem('pathName', pathname);
                    const redirectUrl = isInvested
                      ? `/plans/topup/${isInvested?.id}`
                      : `/plans/initialInvestmentAmount/${planId}`;
                    history.push(redirectUrl);
                  }}
                />
                <br />
                {showWithdrawalError && (
                  <MessageAlert
                    closeAlert={() => setShowWithdrawalError(false)}
                    alert={withdrawalError}
                  />
                )}
              </div>
            </div>

            {brands && brands.length > 0 ? (
              <div className="mt-5 card blue-border">
                <div className="p-5 my-5 text-center d-flex flex-column">
                  <div className="fund-title">
                    {investmentsCopy.companies.tilte}
                  </div>
                  <div className="flex-wrap mt-5 companies-container justify-content-center">
                    {brands.map((brand) => (
                      <CompanyBrand
                        key={brand?.id}
                        companyName={firstLetterUpperCase(brand?.name)}
                        brandLogo={brand?.brandLogo}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            <HistoricalPerformance plan={planDetails}>
              {graphData.length && <HistogramChart graphData={graphData} />}
            </HistoricalPerformance>

            <AddToWatchlist
              isWatchList={isWatchList}
              deleteWatchlist={deleteWatchlist}
              addToTheWatchList={addToTheWatchList}
              isInvested={isInvested}
              planId={planId}
              fundManager={fundManager}
              planName={name}
              withdrawalError={withdrawalError}
              currency={currency}
              investable={investable}
            />
            {prospectus || factSheet ? (
              <Documents prospectus={prospectus} factSheet={factSheet} />
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ goal }) => ({
  goal,
});

export default connect(mapStateToProps, {
  ...goal.actions,
  ...planActions.actions,
})(PlanDetails);
