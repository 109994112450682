import React from 'react';
import { currencyHelper } from '../../../../helpers/currencyHelper';
import PopoverComponent from '../../../Common/PopoverComponent';

const DisplayMoney = ({
  text,
  currency,
  amount,
  discountAmount,
  keycustomStyle,
  valuecustomStyle,
  mathSign,
  minimumAmountError,
}) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <span style={{ ...keycustomStyle }}>{text}</span>

        <span className="font-weight-bold">
          <span style={{ ...valuecustomStyle }}>
            {discountAmount
              ? `- ${discountAmount}`
              : currencyHelper(amount, currency, mathSign)}
          </span>
          {minimumAmountError ? (
            <PopoverComponent popoverContent={minimumAmountError}>
              <span className="cursor-pointer">
                <i
                  style={{
                    fontSize: '16px',
                    color: '#F64E60',
                    marginLeft: '5px',
                  }}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </PopoverComponent>
          ) : null}
        </span>
      </div>
    </>
  );
};

export default DisplayMoney;
