import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const DIRECT_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/direct-investments`;
const FETCH_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/investor/transactions`;

const directInvestment = async (goalAmount, currency, targetDate, planId) => {
  const res = await axios.post(DIRECT_INVESTMENT, {
    amount: goalAmount,
    currency,
    investmentDate: targetDate,
    planId,
  });
  return res;
};

const fetchTransactions = async (investmentType, page, limit) => {
  const res = await axios.get(`${FETCH_TRANSACTIONS}/${investmentType}?page=${page}&limit=${limit}`);
  return res;
};

export { directInvestment, fetchTransactions };
