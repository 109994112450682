/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import * as auth from '../../Auth/_redux/authRedux';
import * as profile from '../../Account/_redux/profile/profielReducers';
import { actions } from '../_redux/wizardRedux';

import { familiarInvestmentsChoices } from '../helpers/choices';
import { updateLoggedInUserInvestorProfile } from '../_redux/wizardCrud';

import Exit from './Exit';
import { WizardQuestionTitle } from '../../Common/WizardQuestionTitle';
import { PageNumbers } from '../../Common/PageNumbers';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';
import { injectIntl } from 'react-intl';

const FamiliarInvestmentsWizardPage = ({
  fetchProfileAction,
  intl,
  familiarInvestments: familiarInvestmentsAction,
  purpose,
  investmentExperience,
}) => {
  let history = useHistory();
  const [familiarInvestments, setFamiliarInvestments] = useState([]);
  const isRetakingWizard = localStorage.getItem('retakingTheWizard');
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const wizard = useSelector((state) => state.wizard);

  const clickChoice = (e, id) => {
    let currentFamiliarInvestments = [...familiarInvestments];
    if (currentFamiliarInvestments.includes(id)) {
      currentFamiliarInvestments = currentFamiliarInvestments.filter(
        (eachItem) => eachItem !== id
      );
      setFamiliarInvestments(currentFamiliarInvestments);
    } else {
      currentFamiliarInvestments.push(id);
      setFamiliarInvestments(currentFamiliarInvestments);
      familiarInvestmentsAction(currentFamiliarInvestments);
    }
  };

  const nextButtonSubmit = async (e) => {
    const retakingTheWizard = localStorage.getItem('retakingWizard');
    const wizardPayload = retakingTheWizard
      ? {
          investmentExperience: wizard?.investmentExperience,
          familiarInvestments,
        }
      : { ...wizard, familiarInvestments };
    e.preventDefault();
    try {
      setLoading(true);
      const response = await updateLoggedInUserInvestorProfile(wizardPayload);
      if (response.status === 200 || response.status === 201) {
        fetchProfileAction();
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: 'Your investor profile has been successfully created.',
          alertMessageType: 'alert-light-success',
        });
        const retakingTheWizard = localStorage.getItem('retakingWizard');
        purpose(null);
        familiarInvestmentsAction([]);
        investmentExperience(null);
        history.push(retakingTheWizard ? '/risk-profile' : '/profile');
      }
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage:
          error.response && error.response.data
            ? error.response.data.message
            : 'unable to create investor profile. Try again',
        alertMessageType: 'alert-light-danger',
      });
    }
  };

  const cancelWizard = () => {
    localStorage.removeItem('wizard');
    localStorage.removeItem('retakingTheWizard');
    history.push('/dashboard');
  };

  const retakingWizard = localStorage.getItem('retakingWizard');

  return (
    <div>
      {/* <!--begin::Container--> */}
      <PageTitle
        title={intl.formatMessage({ id: 'WIZARD.FAMILIAR_INVESTMENT.HEADER' })}
        previousPage={'/wizard/investment-experience'}
        insideContainer
      />
      <div className="container mt-35 z-index-1">
        <PageNumbers currentPageNumber={retakingWizard ? '2' : '3'} />
        {/* <!--begin::Wizard--> */}
        <div
          className="wizard wizard-1 animate__animated animate__fadeInUp"
          id="kt_wizard"
          data-wizard-state="step-first"
          data-wizard-clickable="false"
        >
          {/* <!--begin::Alert--> */}
          {alertOn ? (
            <div
              className={`alert alert-custom ${alert.alertMessageType} mt-10 fade show`}
              role="alert"
            >
              {Array.isArray(alert.alertMessage) ? (
                <div className="alert-text font-weight-bold">
                  <ul>
                    {alert.alertMessage.map((message) => (
                      <li key={message}>{message}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="alert-text font-weight-bold">
                  {alert.alertMessage}
                </div>
              )}
            </div>
          ) : null}
          {/* <!--begin::Wizard Body--> */}
          <div className="row justify-content-center px-1">
            <div className="col-xl-12">
              {/* <!--begin::Wizard Form--> */}
              <form className="form" id="kt_form">
                <div
                  className="pb-3"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <WizardQuestionTitle
                    title={intl.formatMessage({
                      id: 'WIZARD.FAMILIAR_INVESTMENT.TITLE',
                    })}
                  />

                  <div>
                    <p
                      className="font-weight-bold"
                      style={{ color: '#0071CE' }}
                    >
                      {intl.formatMessage({
                        id: 'WIZARD.FAMILIAR_INVESTMENT.SELECT',
                      })}
                    </p>
                    {isRetakingWizard ? (
                      <Exit cancelWizard={cancelWizard} />
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  {familiarInvestmentsChoices.map((eachChoice) => {
                    if (familiarInvestments?.includes(eachChoice.id)) {
                      return (
                        <div className="col-xl-12" key={eachChoice.id}>
                          <div
                            onClick={(e) => clickChoice(e, eachChoice.id)}
                            className="card-custom  animated-card gutter-b selected-answer-box"
                          >
                            {/* <!--begin::Body--> */}
                            <div
                              className="card-body d-flex flex-row justify-content-between"
                              style={{ padding: '1.5rem 2rem' }}
                            >
                              <div className="font-size-h6">
                                {intl.formatMessage({ id: eachChoice.choice })}
                              </div>
                              <div
                                style={{ fontWeight: 300 }}
                                className="font-size-h4"
                              >
                                <i
                                  style={{
                                    color: '#ffffff',
                                  }}
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            {/* <!--end::Body--> */}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="col-xl-12" key={eachChoice.id}>
                          <div
                            onClick={(e) => clickChoice(e, eachChoice.id)}
                            className="animated-card card-custom  gutter-b unselected-answer-box"
                          >
                            {/* <!--begin::Body--> */}
                            <div
                              className="card-body d-flex flex-row"
                              style={{ padding: '1.5rem 2rem' }}
                            >
                              {/* <!--begin::Stats--> */}
                              <div className="font-size-h6">
                                {intl.formatMessage({ id: eachChoice.choice })}
                              </div>
                              {/* <!--end::Stats--> */}
                            </div>
                            {/* <!--end::Body--> */}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                {familiarInvestments && familiarInvestments.length ? (
                  <div className="mt-10">
                    <Button
                      classes="bg-red white-text w-100 py-5"
                      buttonText={intl.formatMessage({ id: 'BUTTONS.NEXT' })}
                      onClick={nextButtonSubmit}
                      loading={loading}
                    />
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default injectIntl(
  connect(mapStateToProps, {
    ...auth.actions,
    ...profile.actions,
    ...actions,
  })(FamiliarInvestmentsWizardPage)
);
