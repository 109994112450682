export const archivedTransactionsHeader = [
  { columnName: 'Date', width: '150px' },
  { columnName: 'Fund Name', width: '150px' },
  { columnName: 'Fund Manager', width: '150px' },
  { columnName: 'Goal', width: '150px' },
  { columnName: 'Invested Amount', width: '180px' },
  { columnName: 'Type', width: '100px' },
  { columnName: 'Reason', width: '150px' },
  { columnName: 'Refunded', width: '100px' },
  { columnName: 'Status', width: '100px' },
];

export const goalTransactionsHeader = [
  { columnName: 'Date', width: '150px' },
  { columnName: 'Fund Name', width: '150px' },
  { columnName: 'Fund Manager', width: '150px' },
  { columnName: 'Goal', width: '150px' },
  { columnName: 'Amount', width: '180px' },
  { columnName: 'Type', width: '100px' },
  { columnName: 'Status', width: '100px' },
];

export const generalTransactionsHeader = [
  { columnName: 'Date', width: '150px' },
  { columnName: 'Fund Name', width: '150px' },
  { columnName: 'Fund Manager', width: '150px' },
  { columnName: 'Amount', width: '180px' },
  { columnName: 'Type', width: '100px' },
  { columnName: 'Status', width: '100px' },
];
