import React from 'react';

import RiskProfileImage from './RiskProfileImage';
import Button from '../../Common/Button';
import elephantOnInstruction from '../../../images/elephantOnInstruction.svg';

import '../index.scss';

const OfferCodes = () => {
  return (
    <>
      <div className="container instructions-container">
        <RiskProfileImage image={elephantOnInstruction} />
        <div className="text-center">
          <p className="instruction-title">Welcome to ndovu</p>
          <p style={{ fontSize: '20px' }}>
            Now that you have created your personal portfolio, complete these
            two last steps to get investing...{' '}
          </p>
        </div>
        <div className="mt-10 left-space">
          <div style={{ fontSize: '18px' }}>
            <span className="text-left">- Deposit Funds</span>
            <br />
            <small style={{ marginLeft: '10px' }}>
              Using mobile money, bank transfer or{' '}
              <span className="center-word-on-mobile">card</span>.
            </small>
          </div>

          <div style={{ fontSize: '18px' }} className="mt-5">
            <span className="text-left">- Tell us about yourself</span>
            <br />
            <small style={{ marginLeft: '18px' }}>
              Your name, address & your hustle.
            </small>
          </div>
        </div>

        <div className="text-center my-15">
          {/* <Link to="/dashboard"> */}
          <Button
            btnText="Get started"
            className="common-btn pl-8 pr-10 py-3"
            onClick={() => window.location.replace('/new-investment')}
          />
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default OfferCodes;
