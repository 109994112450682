import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';

import React, { useState } from 'react';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import Input from '../../Common/Input/Input';
import { Redirect } from 'react-router-dom';
import { SubmitButton } from '../../Common/buttons/Buttons';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { requestPasswordConfirmation } from '../_redux/authCrud';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import UnableViewPassword from '../../Common/UnableViewPassword';
import {
  passwordRegex,
  passwordValidationMessage,
} from '../../../helpers/constants';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = (props) => {
  let history = useHistory();
  const searchParams = new URLSearchParams(props.location.search);
  const passwordResetCode = searchParams.get('code');
  const id = Number(searchParams.get('id'));
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const RedirectToLogin = () => {
    history.push('/auth/login');
  };

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, passwordValidationMessage)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await requestPasswordConfirmation(
          id,
          values.password,
          passwordResetCode
        );
        setLoading(false);
        setSubmitting(false);
        if (response.status === 200) {
          setStatus(response.data.message);
          setSuccess(true);
          setTimeout(RedirectToLogin, 3000);
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error.response.data.message);
        setSuccess(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <ExternalPageTitle
            title="Reset Password"
            subTitle="Enter your new password"
          />
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status && (
              <div className="mb-10 text-center">
                {success ? (
                  <h4
                    className="font-weight-bold font-size-h4"
                    style={{ color: '#67CCCA' }}
                  >
                    {formik.status}
                  </h4>
                ) : (
                  <p style={{ color: '#F05B7F' }}>{formik.status}</p>
                )}
              </div>
            )}
            {/* begin: password */}
            <div className="password-spacing fv-plugins-icon-container">
              <Input
                placeholder="Password"
                type={viewPassword ? 'text' : 'password'}
                className={`form-control input-field ${getInputClasses(
                  'password'
                )}`}
                name="password"
                formik={formik}
              />
              <UnableViewPassword
                handleClickEvent={() => setViewPassword(!viewPassword)}
                viewPassword={viewPassword}
                classes="password-eye-icon"
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="error-message-spacing fv-help-block">
                    {formik.errors.password}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: password */}

            {/* begin: confirm password */}
            <div className="password-spacing fv-plugins-icon-container">
              <Input
                placeholder="Confirm Password"
                type={viewConfirmPassword ? 'text' : 'password'}
                className={`form-control input-field ${getInputClasses(
                  'confirmPassword'
                )}`}
                name="confirmPassword"
                formik={formik}
              />
              <UnableViewPassword
                handleClickEvent={() =>
                  setViewConfirmPassword(!viewConfirmPassword)
                }
                viewPassword={viewConfirmPassword}
                classes="password-eye-icon"
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="error-message-spacing fv-help-block">
                    {formik.errors.confirmPassword}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: confirm password */}
            <div className="flex-wrap form-group d-flex flex-center">
              <SubmitButton
                classes="btn-mobile space-top"
                BtnText="Reset Password"
                disabled={formik.isSubmitting}
                loading={loading}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ResetPassword));
