import React from 'react';

import Alert from '../../../Common/Alert';
import { CancelButton } from '../../../Common/buttons/Buttons';
import Input from '../../../Common/Input/ControlledInput';
// import { CancelButton } from '../../../Common/buttons/Buttons';

const AppyVoucher = ({
  alertOn,
  formik,
  getInputClasses,
  goalPayload,
  handleChange,
  setUseVoucher,
  applyPromoCode,
  loading,
  alert,
  setAlertOn,
}) => {
  return (
    <div className="card card-custom card-gutter-b">
      <div className="card-header">
        <div className="card-title">
          <h3 className="text-center card-label font-size-h3">Payment</h3>
        </div>
      </div>
      <div className="card-body">
        <div className="mb-5">{alertOn && <Alert alert={alert} />}</div>

        <p className="mb-5 text-center">
          Use your discount code to get a bonus.
        </p>

        <form className="mt-10" id="kt_form" onSubmit={formik.handleSubmit}>
          <div className="input-container">
            <Input
              placeholder="Insert discount code"
              type="text"
              className={`form-control input-field ${getInputClasses(
                'promoCode'
              )}`}
              name="promoCode"
              formik={formik}
              defaultValue={goalPayload?.promoCode}
              handleChange={handleChange}
            />
          </div>
          {formik.touched.promoCode && formik.errors.promoCode ? (
            <div className="input-container fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.promoCode}</div>
            </div>
          ) : null}
          <div className="my-10 text-center mb-lg-20">
            <CancelButton
              BtnText="Cancel"
              classes="full-width-small py-4"
              onClick={() => {
                delete goalPayload?.promoCode;
                localStorage.removeItem('promoCode');
                setAlertOn(false);
                setUseVoucher(false);
              }}
            />

            <span
              className="px-5 py-4 text-center cursor-pointer margin-left display-small-block btn-size common-btn"
              onClick={applyPromoCode}
            >
              Redeem Bonus
              {loading && <span className="mx-3 spinner spinner-white" />}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AppyVoucher;
