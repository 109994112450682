import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { actions } from '../../Wizard/_redux/wizardRedux';
import * as goalActions from '../../Goal/_redux/goalReducers';
import { Goal } from '../../Common/Goal';
import { PageNumbers } from '../../Common/PageNumbers';
import { PageTitle } from '../../Common/PageTitle';
import { useHistory } from 'react-router-dom';
import { sataticGoalNames } from '../../Wizard/helpers/choices';

const InvestingFor = ({ intl, updateGoalDetails, purpose }) => {
  const history = useHistory();

  const handleSelect = (e, goal) => {
    if (goal?.goalNameIsRequired) {
      updateGoalDetails({
        id: goal?.id,
        goalNameIsRequired: goal?.goalNameIsRequired,
      });
    } else {
      updateGoalDetails({
        id: goal?.id,
        goalNameIsRequired: goal?.goalNameIsRequired,
        name: goal.choice,
      });
    }
    purpose(goal?.id);
    history.push('/wizard/investment-experience');
  };
  return (
    <div>
      <PageTitle
        title={intl.formatMessage({ id: 'WIZARD.SELECT_GOAL.TITLE' })}
        previousPage={'/setup-your-goal'}
        insideContainer
      />
      <div className="container mt-30 min-vh-100">
        <PageNumbers currentPageNumber={'1'} />
        {sataticGoalNames.map((goal) => (
          <Goal
            goalName={goal?.choice}
            goalDescription={goal?.description}
            goalIcon={require(`../../../images/goals/${goal?.assetName}.png`)}
            onClick={handleSelect}
            key={goal?.id}
            goal={goal}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wizard: state.wizard,
});

export default injectIntl(
  connect(mapStateToProps, { ...actions, ...goalActions.actions })(InvestingFor)
);
