import '../styles/registration.scss';

import * as Yup from 'yup';

import { CancelButton, SubmitButton } from '../../Common/buttons/Buttons';
import React, { useState } from 'react';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import Input from '../../Common/Input/Input';
import { Link } from 'react-router-dom';
import Loader from '../../Common/Loader';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { requestResendEmailVerification } from '../_redux/authCrud';
import { useFormik } from 'formik';

const initialValues = {
  email: '',
};

const ResendEmailVerification = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const ResendEmailVerificationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResendEmailVerificationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await requestResendEmailVerification(
          values.email.toLowerCase()
        );
        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
          setResponseMessage(response.data.message);
        }
      } catch (error) {
        setSubmitting(false);
        setLoading(false);
        setResponseMessage(error.response.data.message);
        setSuccess(false);
      }
    },
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="text-center verify-email-container">
      {responseMessage ? (
        <div
          className={`mb-lg-20 mb-10  text-center`}
          style={{ color: success ? '#67CCCA' : '#F05B7F' }}
        >
          <div className="font-weight-bold">
            <h6>{responseMessage}</h6>
          </div>
        </div>
      ) : null}
      <ExternalPageTitle
        title="Verification"
        subTitle="Enter your email to get a new verification link"
      />
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 ">
            <div
              className="text-center font-weight-bold"
              style={{ color: '#F05B7F' }}
            >
              {formik.status}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="form-group fv-plugins-icon-container">
          <Input
            placeholder="Email"
            type="email"
            className={`form-control input-field width ${getInputClasses(
              'email'
            )}`}
            name="email"
            formik={formik}
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center justify-content-between mobile-row">
          <Link to="/auth/login" className="btn-mobile">
            <CancelButton BtnText="Cancel" classes="btn-mobile py-4" />
          </Link>
          <SubmitButton
            disabled={success}
            classes="btn-mobile space-top"
            BtnText="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default injectIntl(connect(null, null)(ResendEmailVerification));
