import React from 'react';
import { Redirect } from 'react-router-dom';

export const WizardHome = () => {
  return (
    <div>
      <Redirect to={'/select-goal'} />
    </div>
  );
};
