import React from 'react';
import NewInvestmentOption from './components/NewInvestmentOptions';
import { investmentOptions } from './components/NewInvestmentOptions/investmentOptions';

import './NewInvestment.scss';

const NewInvestment = () => {
  return (
    <>
      <h3 className="text-center mb-5">Smart Investing</h3>
      <p
        className="new-investment-text"
        style={{ textAlign: 'center', marginBottom: '30px' }}
      >
        Our investments are designed to have the lowest risk, while enjoying a
        high return over a given period.
      </p>
      <div
        className="justify-content-between new-investment-main-container"
        style={{ display: 'grid' }}
      >
        {investmentOptions.map((investmentOption) => (
          <NewInvestmentOption
            title={investmentOption?.title}
            text={investmentOption?.text}
            ElephantIcon={investmentOption.elephantIcon}
            link={investmentOption.link}
            key={investmentOption?.title}
          />
        ))}
      </div>
    </>
  );
};

export default NewInvestment;
