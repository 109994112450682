import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { findCurrency } from '../../../../helpers/findCurreny';
import { paymentMethod } from '../../../../helpers/constants';
import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { currencyHelper } from '../../../../helpers/currencyHelper';
import Alert from '../../../Common/Alert';
import { CancelButton } from '../../../Common/buttons/Buttons';
import BankTransferCheckbox from '../../../Common/CheckBox/bankTransferCheckbox';
import WarningMessage from '../../../Common/WarningMessage';
import { goalTopUp, investmentInGoal } from '../../../Goal/_redux/goalActions';
import { getBankAccountDetails } from '../../_redux/investmentAction'; 
import { directInvestment, fundTopUp } from '../../../Plan/_redux/plansActions';
import { payWithBankTransfer } from '../../_redux/investmentAction';
import DisplayMoney from '../DisplayMoney';
import BankDetails from './bankDetails';
import './index.scss';

const handleGoalInvestment = async (
  id,
  textReference,
  channel,
  transactionId
) => {
  const response = await investmentInGoal(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId)
  );
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId)
  );
  return response;
};

const handleFundTopUp = async (planSubscribtionId, textReference, channel) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel
  );
  return response;
};

const handleGoalTopUp = async (id, textReference, channel) => {
  const response = await goalTopUp(parseInt(id), textReference, channel);
  return response;
};

const actions = [
  { id: 2, name: 'goalTopUp', invest: handleGoalTopUp },
  { id: 3, name: 'investmentTopup', invest: handleFundTopUp },
  { id: 4, name: 'planInvestmentTopup', invest: handleFundTopUp },
  { id: 5, name: 'goalInvestment', invest: handleGoalInvestment },
  { id: 6, name: 'directInvestment', invest: handleDirectInvestment },
];

const PayWithBankTransfer = ({
  totalAmount,
  discount,
  amount,
  currency,
  setPayWithBankTransfer,
}) => {
  const history = useHistory();
  const [actionName, setActionName] = useState();
  const [localStorageData, setLocalStorageData] = useState();
  const [promoCode, setPromoCode] = useState();
  const [alertOn, setAlertOn] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [checked, handleCheckBoxChange] = useState(false);
  const [account, setAccount] = useState({});

  const profile = useSelector((state) => state.profile);

  const { currencies: allCurrencies } = useSelector(
    (state) => state?.currencies
  );

  const handleCheckBankTransferBoxChange = () => {
    handleCheckBoxChange(!checked);
  };

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem('goalDataForPayment'));
    const promoCode = localStorage.getItem('promoCode');
    const action = JSON.parse(localStorage.getItem('action'));
    setActionName(action?.name);
    setLocalStorageData(localData);
    setPromoCode(promoCode);
  }, []);

  useEffect(() => {
    const selectedCurrency = findCurrency(allCurrencies, currency);
    const getData = async () => {
      try {
        const {
          data: { data },
        } = await getBankAccountDetails(Number(selectedCurrency?.id));
        setAlertOn(false);
        setAccount(data);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
      }
    };

    getData();
  }, [allCurrencies, currency]);

  const attention = (
    <div>
      <i
        className="fa fa-exclamation-triangle"
        aria-hidden="true"
        style={{ color: '#ff9966' }}
      ></i>

      <span style={{ fontSize: '14px', marginLeft: '5px' }}>
        We will finalise the operation only when the money is received. If the
        money is not transferred this operation will be automatically canceled.
      </span>
    </div>
  );

  const displayWarning = () => {
    return (
      <div className="pb-5 pt-3">
        <hr className="border-2 border-top"></hr>
        <p>Please, make sure to:</p>
        <ul>
          <li>
            Cover all bank transfer charges from your account so as to ensure
            that your full investment amount is received by our bank.
          </li>
          <li>
            Specify the exact investment amount and exact reference while you
            make the bank transfer to avoid any delays.
          </li>
        </ul>
        <p>
          Once you have completed the operation, please proceed to select the
          checkbox below.
        </p>

        <div className="pt-3">
          <BankTransferCheckbox
            checked={checked}
            label={'I have transferred the money'}
            name={''}
            handleCheckBoxChange={handleCheckBankTransferBoxChange}
          />
        </div>
        <WarningMessage message={attention} />
      </div>
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await payWithBankTransfer(
        convertAmountToNumber(amount),
        currency,
        promoCode
      );

      const { tx_ref } = response?.data;

      const id = localStorageData.planId
        ? localStorageData.planId
        : localStorageData.fundId
        ? localStorageData.fundId
        : localStorageData.goalId;

      actions.forEach(async (item) => {
        if (item.name === `${actionName}`) {
          try {
            const result = await item.invest(
              id,
              tx_ref,
              paymentMethod.BANK_TRANSFER
            );

            if (result.status === 201) {
              setLoading(false);
              localStorage.removeItem('goalDataForPayment');
              localStorage.removeItem('discountInfo');
              localStorage.removeItem('promoCode');
              history.push('/congratulations');
              return;
            }
          } catch (error) {
            setLoading(false);
            setAlertOn(true);
            setAlert({
              alertMessage: error.response.data.message,
              alertMessageType: 'error',
            });
          }
        }
      });
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage:
          error.response.data.message || error.response.data.errorMessage,
        alertMessageType: 'error',
      });
    }
  };

  return (
    <div className="card card-custom card-gutter-b">
      <div className="pt-5 card-body">
        {alertOn && (
          <div className="mb-5">
            <Alert alert={alert} />
          </div>
        )}
        <div className="py-5 mx-auto responsive-width">
          <div className="d-flex justify-content-center">
            <h4>Pay with Bank Transfer</h4>
          </div>
          <p className="mt-5 font-weight-bold">To complete the investment:</p>
          <ol>
            <li>Please login to your bank’s online banking portal.</li>
            <li>
              Initiate a fund transfer to the beneficiary account indicated
              below.
            </li>
          </ol>
          <BankDetails account={account} />
          <div className="font-weight-bold">
            <div className="d-flex justify-content-between">
              <span>Reference:</span>
              <span className="font-weight-bold">
                {profile.causal ? `NV-${profile.causal}` : '---'}
              </span>
            </div>
            {amount && (
              <DisplayMoney
                discount={discount}
                text="Investment Amount"
                currency={currency}
                amount={amount}
              />
            )}
          </div>

          {discount?.amount && (
            <DisplayMoney
              discount={discount}
              text="Discount Applied"
              currency={discount?.currency}
              discountAmount={currencyHelper(
                discount?.amount,
                discount?.currency
              )}
            />
          )}

          {(totalAmount || totalAmount === 0) && (
            <DisplayMoney
              discount={discount}
              text="Pay now"
              currency={discount?.currency}
              amount={totalAmount}
            />
          )}

          {displayWarning()}
        </div>

        <div className="my-10 text-center mb-lg-20">
          <CancelButton
            BtnText="Cancel"
            classes="full-width-small py-4"
            onClick={() => setPayWithBankTransfer(false)}
          />

          {checked && !alertOn ? (
            <span
              className="px-5 py-4 text-center cursor-pointer margin-left display-small-block btn-size common-btn"
              onClick={handleSubmit}
            >
              Confirm Payment
              {loading && <span className="mx-3 spinner spinner-white" />}
            </span>
          ) : (
            <span className="px-5 py-4 text-center cursor-pointer margin-left display-small-block btn-size disabled-btn">
              Proceed
              {loading && <span className="mx-3 spinner spinner-white" />}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayWithBankTransfer;
