import React from 'react';
import { useHistory } from 'react-router-dom';
import { firstLetterUpperCase } from '../../../../helpers/investmentHelpers';
import Card from './Card';
import TopCard from './TopCard';
import ProfileRisk from './ProfileRisk';

import './index.scss';
import SmallBrandLogo from '../CompanyBrand/SmallBrandLogo';
import { PlanTitle } from '../../../Common/PlanTitle';

const PlanCard = ({ data, planTypeName }) => {
  const history = useHistory();
  return (
    <Card width={'plan-card-width'}>
      <PlanTitle className={'plan-title px-3'} data={data} />
      {data && data.imageUrl ? (
        <TopCard
          onClick={() => history.push(`/plans/${data?.id}`)}
          imageLink={data.imageUrl}
        >
          <span className="cash">{firstLetterUpperCase(data?.sector)}</span>
        </TopCard>
      ) : (
        <TopCard
          onClick={() => history.push(`/plans/${data?.id}`)}
        >
          <span className="cash">{firstLetterUpperCase(data?.sector)}</span>
        </TopCard>
      )}

      <div className="plan-card-second-container">
        <div className="d-flex justify-content-between align-items-center">
          <div
            onClick={() => history.push(`/plans/${data?.id}`)}
            className="d-flex add-space-top"
          >
            {data?.brands?.map((brand) => (
              <SmallBrandLogo
                key={brand.assetName}
                brandLogo={brand.brandLogo}
              />
            ))}
          </div>

          <div
            className="add-space-top"
            onClick={() => history.push(`/plans/${data?.id}`)}
          >
            <span
              className="font-weight-bold"
              style={{ color: '#0071CE', paddingTop: '7PX', fontSize: '12px' }}
            >
              More
            </span>
          </div>
        </div>

        <div
          onClick={() => history.push(`/plans/${data?.id}`)}
          className="fund-container"
        ></div>

        <ProfileRisk
          planTypeName={planTypeName}
          riskProfile={data?.planRiskLevel}
          onClick={() => history.push(`/plans/${data?.id}`)}
        />
      </div>
    </Card>
  );
};

export default PlanCard;
