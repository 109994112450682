import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as goal from '../app/modules/Goal/_redux/goalReducers';
import * as profile from '../app/modules/Account/_redux/profile/profielReducers';
import * as wizard from '../app/modules/Wizard/_redux/wizardRedux';
import * as currency from '../app/modules/Account/_redux/currencies/currenciesReducers';
import * as conversion from '../app/modules/Account/_redux/conversions/conversionReducers';
import * as plan from '../app/modules/Plan/_redux/planReducers';
import * as pieChartData from '../app/modules/Profile/_redux/profileReducers';
import * as learningVideos from '../app/modules/Dashboard/_redux/dashboardReducers';

import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  wizard: wizard.reducer,
  profile: profile.reducer,
  goal: goal.reducer,
  currencies: currency.reducer,
  conversions: conversion.reducer,
  plan: plan.reducer,
  pieChartData: pieChartData.reducer,
  learningVideos: learningVideos.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    wizard.saga(),
    profile.saga(),
    goal.saga(),
    currency.saga(),
    conversion.saga(),
    plan.saga(),
    pieChartData.saga(),
    learningVideos.saga(),
  ]);
}
