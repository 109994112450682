import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';

import * as profile from '../../../Account/_redux/profile/profielReducers';

import Cancelled from './Cancelled';
import Fail from './Fail';
import Success from './Success';
import AfterSubscription from './AfterSubscription';
import { goalTopUp, investmentInGoal } from '../../../Goal/_redux/goalActions';
import Loader from '../../../Common/Loader';
import { getUserProfile } from '../../../Account/_redux/profile/profileActions';
import { directInvestment, fundTopUp } from '../../../Plan/_redux/plansActions';
import KycReminder from '../../../Common/KycReminder';

const handleGoalInvestment = async (
  id,
  textReference,
  channel,
  transactionId
) => {
  const response = await investmentInGoal(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId)
  );
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId)
  );
  return response;
};

const handleFundTopUp = async (
  planSubscribtionId,
  textReference,
  channel,
  transactionId
) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel,
    parseInt(transactionId)
  );
  return response;
};

const handleGoalTopUp = async (id, textReference, channel, transactionId) => {
  const response = await goalTopUp(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId)
  );
  return response;
};

const actions = [
  { id: 2, name: 'goalTopUp', invest: handleGoalTopUp },
  { id: 3, name: 'investmentTopup', invest: handleFundTopUp },
  { id: 4, name: 'planInvestmentTopup', invest: handleFundTopUp },
  { id: 5, name: 'goalInvestment', invest: handleGoalInvestment },
  { id: 6, name: 'directInvestment', invest: handleDirectInvestment },
];

const FinalPage = ({ profileAction, profile }) => {
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [sentData, setSentData] = useState();
  const [subscribeToPro, setSubscribeToPro] = useState();
  const [success, setSuccess] = useState(false);
  const [redirectToFunds, setRedirectToFunds] = useState(false);
  const [redirectToGoals, setRedirectToGoals] = useState(false);

  const { status, tx_ref, transaction_id, cardtype } = queryString.parse(
    search
  );

  useEffect(() => {
    let isMounted = true;
    const action = JSON.parse(localStorage.getItem('action'));
    const localData = JSON.parse(localStorage.getItem('goalDataForPayment'));
    const subscriptionId = localStorage.getItem('subscriptionId');

    (localData?.fundId || localData?.planId) &&
      isMounted &&
      setRedirectToFunds(true);
    localData?.goalId && isMounted && setRedirectToGoals(true);

    subscriptionId && isMounted && setSubscribeToPro(subscriptionId);
    setSentData(localData);
    if (!action) {
      history.push('/dashboard');
      return;
    }
    if ((status === 'cancelled' || status === 'failed') && isMounted) {
      setCancelled(true);
      if (action.name === 'subscribe') {
        setSubscribeToPro(action.name);
      }
      isMounted && setLoading(false);
      return;
    }

    const invest = async () => {
      isMounted && setLoading(true);
      actions.forEach(async (item) => {
        if (item.name === `${action.name}`) {
          const id = subscriptionId
            ? subscriptionId
            : localData?.fundId
            ? localData?.fundId
            : localData?.goalId
            ? localData?.goalId
            : localData?.planId;

          try {
            const result = await item.invest(
              id,
              tx_ref,
              cardtype,
              transaction_id
            );

            if (result.status === 201 && isMounted) {
              if (action?.name === 'subscribe') {
                const userProfile = await getUserProfile();
                profileAction(userProfile?.data?.data);
              }
              setLoading(false);
              setSuccess(true);
              localStorage.removeItem('goalDataForPayment');
              localStorage.removeItem('action');
              localStorage.removeItem('planId');
              localStorage.removeItem('planMinimumInvestableAmounts');
              localStorage.removeItem('planCurrency');
              localStorage.removeItem('promoPlanOnOffer');
            }
          } catch (err) {
            localStorage.removeItem('goalDataForPayment');
            localStorage.removeItem('action');
            localStorage.removeItem('planCurrency');
            localStorage.removeItem('promoPlanOnOffer');
            isMounted && setErrorMessage(err.response.data.message);
            isMounted && setLoading(false);
          }
        }
      });
    };

    if (
      status === 'successful' &&
      (localData?.goalId ||
        localData?.planId ||
        localData?.fundId ||
        subscriptionId)
    ) {
      invest();
    }

    return () => {
      isMounted = false;
    };
  }, [status, tx_ref, transaction_id]);

  const handleTryAgain = () => {
    history.push(
      subscribeToPro
        ? '/subscription'
        : sentData?.goalId
        ? `/new-investment/investorInformation/${sentData?.goalId}`
        : '/new-investment/plan-investment-payment'
    );
  };

  let history = useHistory();

  return (
    <>
      {loading ? (
        <Loader />
      ) : cancelled ? (
        <Cancelled handleTryAgain={handleTryAgain} />
      ) : errorMessage ? (
        <Fail error={errorMessage} />
      ) : success && !profile?.kycSubmittedAt ? (
        <KycReminder />
      ) : subscribeToPro ? (
        <AfterSubscription />
      ) : (
        success && (
          <Success
            redirectToFunds={redirectToFunds}
            redirectToGoals={redirectToGoals}
          />
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state?.profile,
});

export default connect(mapStateToProps, { ...profile?.actions })(FinalPage);
