import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { findCurrency } from '../../../helpers/findCurreny';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';

import Input from '../../Common/Input/ControlledInput';
import DisplayCorrectErrorMessage from '../../Goal/components/GoalCalculator/DisplayCorrectErrorMessage';
import TopUpImage from '../../../icons/topUpImage.svg';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';

const InvestmentAmount = ({
  formik,
  goalData,
  handleChange,
  url,
  titleText,
  errorMessage,
  description,
  intl,
  onClick,
}) => {
  const currency = localStorage.getItem('planCurrency');
  const promoPlanOnOffer = localStorage.getItem('promoPlanOnOffer');
  const [promoPlanOnOfferx, setpromoPlanOnOfferx] = useState(false);
  const { goalId } = useParams();

  useEffect(() => {
    const promoPlanOnOffer = localStorage.getItem('promoPlanOnOffer');
    setpromoPlanOnOfferx(promoPlanOnOffer);
  }, [promoPlanOnOffer]);

  const { currencies: allCurrencies } = useSelector(
    (state) => state?.currencies
  );

  const goalAmountNumber = convertAmountToNumber(goalData?.amount);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'GOAL_TOP_UP_PAGE.TITLE' })}
        previousPage={`/portfolio/viewGoal/${goalId}`}
        insideContainer
      />

      <div className="bg-light-blue border-radius-16 py-10 px-5 mt-25">
        <div className="text-center">
          <img src={TopUpImage} alt="Top Up" />
        </div>

        <div className="mb-5 text-center font-size-33">{titleText}</div>
        {description ? (
          <div className="text-center mb-4 font-size-15 ml-5">
            {description}
          </div>
        ) : null}
        <form className="px-md-10" id="kt_form" onSubmit={formik.handleSubmit}>
          <div className="form-group fv-plugins-icon-container ">
            {errorMessage ? (
              <div className="mb-10 ">
                <div
                  className="text-center font-weight-bold"
                  style={{ color: '#F05B7F' }}
                >
                  {errorMessage}
                </div>
              </div>
            ) : null}
            <div className="input-container">
              <div className="mx-5 mb-2 font-size-15">Amount</div>
              <Input
                placeholder={intl.formatMessage({
                  id: 'GOAL_TOP_UP_PAGE.INPUT_PLACEHOLDER_AMOUNT',
                })}
                type="text"
                className={`topup-input-field w-100 border-radius-16 px-7 py-5`}
                name="amount"
                formik={formik}
                defaultValue={goalData?.amount}
                handleChange={handleChange}
              />
            </div>

            {!errorMessage && (
              <DisplayCorrectErrorMessage
                formikError={formik.errors.goalAmount}
                formikTouched={formik.touched.amount}
                amount={goalAmountNumber}
                currency={findCurrency(allCurrencies, goalData?.currency)}
                input="amount"
              />
            )}

            {formik.touched.currency && formik.errors.currency ? (
              <div className="fv-plugins-message-container text-center">
                <div className="fv-help-block">{formik.errors.currency}</div>
              </div>
            ) : null}
          </div>
          <Button
            buttonText={'NEXT'}
            classes={'bg-red white-text font-size-17 w-100 py-5'}
            buttonType={onClick ? 'button' : 'submit'}
            onClick={onClick}
            // onClick={createTheGoal}
          />

          {/* <NextButtonWithDisabled
            amount={goalAmountNumber}
            currency={findCurrency(allCurrencies, goalData?.currency)}
            promoPlanOnOffer={promoPlanOnOfferx}
          /> */}
        </form>
      </div>
    </>
  );
};

export default injectIntl(InvestmentAmount);
