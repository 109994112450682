import { call, put, takeLatest } from 'redux-saga/effects';

import { getUserProfile } from './profileActions';

export const actionTypes = {
  Profile: '[Profile] Action',
  FetchProfile: '[FetchProfile] Action',
  RiskProfileLevels: '[RiskProfileLevels] Action',
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.Profile: {
      const { profile } = action.payload;
      localStorage.setItem('referralCompleted', profile.isReferralCompleted);
      return {
        ...state,
        ...profile,
      };
    }

    case actionTypes.RiskProfileLevels: {
      const { riskProfileLevels } = action.payload;
      return { ...state, riskProfileLevels };
    }

    default:
      return state;
  }
};

export const actions = {
  profileAction: (profile) => ({
    type: actionTypes.Profile,
    payload: { profile },
  }),

  fetchProfileAction: () => ({ type: actionTypes.FetchProfile }),

  riskProfileLevels: (riskProfileLevels) => ({
    type: actionTypes.RiskProfileLevels,
    payload: { riskProfileLevels },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.FetchProfile, function* fetchUserProfile() {
    const { data } = yield call(getUserProfile);
    yield put(actions.profileAction(data.data));
  });
}
