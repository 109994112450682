import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from './Button';

const KycReminder = () => {
  const history = useHistory();
  return (
    <div className="card card-custom card-gutter-b">
      <div className="card-body">
        <div>
          <p className="py-10 text-center">
            Please complete your profile by providing us with your details
            <Link to={'/investor-information'}> here.</Link> As per regulation,
            we are required to verify your identity before placing any trades.
          </p>
        </div>

        <div className="text-center">
          <Button
            btnText="Complete KYC"
            className="text-center common-btn btn-size"
            onClick={() => history.push('/investor-information')}
          />
          <p className="mt-2 cursor-pointer">
            <Link to="/dashboard">Remind me later</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KycReminder;
