export const sataticGoalNames = [
  {
    id: 1,
    choice: 'Retirement',
    assetName: 'retirement',
    goalNameIsRequired: false,
  },
  {
    id: 2,
    choice: 'Housing or Land',
    assetName: 'housing',
  },
  {
    id: 3,
    choice: 'Children Education',
    assetName: 'kids_education',
    goalNameIsRequired: false,
  },
  {
    id: 4,
    choice: 'Car/Bike or holidays',
    assetName: 'car_or_bike',
    goalNameIsRequired: false,
  },
  {
    id: 5,
    choice: 'Others',
    assetName: 'others',
    goalNameIsRequired: true,
  },
];

export const investmentExperienceChoices = [
  {
    id: 1,
    choice: 'WIZARD.INVESTMENT_EXPERIENCE.ANSWER_1',
  },
  {
    id: 2,
    choice: 'WIZARD.INVESTMENT_EXPERIENCE.ANSWER_2',
  },
  {
    id: 3,
    choice: 'WIZARD.INVESTMENT_EXPERIENCE.ANSWER_3',
  },
];

export const familiarInvestmentsChoices = [
  {
    id: 1,
    choice: 'WIZARD.FAMILIAR_INVESTMENT.ANSWER_1',
  },
  {
    id: 2,
    choice: 'WIZARD.FAMILIAR_INVESTMENT.ANSWER_2',
  },
  {
    id: 3,
    choice: 'WIZARD.FAMILIAR_INVESTMENT.ANSWER_3',
  },
  {
    id: 4,
    choice: 'WIZARD.FAMILIAR_INVESTMENT.ANSWER_4',
  },
  {
    id: 5,
    choice: 'WIZARD.FAMILIAR_INVESTMENT.ANSWER_5',
  },

  {
    id: 6,
    choice: 'WIZARD.FAMILIAR_INVESTMENT.ANSWER_6',
  },
];
