import React, { useState, useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';

const DonutChart = ({ percentages, labels, colorsArray }) => {
  const colors = labels.map((label) => colorsArray[label.toLowerCase()]);
  const [config, setConfig] = useState({
    series: percentages,
    options: {
      chart: {
        width: 350,
        type: 'donut',
      },
      labels,
      fill: {
        colors,
      },
      legend: {
        show: false,
      },
      colors,
      plotOptions: {
        pie: {
          donut: {
            size: '55%',
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 280,
            },
            legend: {
              show: false,
            },
          },
        },

        {
          breakpoint: 767,
          options: {
            chart: {
              width: 280,
            },
            legend: {
              show: false,
            },
          },
        },

        {
          breakpoint: 320,
          options: {
            chart: {
              width: 250,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  });
  useEffect(() => {
    setConfig({
      ...config,
      series: percentages,
      options: {
        ...config.options,
        labels,
        colors,
        fill: {
          colors,
        },
      },
    });
  }, [labels, percentages]);
  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="donut"
        width={config.options.chart.width}
      />
    </div>
  );
};

export default DonutChart;
