import React from "react";

const HeaderCard = ({ headerText }) => {
  return (
    <div className="card-header">
      <div className="card-title">
        <h3 className="card-label font-size-h3 text-center">{headerText}</h3>
      </div>
    </div>
  );
};

export default HeaderCard;
