import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { errorMessages } from '../../../helpers/constants';
import Input from '../../Common/Input/Input';
import UnableViewPassword from '../../Common/UnableViewPassword';
import * as profile from '../../Account/_redux/profile/profielReducers';
import { SubmitButton } from '../../Common/buttons/Buttons';
import '../styles/registration.scss';
import { login } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

const initialValues = {
  email: '',
  password: '',
};

const Login = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      localStorage.removeItem('tokenValidated');
      enableLoading();
      try {
        const { data } = await login(
          values.email.toLowerCase(),
          values.password
        );
        disableLoading();
        props.login(data);
        props.profileAction(data.user);
      } catch (error) {
        disableLoading();
        setSubmitting(false);
        setStatus(
          error.response.data
            ? error.response.data.message
            : 'Unable to login.Try again.'
        );
      }
    },
  });

  return (
    <div className="" id="kt_login_signin_form">
      Welcome to AirInvest
    </div>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions, ...profile.actions })(Login)
);
