import React from 'react';
import { useHistory } from 'react-router-dom';
import { FundType } from '../../helpers/constants';
import { messages } from '../../utils/messages';
import PopoverComponent from './PopoverComponent';

export const PlanTitle = ({ data, className }) => {
  const history = useHistory();
  const content = <div>{messages.annualPerformance}</div>;
  const savingFundContent = (
    <div>
      <span>This is the fixed return earned per year.</span>
      <span className="font-weight-bold"> TAX FREE</span>
    </div>
  );
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <div
        onClick={() => history.push(`/plans/${data?.id}`)}
        className="add-space-top"
      >
        <span className="fund-manager-name-container font-weight-bold blue-color-text">
          {data?.coolName || data?.name}
        </span>
      </div>

      <div className="add-space-top">
        <span
          className="font-weight-bold"
          style={{ color: '#3F4254', paddingTop: '7PX' }}
        >
          {data?.performance1 ? `${data?.performance1.toFixed(2)}%` : '---'}
        </span>
        <PopoverComponent
          popoverContent={
            data.fundType === FundType.SAVE ? savingFundContent : content
          }
        >
          <span>
            <i
              style={{
                fontSize: '16px',
                color: '#0071CE',
                marginLeft: '5px',
              }}
              className="fa fa-question-circle"
              aria-hidden="true"
            ></i>
          </span>
        </PopoverComponent>
      </div>
    </div>
  );
};
