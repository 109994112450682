import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { operationType, status } from '../../../helpers/constants';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { messages } from '../../../utils/messages';
import Alert from '../../Common/Alert';
import { fetchSingleGoal, goalWithdraw } from '../_redux/goalActions';
import * as goalActions from '../../Goal/_redux/goalReducers';
import * as pieChartActions from '../../Profile/_redux/profileReducers';

import ConfirmModal from '../../Common/ConfirmModal';

import GoalAmount from '../components/GoalAmount';
import moment from 'moment';
import GoalPieChart from '../../Common/GoalPieChart';
import GoalTragetAmountProgressBar from '../components/TargetAmountProgressBar';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';
import { injectIntl } from 'react-intl';
import RedArrow from '../../../icons/red-arrow.svg';
import { getPieChartDataForGoal } from '../../Profile/_redux/profileaxiosRequest';
import GoalTransactions from '../components/GoalTransactions';

const ViewGoal = (props) => {
  const { intl } = props;
  const history = useHistory();
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [goal, setGoal] = useState({});
  const [open, setOpen] = useState(false);

  const [withdrawalError, setWithdrawalError] = useState(null);

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const { goalDetails } = useSelector((state) => state.goal);
  const { pieChartData } = useSelector((state) => state.pieChartData);

  const fetchGoal = async () => {
    try {
      setLoading(true);
      const res = await fetchSingleGoal(id);
      const goalData = res.data.data;
      props.updateGoalDetails({ ...goalData });
      if (res.status === 200) {
        setGoal(goal);
        const transaction = goal.transactions.find(
          (item) =>
            item.transactionStatus === status.PENDING &&
            item.operationType === operationType.WITHDRAW
        );

        if (transaction) {
          setWithdrawalError({
            alertMessage: messages.withdraw.unpprovedWithdraw,
            alertMessageType: 'alert-light-danger',
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const generatePieChartData = async () => {
    try {
      const response = await getPieChartDataForGoal(id);
      props.getPieChartData(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchGoal();
    generatePieChartData();
  }, []);

  const handleGoalWithdrawals = async () => {
    try {
      const response = await goalWithdraw(id);
      const { data: { message } = {} } = response;
      setSuccessMessage(message);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: 'GOAL_DETAILS.TITLE',
        })}
        insideContainer
        previousPage={'/portfolio'}
      />
      <div className="mt-25">
        {alert.alertMessage ? <Alert alert={alert.alertMessage} /> : null}
        <div className="goal-image-container border-radius-top-16">
          {goalDetails?.goalPortfolioDetails?.marginAmount >= 0 ? (
            <div className="d-inline float-right bg-white mr-5 mt-5 px-5 py-2 border-radius-12 green-text-600 font-weight-bold">
              {goalDetails?.goalPortfolioDetails?.marginAmount}{' '}
              {goalDetails?.currency}
            </div>
          ) : (
            <div className="d-inline float-right bg-white mr-5 mt-5 px-5 py-2 border-radius-12 text-red font-weight-bold">
              {goalDetails?.goalPortfolioDetails?.marginAmount}{' '}
              {goalDetails?.currency}
            </div>
          )}
        </div>

        <div className="bg-light-blue mt-n5 py-5 px-7 border-radius-bottom-16">
          <div className="d-flex align-items-center justify-content-between mt-5">
            <div>{goalDetails?.name}</div>
            {false ? (
              <Button
                buttonText={intl.formatMessage({
                  id: 'GOAL_DETAILS.EDIT_GOAL_BUTTON_TEXT',
                })}
                classes={
                  'bg-light-blue-250 text-black-600 font-size-14 px-5 py-1'
                }
              />
            ) : null}
          </div>

          <div className="my-4">
            <GoalTragetAmountProgressBar
              min={0}
              max={goalDetails?.goalAmount}
              cuurentValue={
                goalDetails?.estimatedBalance
                  ? goalDetails?.estimatedBalance +
                    goalDetails?.depositPendingAmount
                  : goalDetails?.depositPendingAmount
              }
            />
          </div>
          <div className="mt-5">
            <GoalAmount
              estimatedBalance={
                goalDetails?.estimatedBalance
                  ? goalDetails?.estimatedBalance +
                    goalDetails?.depositPendingAmount
                  : goalDetails?.depositPendingAmount
              }
              goalAmount={goalDetails?.goalAmount}
              currency={goalDetails?.currency}
            />
          </div>

          <div className="gray-divider my-3" />

          <div className="d-flex justify-content-between">
            <div>
              {intl.formatMessage({
                id: 'GOAL_DETAILS.TARGET_DATE',
              })}
            </div>
            <div className="red-text">
              {moment(goalDetails?.targetDate).format('ll')}
            </div>
          </div>

          <div className="gray-divider my-3" />

          <div className="d-flex justify-content-between">
            <div>
              {intl.formatMessage({
                id: 'GOAL_DETAILS.MONTHLY_INVESTMENT',
              })}
            </div>
            <div className="red-text">
              {currencyHelper(
                goalDetails?.recurringAmount,
                goalDetails?.currency
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between mt-10">
            <Button
              buttonText={intl.formatMessage({
                id: 'GOAL_DETAILS.WITHDRAW',
              })}
              classes={'bg-red white-text font-size-17 px-5 py-5'}
              onClick={() => setOpen(true)}
            />

            <Button
              buttonText={intl.formatMessage({
                id: 'GOAL_DETAILS.TOP_UP',
              })}
              classes={'bg-green-400 white-text font-size-17 px-11 py-5'}
              onClick={() => {
                localStorage.setItem('action', 'TOP_UP');
                history.push(`/goal/topup/${goalDetails?.id}`);
              }}
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        confirm={handleGoalWithdrawals}
        title={messages.goalWithdraw.title}
        text={messages.goalWithdraw.body}
        success={successMessage}
        error={errorMessage}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
      {pieChartData && (pieChartData['FIXED'] || pieChartData['VARIABLE']) ? (
        <div className="bg-light-blue py-5 px-7 mt-5 border-radius-16">
          <GoalPieChart
            data={[pieChartData['FIXED'], pieChartData['VARIABLE']]}
          />
        </div>
      ) : null}

      {goalDetails?.transactions && goalDetails?.transactions.length ? (
        <div>
          <div className="mt-5 font-size-20">Investment Transactions</div>
          <div className="d-flex justify-content-between mt-5 font-size-17 bg-light-blue border-radius-16 p-5">
            All transactions {`(${goalDetails?.transactions.length})`}
            <div className="ml-3">
              <img src={RedArrow} alt="arrow" />
            </div>
          </div>
        </div>
      ) : null}

      {goalDetails?.transactions && goalDetails?.transactions.length ? (
        <GoalTransactions goalDetails={goalDetails} />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ goal, profile }) => ({
  goal,
  profile,
});

export default injectIntl(
  connect(mapStateToProps, {
    ...goalActions.actions,
    ...pieChartActions.actions,
  })(ViewGoal)
);
