import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import * as Yup from 'yup';
import * as goal from '../../Goal/_redux/goalReducers';

import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { moneyFormat } from '../../../helpers/moneyFormat';
import { amountValidation } from '../../Invest/_redux/investmentAction';
import InvestmentAmount from '../../Invest/InvestmentAmount';

const DirectInitialInvestmentAmount = (props) => {
  const { id: planId } = useParams();
  const { intl } = props;
  const history = useHistory();
  const [goalData, setGoalData] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const discountInfo = JSON.parse(localStorage.getItem('discountInfo'));

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem('goalDataForPayment')
    );

    if (localStorageData?.planId) {
      setGoalData(localStorageData);
    }
  }, []);

  const initialValues = {
    amount: goalData?.amount,
    currency: goalData?.currency,
  };

  const GoalAmountSchema = Yup.object().shape({
    amount: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    currency: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const numbersRegex = /^[0-9,]*$/;
    if (name === 'amount' && numbersRegex.test(value)) {
      setGoalData({
        ...goalData,
        amount: moneyFormat(convertAmountToNumber(value)),
      });
    } else {
      setGoalData({
        ...goalData,
        currency: value,
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: GoalAmountSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      localStorage.removeItem('goalDataForPayment');
      if (discountInfo?.currency !== values.currency) {
        localStorage.removeItem('discountInfo');
        localStorage.removeItem('promoCode');
      }

      if (values.amount !== '') {
        const paymentPayload = {
          amount: values.amount,
          currency: values.currency,
          planId,
        };
        localStorage.setItem(
          'goalDataForPayment',
          JSON.stringify(paymentPayload)
        );
        const goalAmountNumber = convertAmountToNumber(goalData?.amount);
        try {
          await amountValidation(
            goalAmountNumber,
            goalData?.currency,
            Number(planId)
          );
          setSubmitting(true);
          history.push('/new-investment/plan-investment-payment');
        } catch (error) {
          setErrorMessage(error.response.data.message);
        }
      } else {
        setSubmitting(false);
      }
    },
  });

  const pathName = localStorage.getItem('pathName');

  const generateUrlToThePreviousPage = () => {
    let urlToThePreviousPage = '';
    if (pathName.includes('watchlist')) {
      urlToThePreviousPage = `/watchlist/${planId}`;
    } else if (pathName.includes('fixed-return')) {
      urlToThePreviousPage = `/fixed-return/${planId}`;
    } else if (pathName.includes('plans')) {
      urlToThePreviousPage = `/plans/${planId}`;
    }

    return urlToThePreviousPage;
  };

  return (
    <InvestmentAmount
      url={generateUrlToThePreviousPage()}
      formik={formik}
      handleChange={handleChange}
      goalData={goalData}
      titleText="How much do you want to invest?"
      errorMessage={errorMessage}
    />
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(
  connect(mapStateToProps, goal.actions)(DirectInitialInvestmentAmount)
);
