import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const VALIDATE_AMOUNT_URL = `${REACT_APP_BASE_URL}/v1/investor/payments/validate`;
const FETCH_PROMOTION_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/user-vouchers`;
const MPESA_SUBSCRIPTION_PAYMENT = `${REACT_APP_BASE_URL}/v1/investor/mpesa/subscription-payment`;
const BANK_TRANSFER_PAYMENT_URL = `${REACT_APP_BASE_URL}/v1/investor/payments/bank-transfer`;
const GET_BANK_ACCOUNTS = `${REACT_APP_BASE_URL}/v1/investor/currencies/`;

const amountValidation = async (amount, currency, planId) => {
  const response = await axios.post(VALIDATE_AMOUNT_URL, {
    amount,
    currency,
    planId,
  });
  return response;
};

const fetchPromotionDetails = async (code, currency) => {
  const response = await axios.post(`${FETCH_PROMOTION_DETAILS}/verify`, {
    code,
    currency,
  });
  return response;
};

const paySubscriptionFeesWithMpesa = async (
  subscriptionId,
  phoneNumber,
  amount,
  promoCode
) => {
  return axios.post(MPESA_SUBSCRIPTION_PAYMENT, {
    amount,
    promoCode,
    phoneNumber,
    subscriptionId,
  });
};

const payWithBankTransfer = async (amount, currency, promoCode) => {
  const response = await axios.post(BANK_TRANSFER_PAYMENT_URL, {
    amount,
    currency,
    promoCode,
  });
  return response;
};

const getBankAccountDetails = async (currencyId) => {
  const res = await axios.get(
    `${GET_BANK_ACCOUNTS}${currencyId}/bank-account-details`
  );
  return res;
};

export {
  amountValidation,
  fetchPromotionDetails,
  paySubscriptionFeesWithMpesa,
  payWithBankTransfer,
  getBankAccountDetails
};
