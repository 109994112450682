import React from "react";

const RiskProfileImage = ({ image }) => {
  return (
    <div className="text-center mb-5">
      <img src={image} alt="Risk profile" />
    </div>
  );
};

export default RiskProfileImage;
