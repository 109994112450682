import React from 'react';
import { useHistory } from 'react-router-dom';
import '../components/PlanCard/index.scss';
import { PlanTitle } from '../../Common/PlanTitle';
import Card from '../components/PlanCard/Card';
import TopCard from '../components/PlanCard/TopCard';
import ProfileRisk from '../components/PlanCard/ProfileRisk';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';

const SavingPlanCard = ({ data, classes, url }) => {
  const history = useHistory();
  return (
    <Card
      width="saving-fund-width"
      classes={`saving-plan-card-spacing ${classes}`}
    >
      <PlanTitle className={'plan-title px-3'} data={data} />
      {data && data.imageUrl ? (
        <TopCard onClick={() => history.push(url)} imageLink={data.imageUrl}>
          <span className="cash">{firstLetterUpperCase(data?.sector)}</span>
        </TopCard>
      ) : (
        <TopCard onClick={() => history.push(url)}>
          <span className="cash">{firstLetterUpperCase(data?.sector)}</span>
        </TopCard>
      )}

      <div className="plan-card-second-container">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center font-weight-bold lock-in-period-tile px-3"
            onClick={() => history.push(url)}
          >
            Fixed Return
          </div>

          <div onClick={() => history.push(url)} className="fixed-return ml-2">
            <div
              style={{ fontSize: '12px' }}
            >{`${data.lockInPeriod} Months Minimum*`}</div>
          </div>
        </div>

        <div onClick={() => history.push(url)} className="fund-container"></div>

        <ProfileRisk
          planTypeName={data?.planType?.name}
          riskProfile={data?.planRiskLevel}
          onClick={() => history.push(url)}
        />
      </div>
    </Card>
  );
};

export default SavingPlanCard;
