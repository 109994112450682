import React from 'react';

import DashboardCompanyLogos from '../../Dashboard/pages/DashboardCompanyLogos';
import airInvest from '../../../images/airinvest.svg';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';
import { injectIntl } from 'react-intl';

const WelcomePage = ({ intl }) => {
  return (
    <>
      <PageTitle title={'Welcome'} insideContainer />
      <div className="container text-center mt-30 min-vh-100">
        <img src={airInvest} alt="airInvest" />
        <div className="red-text-color font-weight-bolder welcome-text mt-10">
          {intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.WELCOME' })}
        </div>
        <div className="red-text-color font-weight-bold-900 font-size-50">
          {intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.AIRINVEST' })}
        </div>
        <p className="red-text-color">
          {intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.DESCRIPTION' })}
        </p>

        <Button
          classes="bg-red white-text py-5 w-75 mt-5"
          buttonText={intl.formatMessage({ id: 'BUTTONS.NEXT' })}
          link="/how-it-works"
        />
        <p className="mt-3">
          <span className="red-text-color">
            {intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.POWERED' })}{' '}
          </span>
          <span className="red-text-color font-weight-bolder">
            {intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.NDOVU' })}
          </span>
        </p>

        <DashboardCompanyLogos
          containerClass="dashboard-company-logos"
          dashboardCompanyClass="desktop-dashboard-company"
        />
      </div>
    </>
  );
};

export default injectIntl(WelcomePage);
