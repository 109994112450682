import React from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const HistogramChart = ({ graphData }) => {
  const labelFormatter = (value) => {
    if (value) {
      return value + '%';
    }
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={graphData}>
        <XAxis
          dataKey="name"
          style={{ fontSize: '12px' }}
          axisLine={{ stroke: 'transparent' }}
        />
        <YAxis
          dataKey="value"
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          style={{ fontSize: '12px' }}
          axisLine={false}
        />
        <CartesianGrid
          horizontal="true"
          vertical=""
          stroke="#cae2f6"
          style={{ borderBottom: '1px solid' }}
        />
        <Bar
          dataKey="value"
          fill="#2686d5"
          barSize={80}
          label={{
            position: 'center',
            formatter: labelFormatter,
            fontSize: '12px',
            fontWeight: 'bold',
            fill: "#fff"
          }}
          unit="%"
          fontFamily="Poppins"
          fontSize="12px"
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HistogramChart;
