/* eslint-disable jsx-a11y/anchor-is-valid */

import '../../Onboarding/index.scss';

import React from 'react';

const Referral = ({ profile }) => {
  const { userReferralCode } = profile;
  return (
    <>
      <div
        id="card-margin"
        className="card card-custom card-border mx-40 my-15"
      >
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label" style={{ color: '#0071CE' }}>
              Referral Code
            </h3>
          </div>
        </div>
        <di className="text-center">
          <div className="mt-10 mx-5">
            <p>Invite your friends. Earn a referral bonus.</p>
            <p className="font-weight-bold">
              For every friend you refer who invests on ndovu, you will get a
              referral bonus of $5!
            </p>
            <p className="font-weight-bold">
              limited to 5 referrals. Hurry this is a limited offer!
            </p>
          </div>

          <div className="mt-10 font-weight-bold mx-5">
            <span>Referral ID:</span>{' '}
            <span style={{ color: '#0071CE' }}>
              {userReferralCode ? userReferralCode : '00000'}
            </span>
          </div>
          <div className="mx-5 my-10">
            <p> Get your friends in on the fun. Happy Referring!</p>
          </div>
        </di>
      </div>
    </>
  );
};

export default Referral;
