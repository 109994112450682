import React from 'react';

const CongratulationContent = () => {
  return (
    <div>
      <p className="font-weight-bold">
        Your payment was successful. An email with the transaction details has
        been shared.
      </p>
      <ul>
        <li>
          All trade instructions & payments received by 12noon (EAT) are placed
          on the same day.
        </li>
        <li>
          All trade instructions & payments received after 12noon (EAT) will be
          placed on the following day.
        </li>
      </ul>
      <p>
        Where the following day is not a weekend or public holiday in the US or
        Kenya. Please note that all trade instructions are placed on a best
        execution basis.
      </p>
    </div>
  );
};

export default CongratulationContent;
