import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Alert from '../../../Common/Alert';
import { Button } from '../../../Common/AllButtons';
import { CancelButton } from '../../../Common/buttons/Buttons';
import { PageTitle } from '../../../Common/PageTitle';
import './index.scss';

const PhoneNumber = ({
  setPayWithMpesa,
  phoneNumber,
  setPhoneNumber,
  pushNotification,
  intl,
}) => {
  const [showCountDown, setShowCountDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const history = useHistory();

  const handlePhoneNumber = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
  };

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'MPESA_PAGE.TITLE' })}
        insideContainer
        previousPage={'/portfolio/goal/summary'}
      />
      <div className="bg-light-blue border-radius-16 mt-25">
        <div className="pt-20 card-body">
          {alertOn && (
            <div className="mb-5">
              <Alert alert={alert} />
            </div>
          )}
          <p className="text-center phone-number-title">
            Enter your phone number
          </p>
          <div className="mt-5 d-flex justify-content-center">
            <input
              readOnly
              className="input-field country-code"
              type="text"
              value={'+254'}
              style={{
                border: '0.5px solid #FFF',
                background: '#FFF',
                height: '48px',
                width: '100px',
              }}
              autoComplete="off"
            />

            <input
              name="phoneNumber"
              onChange={handlePhoneNumber}
              className="input-field phone-number"
              type="number"
              value={phoneNumber || ''}
              style={{
                border: '0.5px solid #FFF',
                background: '#FFF',
                height: '48px',
                marginLeft: '5px',
              }}
              placeholder="Phone Number"
              autoComplete="off"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
          </div>

          {showCountDown && (
            <div className="m-5 text-center">
              Please wait for{'  '}
              <Countdown
                date={Date.now() + 180000}
                renderer={({ minutes, seconds, completed }) =>
                  !completed && (
                    <span className="font-weight-bolder">
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                  )
                }
              />
              {'  '}
              while your payment is being processed.
            </div>
          )}

          <div className="d-flex justify-content-between my-10">
            <Button
              buttonText={intl.formatMessage({ id: 'BUTTONS.CANCEL' })}
              classes={'bg-red-400 white-text font-size-20 py-5 px-6'}
              onClick={() => history.push('/portfolio/goal/summary')}
            />

            <Button
              buttonText={intl.formatMessage({ id: 'BUTTONS.INVEST_NOW' })}
              classes={'bg-red white-text font-size-20 py-5 px-8'}
              buttonType="submit"
              onClick={() =>
                pushNotification({
                  setShowCountDown,
                  setLoading,
                  setAlert,
                  setAlertOn,
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(PhoneNumber);
