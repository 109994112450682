/* eslint-disable jsx-a11y/anchor-is-valid */
import * as profile from '../Account/_redux/profile/profielReducers';

import { Redirect, Route, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';

import AppFooter from '../../utils/appFooter';
import DisplayAppropriateContent from '../Common/DisplayAppropriateContent';
import ExternaLeft from '../Common/ExternalLeft';
import Instructions from './pages/Instructions';
import OfferCodes from './pages/OfferCodes';
import RiskProfile from './pages/RiskProfile';
import { connect } from 'react-redux';
import { getUserProfile } from '../Account/_redux/profile/profileActions';

const Onboarding = ({ profileAction, userData, profile, match }) => {
  useEffect(() => {
    const getData = async () => {
      const { data: { data: userProfile } = {} } = await getUserProfile();
      profileAction(userProfile);
    };
    getData();
  }, [profileAction]);
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
        >
          <ExternaLeft>
            <DisplayAppropriateContent />
          </ExternaLeft>

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-0 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center external-container mt-lg-0">
              {profile.riskProfile === null && !userData ? (
                <Redirect to="/auth" />
              ) : (
                <Switch>
                  <Route
                    exact
                    path={`${match.path}`}
                    component={() => <RiskProfile userProfileInfo={profile} />}
                  />
                  <Route
                    exact
                    path={`${match.path}/offerCodes`}
                    component={() => <OfferCodes />}
                  />

                  <Route
                    exact
                    path={`${match.path}/instructions`}
                    component={() => <Instructions />}
                  />
                </Switch>
              )}
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                <AppFooter />
              </div>
              {/* remove div for links */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  userData: state.auth.authToken,
});

export default connect(mapStateToProps, { ...profile.actions })(Onboarding);
