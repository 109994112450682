export const routes = {
  wizard: {
    home: '/wizard',
    investingFor: 'select-goal',
    investmentExperience: '/wizard/investment-experience',
    familiarInvestments: '/wizard/familiar-investments',
    register: '/auth/registration',
    login: '/auth/login',
    resetPassword: '/auth/resetpassword',
    resendEmailVerification: '/auth/resendVerification',
    forgotPassword: '/auth/resetpasswordconfirmation',
    instructions: '/onboarding/instructions',
  },
};
