import React, { useState, useEffect } from 'react';

import DisplayMoney from '../DisplayMoney';
import './index.scss';
import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import {
  FundType,
  mathSign,
  paymentMethod,
  paymentMethodCharge,
} from '../../../../helpers/constants';
import PopoverComponent from '../../../Common/PopoverComponent';

const InvestmentPaymentBreakdown = ({
  discount,
  amount,
  currency,
  planDetails,
  activePaymentMethod,
  profile,
  setpayNowAmount,
  minimumAmountError,
}) => {
  const [ndovuPercentage, setNdovuPercentage] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const isCurrentOffer = planDetails?.isCurrentOffer;
  const ndovuFeeCustomFee = planDetails?.ndovuFeeCustomFee;

  useEffect(() => {
    const ndovuPercentage = profile?.subscription?.ndovuPercentageFee;
    const discountedAmount = discount?.amount
      ? convertAmountToNumber(amount) - discount?.amount
      : convertAmountToNumber(amount);

    setNdovuPercentage(ndovuPercentage);
    setDiscountedAmount(discountedAmount);
  }, [activePaymentMethod, amount, profile, discount.amount]);

  const payNowColor = minimumAmountError ? '#F64E60' : '';

  const calculateNdovuFee = () => {
    if (
      isCurrentOffer &&
      ndovuFeeCustomFee !== null &&
      ndovuFeeCustomFee !== undefined
    ) {
      if (ndovuFeeCustomFee <= 0) {
        return 0;
      }

      return (convertAmountToNumber(amount) * ndovuFeeCustomFee) / 100;
    } else if (planDetails?.fundType === FundType.SAVE) {
      return 0;
    }

    if (discountedAmount <= 0) {
      return 0;
    }
    const ndovuFee = discount?.amount
      ? ((convertAmountToNumber(amount) - discount?.amount) * ndovuPercentage) /
        100
      : (convertAmountToNumber(amount) * ndovuPercentage) / 100;
    return ndovuFee;
  };

  const calculateTrnxnFees = () => {
    if (discountedAmount <= 0) {
      return 0;
    }

    let paymentFee = 0;

    if (activePaymentMethod === paymentMethod.KENYAN_CARD) {
      paymentFee =
        (paymentMethodCharge.KENYAN_CREDIT_CARD_PERC * discountedAmount) / 100;
    } else if (activePaymentMethod === paymentMethod.INTERNATIONAL_CARD) {
      paymentFee =
        (paymentMethodCharge.INTERNATIONAL_CREDIT_CARD_PERC *
          discountedAmount) /
        100;
    }
    return paymentFee;
  };

  const calculateInvestedAmount = () => {
    const ndovuFee = calculateNdovuFee();
    const investedAmount =
      convertAmountToNumber(amount) - Number(ndovuFee).toFixed(2);
    return investedAmount;
  };
  const calculatePayNow = () => {
    const trnxFees = calculateTrnxnFees();
    if (discountedAmount <= 0) {
      return 0;
    }
    const payNow =
      trnxFees > 0 ? discountedAmount + trnxFees : discountedAmount;
    setpayNowAmount(payNow);
    return payNow;
  };

  const trnxFees = calculateTrnxnFees();
  const ndovuFee = calculateNdovuFee();

  return (
    <div
      style={{ background: '#f2f8fd', padding: '20px', borderRadius: '5px' }}
    >
      {planDetails ? (
        <div className="d-flex justify-content-between">
          <span>Fund Name </span>
          <span className="font-weight-bold">
            {planDetails?.coolName || planDetails?.name}
          </span>
        </div>
      ) : null}

      <div style={{ color: '#0071CE' }} className="mt-2">
        How much am I paying now?
        <PopoverComponent
          popoverContent={
            'Transaction fees are charged by the payment provider, not ndovu.'
          }
        >
          <span className="cursor-pointer">
            <i
              style={{
                fontSize: '16px',
                color: '#0071CE',
                marginLeft: '5px',
              }}
              className="fa fa-question-circle"
              aria-hidden="true"
            ></i>
          </span>
        </PopoverComponent>
      </div>

      {amount && (
        <DisplayMoney
          text="Amount Selected"
          currency={currency}
          amount={amount}
        />
      )}

      {discount?.amount && (
        <DisplayMoney
          text="Voucher Discount"
          currency={discount?.currency}
          amount={discount?.amount}
          mathSign={mathSign.NEGATIVE}
        />
      )}

      <DisplayMoney
        text="Transaction Fees"
        currency={currency}
        amount={moneyFormat(trnxFees.toFixed(2))}
        mathSign={trnxFees > 0 ? mathSign.POSITIVE : null}
      />

      <DisplayMoney
        text="Pay now"
        currency={currency}
        amount={calculatePayNow()}
        keycustomStyle={{ fontWeight: '800', color: `${payNowColor}` }}
        valuecustomStyle={{ fontWeight: '800', color: `${payNowColor}` }}
        minimumAmountError={minimumAmountError}
      />

      <div style={{ color: '#0071CE' }} className="mt-2">
        How much will get invested ?
        <PopoverComponent
          popoverContent={
            'We believe in being transparent. ndovu fee will be deducted from the payment amount upfront. Balance after ndovu fee deduction will be the final amount reflected in your portfolio.'
          }
        >
          <span className="cursor-pointer">
            <i
              style={{
                fontSize: '16px',
                color: '#0071CE',
                marginLeft: '5px',
              }}
              className="fa fa-question-circle"
              aria-hidden="true"
            ></i>
          </span>
        </PopoverComponent>
      </div>

      <DisplayMoney
        text={`ndovu Fee (${
          isCurrentOffer &&
          ndovuFeeCustomFee !== null &&
          ndovuFeeCustomFee !== undefined
            ? ndovuFeeCustomFee
            : planDetails?.fundType === FundType.SAVE
            ? 0
            : ndovuPercentage
        }%)`}
        currency={currency}
        amount={ndovuFee.toFixed(2)}
        mathSign={mathSign.NEGATIVE}
      />

      <DisplayMoney
        text="Total Invested"
        currency={currency}
        amount={calculateInvestedAmount()}
        keycustomStyle={{ fontWeight: '800' }}
        valuecustomStyle={{ fontWeight: '800' }}
      />
    </div>
  );
};

export default InvestmentPaymentBreakdown;
