import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import * as pieChartActions from '../../Profile/_redux/profileReducers';
import { Divider } from '../../Common/Divider';
import { getPieChartDataByProfile } from '../../Profile/_redux/profileaxiosRequest';
import { GoalSummaryItem } from '../components/GoalSummaryItem';

const FixVariableComponent = ({ intl, getPieChartData }) => {
  const profile = useSelector((state) => state.profile);
  const [fixValue, setFixValue] = useState();
  const [variableValue, setVariableValue] = useState();

  const generatePieChartData = async () => {
    try {
      const response = await getPieChartDataByProfile(profile.riskProfile.id);
      setVariableValue(response.data.data['VARIABLE']);
      setFixValue(response.data.data['FIXED']);
      getPieChartData(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    generatePieChartData();
  }, []);
  return (
    <>
      <GoalSummaryItem
        label={intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.FIX_INVESTMENT',
        })}
        value={`${fixValue}%`}
      />
      <Divider className="gray-divider" />

      <GoalSummaryItem
        label={intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.VARIABLE_INVESTMENT',
        })}
        value={`${variableValue}%`}
      />
    </>
  );
};

export default injectIntl(
  connect(null, { ...pieChartActions.actions })(FixVariableComponent)
);
