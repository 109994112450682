import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
    referralCorporateVoucher: "[referralCorporateVoucher] Action",
};

const initialAuthState = {
    referral: "",
    corporateVoucher: "",
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.referralCorporateVoucher: {
    const { referral } = action.payload
    return { ...state, referral };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
    referralCorporateVoucher: (referral) => ({
        type: actionTypes.corporateVoucher,
        payload: { referral },
    }),
    };

export function* saga() {}
