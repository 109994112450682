/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { actions } from '../../Wizard/_redux/wizardRedux';

import Selector from './Selector';
import { routes } from '../../../utils/routes';
import { investmentExperienceChoices } from '../helpers/choices';
import { PageNumbers } from '../../Common/PageNumbers';
import { PageTitle } from '../../Common/PageTitle';

const InvestmentExperience = ({ intl, investmentExperience }) => {
  let history = useHistory();
  const [wizardData, setWizardData] = useState(null);
  const wizard = useSelector((state) => state.wizard);

  useEffect(() => {
    // const localStorageWizard = JSON.parse(localStorage.getItem('wizard'));
    setWizardData(wizard);
  }, []);

  const clickChoice = (e, id) => {
    const elementsWithAnim = document.querySelectorAll('.animated-card');
    elementsWithAnim.forEach((each) => {
      if (each !== e.target) {
        return each.classList.add('animate__animated', 'animate__fadeOutRight');
      } else {
        return (
          each.classList.remove('unselected-answer-box'),
          each.classList.add(
            'animate__animated',
            'animate__pulse',
            'bg-info-blue'
          )
        );
      }
    });
    e.target.addEventListener('animationend', () => {
      // localStorage.setItem(
      //   'wizard',
      //   JSON.stringify({ ...wizardData, investmentExperience: id })
      // );
      investmentExperience(id);
      history.push(routes.wizard.familiarInvestments);
    });
  };

  return (
    <div>
      <PageTitle
        title={intl.formatMessage({ id: 'WIZARD.INVESTMENT_EXPERIENCE.TITLE' })}
        previousPage="/wizard/select-goal"
      />
      <div className="container mt-35">
        <PageNumbers currentPageNumber={'2'} />

        <Selector
          clickChoice={clickChoice}
          choices={investmentExperienceChoices}
          id={wizardData?.investmentExperience}
          item={wizardData?.investmentExperience}
          prev={routes.wizard.investingFor}
          next={routes.wizard.familiarInvestments}
          action={intl.formatMessage({
            id: 'WIZARD.INVESTMENT_EXPERIENCE.QUESTION',
          })}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wizard: state.wizard,
});

export default injectIntl(
  connect(mapStateToProps, { ...actions })(InvestmentExperience)
);
