import React from 'react';
import { PlanTitle } from '../../../Common/PlanTitle';
import { popoverMessage } from '../../../../helpers/investmentHelpers';
// import { popoverMessage } from '../Helper';
import PlanTypeComponent from '../PlanTypeComponent';
import ExtremelyLowRisk from './ExtremelyLow';
import HighRisk from './HighRisk';

import LowRisk from './LowRisk';
import MediumRisk from './MediumRisk';

const numberOfSquares = [0, 1, 2, 3, 4, 5, 6];

const ProfileRisk = ({
  planTypeName,
  riskProfile,
  bold,
  onClick,
  goalName,
  data,
}) => {
  return (
    <div
      className={
        goalName
          ? 'mt-2'
          : 'd-flex justify-content-between align-items-center mt-2'
      }
    >
      {goalName ? (
        data ? (
          <PlanTitle data={data} />
        ) : (
          goalName
        )
      ) : (
        <div
          style={{
            color: 'rgb(63, 66, 84)',
            fontSize: '14px',
          }}
          className={`mt-2 ${bold}`}
        >
          <PlanTypeComponent
            planTypeName={planTypeName}
            popoverContent={popoverMessage(planTypeName.toLowerCase())}
          />
        </div>
      )}
      {!goalName && (
        <div onClick={onClick}>
          {riskProfile?.id === 1 ? (
            <LowRisk squares={numberOfSquares} />
          ) : riskProfile?.id === 2 ? (
            <MediumRisk squares={numberOfSquares} />
          ) : riskProfile?.id === 3 ? (
            <HighRisk squares={numberOfSquares} />
          ) : (
            riskProfile?.id === 4 && (
              <ExtremelyLowRisk squares={numberOfSquares} />
            )
          )}
          <span
            style={{ fontSize: '12px', color: '#000000' }}
            className={'ml-1 mb-5'}
          >
            Risk level:{' '}
            <span style={{ color: '#0071CE', fontWeight: '500' }}>
              {' '}
              {riskProfile?.name}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default ProfileRisk;
