import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../Common/Button';
import CongratulationContent from '../Common/Button/CongratulationContent';
import KycReminder from '../Common/KycReminder';

const Congratulations = () => {
  const profile = useSelector((state) => state.profile);
  const history = useHistory();
  return !profile?.kycSubmittedAt ? (
    <KycReminder />
  ) : (
    <div className="card card-custom card-gutter-b">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label font-size-h3 text-center">
            Congratulations!
          </h3>
        </div>
      </div>
      <div className="card-body">
        <div className="py-4 px-4 py-md-8 px-md-0 mt-2">
          <CongratulationContent />
        </div>
      </div>

      <div className="text-center mb-10">
        <Button
          btnText="Next"
          className="text-center full-width-small btn-size common-btn"
          onClick={() => {
            localStorage.removeItem('investedIn');
            history.push('/portfolio');
          }}
        />
      </div>
    </div>
  );
};

export default Congratulations;
