import React from 'react';
import { injectIntl } from 'react-intl';
import { vowels } from '../../../helpers/constants';
import balancedProfileImage from '../../../images/balancedProfile.svg';
import greenInfoIcon from '../../../icons/green-info-icon.svg';

const RiskProfile = ({ intl, profile, currentRiskLevel }) => {
  const displayRiskLevel = () => {
    let riskLevel;
    if (currentRiskLevel) {
      riskLevel = (
        <div className="font-size-12 mt-3">
          {intl.formatMessage({
            id: 'PROFILE_PAGE.YOU_ARE',
          })}{' '}
          {vowels.includes(currentRiskLevel.toLowerCase().charAt(0))
            ? intl.formatMessage({
                id: 'PROFILE_PAGE.AN',
              })
            : intl.formatMessage({
                id: 'PROFILE_PAGE.A',
              })}
        </div>
      );
    } else {
      riskLevel = (
        <div className="font-size-12 mt-3">
          {intl.formatMessage({
            id: 'PROFILE_PAGE.YOU_ARE',
          })}{' '}
          {vowels.includes(profile?.riskProfile?.alias.toLowerCase().charAt(0))
            ? intl.formatMessage({
                id: 'PROFILE_PAGE.AN',
              })
            : intl.formatMessage({
                id: 'PROFILE_PAGE.A',
              })}
        </div>
      );
    }

    return riskLevel;
  };
  return (
    <>
      <div className="text-center profile-container py-10 px-5 mt-5">
        <div>
          <img src={balancedProfileImage} alt="Balanced profile" />
        </div>
        {displayRiskLevel()}
        <h2 className="red-text investor-profile-title">
          {profile?.riskProfile?.alias && (
            <span>
              {currentRiskLevel
                ? currentRiskLevel
                : profile?.riskProfile?.alias}{' '}
              {intl.formatMessage({
                id: 'PROFILE_PAGE.INVESTOR',
              })}
            </span>
          )}
        </h2>
        <div className="mt-6 d-flex align-items-start">
          <img src={greenInfoIcon} alt="Information icon" />

          <p className="text-left ml-5 font-size-12">
            {intl.formatMessage({
              id: 'PROFILE_PAGE.RISK_PROFILE.BALANCED.DESCRIPTION',
            })}
          </p>
        </div>
      </div>
    </>
  );
};

export default injectIntl(RiskProfile);
