import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import * as goal from '../../../Goal/_redux/goalReducers';
import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { goalInvestmentWithMPESA } from '../../../Goal/_redux/goalActions';
import { fundInvestmentWithMPESA } from '../../../Plan/_redux/plansActions';
import { paySubscriptionFeesWithMpesa } from '../../_redux/investmentAction';
import PhoneNumber from './PhoneNumber';
import { connect, useSelector } from 'react-redux';

const investmentAction = {
  TOP_UP: 'TOP_UP',
  INITIAL_INVESTMENT: 'INITIAL_INVESTMENT',
};

const PayWithMpesa = ({ setPayWithMpesa, amount, updateGoalDetails }) => {
  const history = useHistory();
  const [promoCode, setPromoCode] = useState();
  const [actionName, setActionName] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const { goalDetails } = useSelector((state) => state.goal);

  useEffect(() => {
    const action = localStorage.getItem('action');
    setActionName(action);
  }, []);

  const handleSubmit = async ({
    setLoading,
    setAlert,
    setAlertOn,
    setShowCountDown,
  }) => {
    const isValid = validator.isMobilePhone(`+254${phoneNumber}`);
    if (isValid) {
      setLoading(true);
      setShowCountDown(true);
      try {
        // SEND PUSH NOTIFICATION ON MOBILE TO APPROVE PAYMENT
        const goalId = goalDetails?.id;

        const response = await goalInvestmentWithMPESA(
          goalId,
          `+254${phoneNumber}`,
          convertAmountToNumber(goalDetails.initialInvestment),
          actionName,
          promoCode
        );

        setLoading(false);
        setShowCountDown(false);
        if (response.status === 201) {
          const action = localStorage.getItem('action');
          localStorage.removeItem('action');
          history.push(
            action === investmentAction.TOP_UP
              ? '/portfolio'
              : '/portfolio/congratulations'
          );
          return;
        }
      } catch (error) {
        setShowCountDown(false);
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage:
            error.response?.data?.message ||
            error.response?.data?.errorMessage ||
            'Something wrong happened',
          alertMessageType: 'error',
        });
      }
    } else {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Please provide a valid phone number e.g. 7********',
        alertMessageType: 'error',
      });
    }
  };

  return (
    <PhoneNumber
      phoneNumber={phoneNumber}
      setPayWithMpesa={setPayWithMpesa}
      setPhoneNumber={setPhoneNumber}
      pushNotification={handleSubmit}
    />
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(
  connect(mapStateToProps, { ...goal.actions })(PayWithMpesa)
);
