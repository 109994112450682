import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';

import { CancelButton, SubmitButton } from '../../Common/buttons/Buttons';
import { Link, Redirect } from 'react-router-dom';
import React, { useState } from 'react';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import Input from '../../Common/Input/Input';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { requestPassword } from '../_redux/authCrud';
import { useFormik } from 'formik';

const initialValues = {
  email: '',
};

const ForgotPassword = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await requestPassword(values.email.toLowerCase());
        setLoading(false);
        setSubmitting(false);
        if (response.status === 200) {
          setStatus(response.data.message);
          setSuccess(true);
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error.response.data.message);
        setSuccess(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <ExternalPageTitle
            title="Forgotten Password?"
            subTitle="Enter the email address associated with your ndovu account to have a password reset request emailed to you"
          />
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
          >
            {formik.status && (
              <div className="mt-15 mb-10 text-center">
                <h4
                  className="font-weight-bold font-size-h4"
                  style={{ color: success ? '#67CCCA' : '#F05B7F' }}
                >
                  {formik.status}
                </h4>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <Input
                placeholder="Email"
                type="email"
                className={`form-control input-field ${getInputClasses(
                  'email'
                )}`}
                name="email"
                formik={formik}
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center justify-content-between mobile-row">
              <Link to="/auth/login" className="btn-mobile">
                <CancelButton BtnText="Cancel" classes="btn-mobile py-4" />
              </Link>
              <SubmitButton
                classes="btn-mobile space-top"
                loading={loading}
                BtnText="Submit"
                disabled={formik.isSubmitting}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
