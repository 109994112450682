import React from 'react';
import { injectIntl } from 'react-intl';
import { fixAndVariableColors } from '../../../helpers/constants';
import DonutChart from '../../Invest/components/DonutChart';
import PieChartDescription from './PieChartDescription';

const GoalPieChart = ({ intl, data }) => {
  return (
    <div>
      <div className="mt-10">
        <DonutChart
          percentages={data}
          labels={[
            intl.formatMessage({
              id: 'GOAL_DETAILS.FIX',
            }),
            intl.formatMessage({
              id: 'GOAL_DETAILS.VARIABLE',
            }),
          ]}
          colorsArray={fixAndVariableColors}
        />
      </div>

      <PieChartDescription />
    </div>
  );
};

export default injectIntl(GoalPieChart);
