import * as auth from "../_redux/authRedux";

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { requestEmailVerification } from "../_redux/authCrud";

const SignupEmailVerification = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const searchParams = new URLSearchParams(props.location.search);
  const verificationCode = searchParams.get("code");
  const id = searchParams.get("id");

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const response = await requestEmailVerification(id, verificationCode);
      setLoading(false);
      if (response.status === 200) {
        setResponseMessage(response.data.message);
        setSuccess(true);
      }
    } catch (error) {
      setResponseMessage(
        error.response.data
          ? error.response.data.message
          : "Verification not successful"
      );
      setLoading(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  if (loading) {
    return <Loader />
  }
  return (
    <div>
      {!loading && !success && (
        <div className="text-center">
          <h3 className="font-size-h3">Verification Error</h3>
          <p>Kindly confirm your verification code</p>
          <h6 className="mt-10" style={{ color: "#F05B7F" }}>
            {responseMessage}
          </h6>
          <div className="text-center">
            <Link
              style={{
                color: "#0071CE",
                background: "rgba(0, 113, 206, 0.25)",
              }}
              to="/auth/resendVerification"
              className="btn font-weight-bold px-9 py-4 my-8"
            >
              Resend Verification Email
            </Link>
          </div>
        </div>
      )}
      {success && !loading && (
        <div>
          <div className="text-center">
            <h3 className="font-size-h3">Successfully Verified</h3>
            <p>Kindly sign in to your account</p>
            <h6 className="mt-10" style={{ color: "#67CCCA" }}>
              {responseMessage}
            </h6>
          </div>
          <div className="mt-lg-20 mt-10 form-group d-flex flex-wrap flex-center">
            <Link to="/auth/login">
              <button
                style={{ color: "#ffffff", background: "#0071CE" }}
                type="button"
                className="btn  font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Sign In
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(SignupEmailVerification));
