/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Switch } from 'react-router-dom';
import React from 'react';

import FamiliarInvestments from './familiarInvestments';
import InvestmentExperience from './InvestmentExperience';
import { connect } from 'react-redux';
import InvestingFor from '../../Instructions/Pages/InvestingFor';
import { WizardHome } from './Home';

const WizardPage = (props) => {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-0 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column flex-left">
              <Switch>
                <Route
                  exact
                  path={`${props.match.path}`}
                  component={() => <WizardHome />}
                />
                <Route
                  exact
                  path={`${props.match.path}/select-goal`}
                  component={() => <InvestingFor />}
                />
                <Route
                  exact
                  path={`${props.match.path}/investment-experience`}
                  component={() => <InvestmentExperience />}
                />
                <Route
                  exact
                  path={`${props.match.path}/familiar-investments`}
                  component={() => <FamiliarInvestments />}
                />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { profile: state.auth.authToken };
};
export default connect(mapStateToProps, null)(WizardPage);
