import React from 'react';

import Document from './Document';
import rightArrow from '../../../../icons/rightArrow.svg';
import linksIcon from '../../../../icons/linksIcon.svg';

const Documents = ({ factSheet, prospectus }) => {
  return (
    <div className="card blue-border mt-5">
      <div className="d-flex flex-column documents-width p-5 center-container">
        <div>
          <p>Links to Documents</p>
          <div className="divider" />
        </div>
        {factSheet ? (
          <Document
            factSheet={factSheet}
            iconA={linksIcon}
            iconB={rightArrow}
            name="Fact Sheet PDF"
          />
        ) : null}
        {prospectus ? (
          <Document
            prospectus={prospectus}
            iconA={linksIcon}
            iconB={rightArrow}
            name="Prospectus"
          />
        ) : null}
      </div>
    </div>
  );
};

export default Documents;
