import conservative from "../../../images/investorProfile.svg";
import aggressive from "../../../images/aggressiveProfile.svg";
import balanced from "../../../images/balancedProfile.svg";

import conservativeInvestorImage from "../../../images/riskProfile/ConservativeInvestorImage.svg";
import balancedInvestorImage from "../../../images/riskProfile/BalancedInvestorImage.svg";
import aggressiveInvestorImage from "../../../images/riskProfile/AggressiveInvestorImage.svg";

const displayImage = (riskProfileId) => {
  let image;
  let elephant;
  let name;
  if (riskProfileId === 1) {
    elephant = conservative;
    image = conservativeInvestorImage;
    name = "Warren Buffett";
  }

  if (riskProfileId === 3) {
    elephant = aggressive;
    image = aggressiveInvestorImage;
    name = "Elon Musk";
  }

  if (riskProfileId === 2) {
    elephant = balanced;
    image = balancedInvestorImage;
    name = "Shawn Corey Carter (Jay Z)";
  }

  return { elephant, image, name };
};

export default displayImage;
