import React from 'react';
import KenyanFlag from '../../../images/flags/KenyaFlag.png';
import ChinaFlag from '../../../images/flags/ChinaFlag.png';
import USAFlag from '../../../images/flags/USAFlag.png';
import Card from './Card';
import LetfContainer from './LetfContainer';
import RigthContainer from './RigthContainer';
import ExtremelyLowRisk from '../../Plan/components/PlanCard/ExtremelyLow';
import LowRisk from '../../Plan/components/PlanCard/LowRisk';
import MediumRisk from '../../Plan/components/PlanCard/MediumRisk';
import HighRisk from '../../Plan/components/PlanCard/HighRisk';

const displayFlag = (countryFlag) => {
  if (countryFlag === 'Kenya') {
    return KenyanFlag;
  }

  if (countryFlag === 'US') {
    return USAFlag;
  }

  if (countryFlag === 'China') {
    return ChinaFlag;
  }

  return USAFlag;
};

const FundCard = ({
  name,
  sector,
  planRiskLevel,
  countryFlag,
  coolName,
  imageUrl,
  annualPerformance,
  isCurrentOffer,
  tenor,
  fundType,
}) => {
  return (
    <Card>
      <LetfContainer fundBackground={imageUrl} sector={sector} />
      <RigthContainer
        coolName={coolName}
        name={name}
        annualPerformance={annualPerformance}
        classNames="height-plan"
        isCurrentOffer={isCurrentOffer}
        tenor={tenor}
        fundType={fundType}
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="text-center right-box">
            <img
              style={{ marginTop: '10px' }}
              src={displayFlag(countryFlag)}
              alt="country flag"
            />
          </div>
          {planRiskLevel?.id ? (
            <div style={{ marginLeft: '5px' }}>
              {planRiskLevel?.id === 1 ? (
                <LowRisk />
              ) : planRiskLevel?.id === 2 ? (
                <MediumRisk />
              ) : planRiskLevel?.id === 3 ? (
                <HighRisk />
              ) : (
                planRiskLevel?.id === 4 && <ExtremelyLowRisk />
              )}
              <span
                style={{ fontSize: '12px', color: '#000000' }}
                className={'ml-1 mb-5 text-center'}
              >
                Risk level:{' '}
                <span className="blue-color-text">{planRiskLevel?.name}</span>
              </span>
            </div>
          ) : null}
        </div>
      </RigthContainer>
    </Card>
  );
};

export default FundCard;
