import React from "react";

const ExternalPageTitle = ({ title, subTitle }) => {
  return (
    <div className="text-center mb-5 mb-lg-10">
      <h1 className="font-size-h1">{title}</h1>
      <p>{subTitle}</p>
    </div>
  );
};

export default ExternalPageTitle;
