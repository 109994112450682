import "./index.scss";

import AppFooter from "../../../utils/appFooter";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ExternaLeft({ children }) {
  return (
    <>
      {/*begin::Aside*/}
      <div
        className="login-aside d-flex flex-row-auto py-10"
        style={{
          background: "rgba(0, 113, 206, 0.15)",
        }}
      >
        {/*begin: Aside Container*/}
        <div className="d-flex flex-column justify-content-start sm-margin-left">
          {/* start:: Aside header */}
          <span className="extenal-ndovu-logo">
            <SVG
              className="mb-md-10 mb-lg-10 mb-1"
              src={toAbsoluteUrl("/media/svg/ndovu/logo.svg")}
              style={{ width: "250px" }}
            />
          </span>
          {/* end:: Aside header */}

          {/* start:: Aside content */}
          <div className="flex-column-fluid d-flex flex-column hr-margins">
            {children}
          </div>
          {/* end:: Aside content */}

          {/* start:: Aside footer for desktop */}
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            {/* Removed Links here */}
            <div>
              <SVG src={toAbsoluteUrl("/media/svg/ndovu/footer-logo.svg")} />
              <div>
                <AppFooter />
              </div>
            </div>
          </div>
          {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/}
      </div>
      {/*begin::Aside*/}
    </>
  );
}
