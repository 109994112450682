import moment from 'moment';
import React from 'react';
import { operationType, status } from '../../../../helpers/constants';
import { currencyHelper } from '../../../../helpers/currencyHelper';
import {
  capitalizeTheFirstLetterOfEachWord,
  firstLetterUpperCase,
} from '../../../../helpers/investmentHelpers';
import PopoverComponent from '../../../Common/PopoverComponent';
import TransactionPopup from '../../../Common/Popup/transactionPopup';
import { goalTransactionsHeader } from '../../../Transaction/pages/transactionsHeader';

const GoalTransactions = ({ goalDetails }) => {
  return (
    <div className="card blue-border mt-7">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {goalTransactionsHeader.map((header) => (
                  <th
                    key={header.columnName}
                    style={{ color: '#0071CE', minWidth: header.width }}
                    scope="col"
                  >
                    {header.columnName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {goalDetails?.transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="td-font-size-14">
                    {transaction?.createdAt
                      ? moment(transaction?.createdAt).format('YYYY-MM-DD')
                      : '---'}
                  </td>

                  <td className="td-font-size-14">
                    {transaction?.planSubscription?.plan?.coolName
                      ? transaction?.planSubscription?.plan?.coolName
                      : '---'}
                  </td>

                  <td className="td-font-size-14">
                    {transaction?.planSubscription?.plan?.fundManager?.fundname
                      ? transaction?.planSubscription?.plan?.fundManager
                          ?.fundname
                      : '---'}
                  </td>

                  <td className="td-font-size-14">
                    {goalDetails?.name ? goalDetails?.name : '---'}
                  </td>

                  <td className="td-font-size-14">
                    {transaction?.convertedFinalAmount
                      ? currencyHelper(
                          transaction?.convertedFinalAmount,
                          transaction?.finalCurrency
                        )
                      : '---'}
                  </td>

                  <td className="td-font-size-14">
                    {transaction?.operationType
                      ? firstLetterUpperCase(transaction?.operationType)
                      : '---'}
                  </td>

                  <td className="td-font-size-14">
                    {transaction?.transactionStatus ? (
                      <span
                        className={`badge badge_${transaction?.transactionStatus?.toLowerCase()} text-white`}
                        style={{ width: '138px' }}
                      >
                        {transaction?.transactionStatus === status.PENDING
                          ? 'Money Received'
                          : capitalizeTheFirstLetterOfEachWord(
                              transaction?.transactionStatus?.replace('_', ' ')
                            )}
                      </span>
                    ) : (
                      '---'
                    )}
                  </td>

                  {transaction?.operationType === operationType.DEPOSIT && (
                    <td className="td-font-size-14">
                      <PopoverComponent
                        popoverContent={TransactionPopup(
                          transaction,
                          transaction.planSubscription?.plan
                        )}
                      >
                        <span>
                          <i
                            style={{
                              color: '#0071CE',
                            }}
                            className="ki ki-bold-more-hor"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </PopoverComponent>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GoalTransactions;
