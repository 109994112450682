import React from 'react';
import { injectIntl } from 'react-intl';
import { SmallCircle } from '../SmallCircle';

const PieChartDescription = ({ intl }) => {
  return (
    <div>
      <div className="d-flex align-items-center mt-5">
        <SmallCircle className={'red-circle'} />
        <div className="text-left ml-5">
          <span className="font-weight-bolder">
            {intl.formatMessage({
              id: 'GOAL_DETAILS.FIX',
            })}
            :
          </span>{' '}
          {intl.formatMessage({
            id: 'GOAL_DETAILS.FIX_DESCRIPTION_1',
          })}{' '}
          <span className="font-weight-bolder">
            {intl.formatMessage({
              id: 'GOAL_DETAILS.FIX_DESCRIPTION_2',
            })}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center mt-5">
        <SmallCircle className={'blue-circle'} />
        <div className="text-left ml-5">
          <span className="font-weight-bolder">
            {intl.formatMessage({
              id: 'GOAL_DETAILS.VARIABLE',
            })}
            :
          </span>{' '}
          {intl.formatMessage({
            id: 'GOAL_DETAILS.VARIABLE_DESCRIPTION_1',
          })}{' '}
          <span className="font-weight-bolder">
            {' '}
            {intl.formatMessage({
              id: 'GOAL_DETAILS.VARIABLE_DESCRIPTION_2',
            })}
          </span>{' '}
          {intl.formatMessage({
            id: 'GOAL_DETAILS.VARIABLE_DESCRIPTION_3',
          })}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(PieChartDescription);
