import axios from "axios";
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const SEND_REFERAL_URL = `${REACT_APP_BASE_URL}/v1/investor/investors/referral-corporate`;


const sendReferalCode = async (referralCode, voucher) => {
  const res = await axios.put(SEND_REFERAL_URL, { referralCode, voucher });
  return res
};

export default sendReferalCode
