import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AllPlans from './pages/Plans';
import PlanDetails from './pages/PlanDetails';
import SavingPlans from './pages/SavingAccounts';
import DirectInitialInvestmentAmount from './pages/DirectInitialInvestmentAmount';
import FundTopUpAmount from './pages/FundTopUpAmount';

const Plan = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/`} component={AllPlans} />
        <Route
          exact
          path={`${match.path}/fixed-return`}
          component={SavingPlans}
        />
        <Route exact path={`${match.path}/:id`} component={PlanDetails} />
        <Route
          exact
          path={`${match.path}/fixed-return/:id`}
          component={PlanDetails}
        />
        <Route
          exact
          path={`${match.path}/initialInvestmentAmount/:id`}
          component={DirectInitialInvestmentAmount}
        />
        <Route
          exact
          path={`${match.path}/topup/:fundId`}
          component={FundTopUpAmount}
        />
      </Switch>
    </>
  );
};

export default Plan;
