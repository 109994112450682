import React from 'react';
import { injectIntl } from 'react-intl';
import SliderInput from '../../Common/SliderInput';

const ChangeRiskProfile = ({
  intl,
  handleChange,
  riskProfileAlias,
  riskProfileId,
  generateRiskProfileLevel,
}) => {
  return (
    <>
      <div className="mt-5 bg-light-blue p-5 border-radius-16">
        <SliderInput
          handleChange={handleChange}
          title={intl.formatMessage({
            id: 'GOAL_CALCULATOR_PAGE.LABEL.RISK_LEVEL',
          })}
          rangeInputName="riskLevel"
          sliderValue={riskProfileId || generateRiskProfileLevel()?.id}
          silderLabels={
            <div className="d-flex justify-content-between mx-auto risk-profile-types goal-amount-slider-container mt-3">
              <p>
                {intl.formatMessage({
                  id: 'GOAL_CALCULATOR_PAGE.RISK_LEVEL.CONSERVATIVE',
                })}
              </p>
              <p>
                {intl.formatMessage({
                  id: 'GOAL_CALCULATOR_PAGE.RISK_LEVEL.BALANCED',
                })}
              </p>
              <p>
                {intl.formatMessage({
                  id: 'GOAL_CALCULATOR_PAGE.RISK_LEVEL.ADVENTUROUS',
                })}
              </p>
            </div>
          }
          min={1}
          max={3}
          step={1}
          input={
            <>
              <div className="goal-amount-slider-container font-weight-bolder font-size-20 mx-auto py-3 input-styles">
                {riskProfileAlias
                  ? riskProfileAlias
                  : generateRiskProfileLevel()?.alias}
              </div>
            </>
          }
        />
        <p className="font-size-12 mx-5 my-10">
          {intl.formatMessage({
            id: 'PROFILE_PAGE.RISK_PROFILE.BALANCED.DESCRIPTION',
          })}
        </p>
      </div>
    </>
  );
};

export default injectIntl(ChangeRiskProfile);
