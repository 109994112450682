import React from 'react';
import { useHistory } from 'react-router-dom';
import Exit from './Exit';
import { WizardQuestionTitle } from '../../Common/WizardQuestionTitle';
import { injectIntl } from 'react-intl';

const Selector = ({ clickChoice, choices, id, action, subAction, intl }) => {
  const history = useHistory();
  const isRetakingWizard = localStorage.getItem('retakingTheWizard');
  const cancelWizard = () => {
    localStorage.removeItem('wizard');
    localStorage.removeItem('retakingTheWizard');
    history.push('/dashboard');
  };
  return (
    <div
      className="wizard wizard-1 animate__animated animate__fadeInUp"
      id="kt_wizard"
      data-wizard-state="step-first"
      data-wizard-clickable="false"
    >
      {/* <!--begin::Wizard Body--> */}
      <div className="row justify-content-center px-1 px-lg-10">
        <div className="col-xl-12">
          {/* <!--begin::Wizard Form--> */}
          <form className="form">
            <div
              // className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <WizardQuestionTitle title={action} />

              <div>
                {subAction ? <p>{subAction}</p> : ''}
                {isRetakingWizard ? <Exit cancelWizard={cancelWizard} /> : null}
              </div>
            </div>
            <div className="row">
              {choices.map((eachChoice) => {
                return (
                  <div className="col-xl-12" key={eachChoice.id}>
                    {eachChoice.id === id ? (
                      <div
                        onClick={(e) => clickChoice(e, eachChoice.id)}
                        className="card-custom wizard-custom-selector animated-card gutter-b selected-answer-box"
                      >
                        {/* <!--begin::Body--> */}
                        <div
                          className="card-body d-flex flex-row"
                          style={{ padding: '1.5rem 2.25rem' }}
                        >
                          <div className="font-size-h6">
                            {intl.formatMessage({ id: eachChoice.choice })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={(e) => clickChoice(e, eachChoice.id)}
                        className="animated-card card-custom wizard-custom-selector gutter-b unselected-answer-box"
                      >
                        <div
                          className="card-body d-flex flex-row"
                          style={{ padding: '1.5rem 2.25rem' }}
                        >
                          <div className="font-size-h6">
                            {intl.formatMessage({ id: eachChoice.choice })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Selector);
