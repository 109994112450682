import React from 'react';

import backArrow from '../../../icons/backArrow.svg';

const Back = ({ onClick, text }) => {
  return (
    <div
      style={{ width: 'fit-content' }}
      className="cursor-pointer mb-5"
      onClick={onClick}
    >
      <img src={backArrow} alt="Back arrow" />
      <span className="ml-1">{text}</span>
    </div>
  );
};

export default Back;
