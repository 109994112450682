import React from "react";

const Document = ({ factSheet, prospectus, iconA, iconB, name }) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() =>
        window.open(factSheet ? factSheet : prospectus ? prospectus : "#")
      }
    >
      <div className="d-flex justify-content-between">
        <img src={iconA} alt="PDFIcon" />
        <p className="mt-5">{name}</p>
        <img src={iconB} alt="Arrow icon" />
      </div>
      <div style={{ marginTop: "1px" }} className="divider" />
    </div>
  );
};

export default Document;
