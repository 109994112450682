import React from 'react';
import { FundType } from '../../../../helpers/constants';

import { investmentsCopy } from '../../../../utils/investmentsCopy';

const HistoricalPerformance = ({ children, plan }) => {
  return (
    <div className="card mt-5 blue-border">
      <div className="d-flex text-center performance-container p-5 my-5">
        <div className="mr-5 width">
          <div className="fund-title historical-margin">
            {investmentsCopy.performance.title}
          </div>
          <div className="divider" />
          <div className="mt-5" style={{ fontSize: '14px' }}>
            {plan.fundType === FundType.SAVE
              ? investmentsCopy.performance.descriptionForSavingFund
              : investmentsCopy.performance.description}
          </div>
        </div>
        <div className="width my-10">{children}</div>
      </div>
    </div>
  );
};

export default HistoricalPerformance;
