import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const PIE_CHART_DATA_PROFIT = `${REACT_APP_BASE_URL}/v1/investor/goals/investment-profile-split`;
const PIE_CHART_DATA_FOR_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals`;

const getPieChartDataByProfile = async (riskProfileId) => {
  const res = await axios.get(`${PIE_CHART_DATA_PROFIT}/${riskProfileId}`);
  return res;
};

const getPieChartDataForGoal = async (goalId) => {
  const res = await axios.get(
    `${PIE_CHART_DATA_FOR_GOAL}/${goalId}/portfolio-split`
  );
  return res;
};

export { getPieChartDataByProfile, getPieChartDataForGoal };
