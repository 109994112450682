import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_ALL_PLANS = `${REACT_APP_BASE_URL}/v1/investor/plans-new`;
const FETCH_A_SINGLE_PLAN_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/plans/`;
const FETCH_GRAPH_DATA = `${REACT_APP_BASE_URL}/v1/investor/plans/`;
const CREATE_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions`;
const MPESA_FUND_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/mpesa/fund-investment`;
const FUND_TOPUP_URL = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/`;
const WITHDRAL_URL = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/`;
const ADD_TO_WATCH_LIST = `${REACT_APP_BASE_URL}/v1/investor/watch-lists`;
const REMOVE_FROM_WATCH_LIST = `${REACT_APP_BASE_URL}/v1/investor/watch-lists/`;

const fetchPlans = async (fundType) => {
  const res = await axios.get(`${FETCH_ALL_PLANS}?fundType=${fundType}`);
  return res;
};

const fetchSinglePlanDetails = async (planId) => {
  const res = await axios.get(`${FETCH_A_SINGLE_PLAN_DETAILS}${planId}`);
  return res;
};

const fetchGraphData = async (planId, numOfMonths) => {
  const res = await axios.get(
    `${FETCH_GRAPH_DATA}${planId}/graph?range=${numOfMonths}`
  );
  return res;
};

/**
 * Handle paymentSubscription/fund investment or Top up using MPESA
 * @param {number} id Identification of the fund or plan-subscription
 * @param {string} phoneNumber user's phone number to charge
 * @param {string} amount Amount to pay with no promo code applied
 * @param { enum } type type of investment <INITIAL_INVESTMENT | TOP_UP>
 * @param {string} promoCode promotion code if any. It should be null.
 */
const fundInvestmentWithMPESA = async (
  id,
  phoneNumber,
  amount,
  type,
  promoCode
) => {
  return axios.post(MPESA_FUND_INVESTMENT, {
    id: parseInt(id),
    phoneNumber,
    amount,
    promoCode,
    type,
  });
};

const directInvestment = async (
  planId,
  txReference,
  channel,
  transactionId
) => {
  const res = await axios.post(CREATE_INVESTMENT, {
    planId,
    txReference,
    channel,
    transactionId,
  });
  return res;
};

const fundTopUp = async (
  planSubscribtionId,
  txReference,
  channel,
  transactionId
) => {
  const res = await axios.post(
    `${FUND_TOPUP_URL}${planSubscribtionId}/top-up`,
    {
      txReference,
      channel,
      transactionId,
    }
  );
  return res;
};

const withdraw = async (planId) => {
  const response = await axios.post(`${WITHDRAL_URL}${planId}/widthraw`);
  return response;
};

const addToWatchList = async (id) => {
  const res = await axios.post(ADD_TO_WATCH_LIST, {
    planId: id,
  });
  return res;
};

const removeFromWatchList = async (id) => {
  const res = await axios.delete(`${REMOVE_FROM_WATCH_LIST}${id}`);
  return res;
};

export {
  fetchPlans,
  fetchSinglePlanDetails,
  fetchGraphData,
  fundInvestmentWithMPESA,
  directInvestment,
  fundTopUp,
  withdraw,
  addToWatchList,
  removeFromWatchList,
};
