import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const REMOVE_VOUCHER_CORPORATE = `${REACT_APP_BASE_URL}/v1/investor/voucher-corporate/remove`;

const removeVoucherCorporate = async (voucher) => {
  const res = await axios.delete(`${REMOVE_VOUCHER_CORPORATE}`);
  return res;
};

export default removeVoucherCorporate;
