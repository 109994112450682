import React from 'react';
import { injectIntl } from 'react-intl';

const ChangeRiskLevelDiscraimer = ({ intl, riskProfile }) => {
  return (
    <div className="bg-light-blue px-5 py-8 border-radius-16 mt-5">
      <div className="font-weight-bold">
        {intl.formatMessage({
          id: 'PROFILE_PAGE.RISK_PROFILE.NOTE',
        })}
      </div>
      <div>
        <span>
          {intl.formatMessage({
            id: 'PROFILE_PAGE.RISK_PROFILE.DISCLAIMER_1',
          })}
        </span>{' '}
        <span className="red-text">
          {intl.formatMessage({
            id: `PROFILE_PAGE.RISK_PROFILE_LEVEL.${riskProfile.toUpperCase()}`,
          })}
        </span>{' '}
        <span>
          {intl.formatMessage(
            {
              id: 'PROFILE_PAGE.RISK_PROFILE.DISCLAIMER_2',
            },
            { riskProfile }
          )}
        </span>
      </div>
    </div>
  );
};

export default injectIntl(ChangeRiskLevelDiscraimer);
