import React from 'react';

const MediumRisk = () => {
  const squares = [0, 1, 2, 3, 4];
  return (
    <div className="d-flex justify-content-center">
      <span className="risk-profile-little-square blue "></span>
      <span className="risk-profile-little-square blue ml-1"></span>
      {squares.map((item) => (
        <span
          key={item}
          className="risk-profile-little-square gray ml-1"
        ></span>
      ))}
    </div>
  );
};

export default MediumRisk;
