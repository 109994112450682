import React, { useEffect, useState } from 'react';

const PaymentMethodRadioButton = ({
  label,
  processingDays,
  activePaymentMethod,
  name,
  setActivePaymentMethod,
  fee,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(activePaymentMethod);
  }, [activePaymentMethod]);

  return (
    <div
      style={{ background: '#EEF2F7', borderRadius: '5px' }}
      className="form-group p-2"
    >
      <label className="cursor-pointer d-flex">
        <div>
          <div>
            <input
              type="radio"
              onChange={(e) => setActivePaymentMethod(e.target.name)}
              name={name}
              checked={paymentMethod === name}
              className="mt-1 mr-2"
            />
            <span style={{ color: '#0071CE', fontWeight: '500' }}>{label}</span>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <span style={{ display: 'block' }}>{fee}</span>
            <span style={{ display: 'block' }}>{processingDays}</span>
          </div>
        </div>
      </label>
      <span />
    </div>
  );
};

export default PaymentMethodRadioButton;
