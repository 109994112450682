import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import DisplayAppropriateContent from '../../Common/DisplayAppropriateContent';
import ExternaLeft from '../../Common/ExternalLeft';
import Input from '../../Common/Input/Input';
import Loader from '../../Common/Loader';
import { SubmitButton } from '../../Common/buttons/Buttons';
// import { SubmitButton } from '../../Wizard/pages/buttons/Buttons';

import {
  generateAccessTokenAndSendMail,
  sendAccessToken,
} from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

const initialValues = {
  token: '',
};

const TokenAuth = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailErrorMessage, setMailErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const TokenSchema = Yup.object().shape({
    token: Yup.string()
      .min(4, 'Minimum 6 digits')
      .max(6, 'Maximum 6 digits')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  useEffect(() => {
    localStorage.removeItem('tokenValidated');
    async function sendTokenMail() {
      try {
        const response = await generateAccessTokenAndSendMail();
        if (response.status === 200) {
          setMailSent(true);
        } else {
          setMailErrorMessage(response.data.message);
        }
      } catch (error) {
        setMailErrorMessage(error.response.data.message);
      }
    }
    sendTokenMail();
  }, []);

  const resendTokenMail = async () => {
    setMailSent(false);
    setMailErrorMessage('');
    formik.resetForm();
    try {
      const response = await generateAccessTokenAndSendMail();
      if (response.status === 200) {
        setMailSent(true);
      } else {
        setMailErrorMessage(response.data.message);
      }
    } catch (error) {
      setMailErrorMessage(error.response.data.message);
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const exitTokenPage = () => {
    localStorage.removeItem('ndovuToken');
    localStorage.removeItem('tokenValidated');
    window.location.replace('/auth/login');
  };

  const displayAuthActionLink = () => {
    return (
      <>
        <span
          style={{ color: '#0071CE' }}
          className="font-weight-bold cursor-pointer ml-2"
          id="kt_login_signup"
          onClick={() => exitTokenPage()}
        >
          {' '}
          Exit Page{' '}
        </span>
      </>
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: TokenSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      try {
        const response = await sendAccessToken(values.token);

        if (response.status === 200) {
          props.accessTokenValidated(true);
          setSuccessMessage(response.data.message);
          disableLoading();
        } else {
          props.accessTokenValidated(false);
          disableLoading();
        }
      } catch (error) {
        disableLoading();
        setSubmitting(false);
        setMailErrorMessage(
          error.response.data
            ? error.response.data.message
            : 'Unable to validate token.Try again.'
        );
      }
    },
  });

  const renderAccessForm = () => {
    return (
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="text-center mb-5 mb-lg-10">
          <h1 className="font-size-h1">Access Token</h1>
          <p>Enter your 6 digit token</p>
        </div>

        {/* end::Head */}

        {/* {*begin::Form*} */}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {successMessage ? (
            <div className="mb-10">
              <div
                className="text-center font-weight-bold"
                style={{ color: '#67CCCA' }}
              >
                {successMessage}
              </div>
            </div>
          ) : (
            ''
          )}

          {mailSent ? (
            <>
              <div className="form-group fv-plugins-icon-container">
                {mailErrorMessage ? (
                  <div className="mb-10 ">
                    <div
                      className="text-center font-weight-bold"
                      style={{ color: '#F05B7F' }}
                    >
                      {mailErrorMessage}
                    </div>
                  </div>
                ) : null}
                <>
                  <Input
                    placeholder="Access Token"
                    type="number"
                    className={`form-control input-field ${getInputClasses(
                      'token'
                    )}`}
                    name="token"
                    formik={formik}
                    autoComplete="off"
                  />
                  {formik.touched.token && formik.errors.token ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.token}</div>
                    </div>
                  ) : null}
                </>
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <span
                  className="cursor-pointer"
                  style={{ color: '#0071CE' }}
                  onClick={resendTokenMail}
                >
                  Resend Access Token
                </span>

                <SubmitButton
                  loading={loading}
                  BtnText="Submit"
                  disabled={formik.isSubmitting}
                  classes="btn-mobile space-top"
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </form>
        {/* {*end::Form*} */}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <ExternaLeft>
            <DisplayAppropriateContent />
          </ExternaLeft>

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              {displayAuthActionLink()}
            </div>
            {/*end::Content header*/}
            {/* begin::Content body */}

            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              {renderAccessForm()}
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(TokenAuth));
