import * as Yup from 'yup';
import * as profile from '../../Account/_redux/profile/profielReducers';

import React, { useState } from 'react';

import Alert from '../../Common/Alert';
import { SubmitButton } from '../../Common/buttons/Buttons';
import { connect } from 'react-redux';
import { getUserProfile } from '../../Account/_redux/profile/profileActions';
import sendReferalCode from '../../Onboarding/_redux/ReferalAction';
import { useFormik } from 'formik';

const DiscountInactive = ({ profileAction }) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const initialValues = {
    voucher: '',
  };
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };
  const referalCodeSchema = Yup.object().shape({
    voucher: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(8, 'Maximum 8 characters'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: referalCodeSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const response = await sendReferalCode(null, values.voucher);

        if (response.status === 200) {
          formik.resetForm();
          const userProfile = await getUserProfile();
          profileAction(userProfile.data.data);
        }
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
        setTimeout(() => {
          setAlertOn(false);
        }, 3000);
      }
    },
  });
  return (
    <>
      <div
        id="card-margin"
        className="card card-custom card-border mx-40 my-15"
      >
        <div className="card-header mx-1">
          <div className="card-title">
            <h3 className="card-label" style={{ color: '#0071CE' }}>
              Corporate code
            </h3>
          </div>
        </div>
        <div className="mt-10 mx-5">
          <p>
            We work with employers to provide their employees with a discount
            when it comes to building wealth.
          </p>
          <p>
            If you have a corporate code, please insert it here to unlock your
            discount.
          </p>
          <p>
            If you would like us to partner with your employer, email us at{' '}
            <strong>support@ndovu.co</strong>
          </p>
        </div>

        <div className="form-group fv-plugins-icon-container m-10">
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework px-md-20 px-lg-40"
          >
            <input
              placeholder="Voucher"
              type="text"
              onInput={() =>
                setAlert({
                  alertMessage: null,
                  alertMessageType: null,
                })
              }
              className={`form-control  py-5 px-6 ${getInputClasses(
                'voucher'
              )}`}
              name="voucher"
              {...formik.getFieldProps('voucher')}
              style={{
                border: '0.5px solid #CAE2F6',
                background: '#F5F9FD',
                height: '48px',
              }}
              autoComplete="off"
            />
            {<Alert alert={alert} />}
            {formik.touched.voucher && formik.errors.voucher ? (
              <div
                className="text-center font-weight-bold"
                style={{ color: '#F05B7F' }}
              >
                {formik.errors.voucher}
              </div>
            ) : null}

            <div className="text-center my-10">
              <SubmitButton />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(
  DiscountInactive
);
