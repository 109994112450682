import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Congratulations from './Congratulations';
import NewInvestment from './NewInvestment';
import PlanInvestmentPayment from './PlanInvestmentPayment';
import InvestorProfile from './components/InvestorProfile';

const Invest = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={NewInvestment} />
        <Route
          exact
          path={`${match.path}/investorInformation/:id`}
          component={InvestorProfile}
        />
        <Route
          exact
          path={`${match.path}/plan-investment-payment`}
          component={PlanInvestmentPayment}
        />
        <Route
          exact
          path={`${match.path}/congratulations`}
          component={Congratulations}
        />
      </Switch>
    </>
  );
};

export default Invest;
