import React from "react";

const BankTransferRadio = ({
  checked,
  label,
  name,
  handleCheckBoxChange,
}) => {
  return (
    <div className="form-group">
      <label className="cursor-pointer d-flex">
        <input
          type="checkbox"
          onChange={handleCheckBoxChange}
          name={name}
          checked={checked}
          className="mt-1 mr-2"
        />
        <span>{label}</span>
      </label>
      <span />
    </div>
  );
};

export default BankTransferRadio;
