import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import * as goal from '../../Goal/_redux/goalReducers';
import InvestmentAmount from '../../Invest/InvestmentAmount';

import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { moneyFormat } from '../../../helpers/moneyFormat';
import { amountValidation } from '../../Invest/_redux/investmentAction';

const FundTopUpAmount = (props) => {
  const { fundId } = useParams();
  const { intl } = props;
  const history = useHistory();
  const [goalData, setGoalData] = useState();
  const [planId, setPlanId] = useState();
  const [backToFund, setBackToFund] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem('goalDataForPayment')
    );
    const action = JSON.parse(localStorage.getItem('action'));

    action?.name === 'investmentTopup' && setBackToFund(true);

    const getPlanId = localStorage.getItem('planId');
    getPlanId && setPlanId(getPlanId);

    if (localStorageData?.fundId) {
      setGoalData(localStorageData);
    }
  }, []);

  const discountInfo = JSON.parse(localStorage.getItem('discountInfo'));

  const initialValues = {
    amount: goalData?.amount,
    currency: goalData?.currency,
  };

  const GoalAmountSchema = Yup.object().shape({
    amount: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    currency: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const numbersRegex = /^[0-9,]*$/;

    if (name === 'amount' && numbersRegex.test(value)) {
      setGoalData({
        ...goalData,
        amount: moneyFormat(convertAmountToNumber(value)),
      });
    } else {
      setGoalData({
        ...goalData,
        currency: value,
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: GoalAmountSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      localStorage.removeItem('goalDataForPayment');
      if (discountInfo?.currency !== values.currency) {
        localStorage.removeItem('discountInfo');
        localStorage.removeItem('promoCode');
      }
      if (values.amount !== '') {
        const paymentPayload = {
          amount: values.amount,
          currency: values.currency,
          fundId,
        };
        localStorage.setItem(
          'goalDataForPayment',
          JSON.stringify(paymentPayload)
        );
        const goalAmountNumber = convertAmountToNumber(goalData?.amount);
        try {
          await amountValidation(
            goalAmountNumber,
            goalData?.currency,
            Number(planId)
          );
          setSubmitting(true);
          history.push('/new-investment/plan-investment-payment');
        } catch (error) {
          setErrorMessage(error.response.data.message);
        }
      } else {
        setSubmitting(false);
      }
    },
  });

  return (
    <InvestmentAmount
      url={
        backToFund ? `/portfolio/viewInvestment/${fundId}` : `/plans/${planId}`
      }
      formik={formik}
      handleChange={handleChange}
      goalData={goalData}
      titleText={'How much do you want to top up?'}
      errorMessage={errorMessage}
    />
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(
  connect(mapStateToProps, goal.actions)(FundTopUpAmount)
);
