export const actionTypes = {
  UpdatePlanDetails: '[UpdatePlanDetails] Action',
};

const initiaPlanDetailsState = {};

export const reducer = (state = initiaPlanDetailsState, action) => {
  switch (action.type) {
    case actionTypes.UpdatePlanDetails: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  updatePlanDetails: (planDetails) => ({
    type: actionTypes.UpdatePlanDetails,
    payload: { planDetails },
  }),
};

export function* saga() {}
