import React from 'react';
import PopoverComponent from '../../../Common/PopoverComponent';
import { capitalizeTheFirstLetterOfEachWord } from '../../../../helpers/investmentHelpers';

const PlanTypeComponent = ({ planTypeName, popoverContent }) => {
  return (
    <div>
      <span className="p-1 text-dark font-weight-bold pl-md-5 font-size-14">
        {capitalizeTheFirstLetterOfEachWord(planTypeName)}
      </span>
      {popoverContent ? (
        <PopoverComponent popoverContent={popoverContent}>
          <span className="cursor-pointer">
            <i
              style={{
                fontSize: '16px',
                color: '#0071CE',
              }}
              className="fa fa-question-circle"
              aria-hidden="true"
            ></i>
          </span>
        </PopoverComponent>
      ) : null}
    </div>
  );
};

export default PlanTypeComponent;
