import React from 'react';

import './index.scss';

const WarningMessage = ({ message, classes }) => {
  return (
    <div className={`${classes} warning-message-container`}>
      <div>{message}</div>
    </div>
  );
};

export default WarningMessage;
