import React from 'react';
import { FundType } from '../../../helpers/constants';
import { messages } from '../../../utils/messages';
import PopoverComponent from '../PopoverComponent';

const RigthContainer = ({
  name,
  children,
  classes,
  classNames,
  coolName,
  annualPerformance,
  isCurrentOffer,
  tenor,
  fundType,
}) => {
  return (
    <div className={`plan-details-second ${classNames}`}>
      <div className=" right-container-mt">
        <div className="d-flex justify-content-between align-items-center">
          <div className="plan-cool-name">
            <div className="mb-3" style={{ fontSize: '20px' }}>
              {coolName || name}
            </div>
          </div>

          <div
            style={{
              color: 'rgb(63, 66, 84)',
              fontSize: '14px',
            }}
            className="blue-color-text"
          >
            {annualPerformance ? (
              <div>
                <span className="p-1 text-dark font-weight-bold pl-md-5 font-size-14">
                  {`${annualPerformance}%`}
                </span>
                <PopoverComponent
                  popoverContent={
                    fundType === FundType.SAVE
                      ? messages.annualPerformanceSavingProduct
                      : messages.annualPerformance
                  }
                >
                  <span className="cursor-pointer">
                    <i
                      style={{
                        fontSize: '16px',
                        color: '#0071CE',
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    ></i>
                  </span>
                </PopoverComponent>
              </div>
            ) : null}
          </div>
        </div>
        <div className="blue-separator" />
        <div className={`d-flex justify-content-between mt-5 ${classes}`}>
          {children}
        </div>

        {isCurrentOffer && tenor ? (
          <div className=" right-container-mt">
            <div className="d-flex  align-items-center">
              <div>
                Investment Tenor:
                <span
                  style={{
                    fontSize: '16px',
                    color: '#2686d5',
                    marginLeft: '10px',
                    fontWeight: 700,
                  }}
                >
                  {tenor}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RigthContainer;
