import React from 'react';
import { useHistory } from 'react-router-dom';
import goalIcon from '../../../../icons/goalIcon.svg';
import smallTriangleGreen from '../../../../icons/smallTriangleGreen.svg';
import smallTriangleRed from '../../../../icons/smallTriangleRed.svg';
import Calendar from '../../../../icons/Calendar.svg';
import GoalTragetAmountProgressBar from '../TargetAmountProgressBar';
import moment from 'moment';
import GoalAmount from '../GoalAmount';
import { injectIntl } from 'react-intl';
import DisplayProfit from '../DisplayProfit';

const SingleGoal = ({
  name,
  currency,
  goalAmount,
  estimatedBalance,
  createdAt,
  id,
  goalPortfolioDetails,
  intl,
  depositPendingAmount,
}) => {
  const history = useHistory();
  return (
    <div
      className="cursor-pointer"
      onClick={() => history.push(`/portfolio/viewGoal/${id}`)}
    >
      <div className="bg-light-blue p-5 border-radius-16 mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div>
              <img src={goalIcon} alt="Goal Icon" />
            </div>
            <div className="ml-3">
              <div>
                {goalPortfolioDetails?.marginAmount >= 0 ? (
                  <DisplayProfit
                    currency={currency}
                    profit={goalPortfolioDetails?.marginAmount}
                    icon={smallTriangleGreen}
                  />
                ) : (
                  <DisplayProfit
                    currency={currency}
                    profit={goalPortfolioDetails?.marginAmount}
                    icon={smallTriangleRed}
                  />
                )}
                <div className="font-size-20 font-weight-bold">
                  {intl.formatMessage(
                    {
                      id: 'PORTFOLIO.GOAL_NAME',
                    },
                    { name }
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex align-items-center bg-red-100 px-2 py-1 border-radius-9">
              <img src={Calendar} alt="Calendar Icon" />
              <div className="ml-2 red-text font-weight-bold font-size-12">
                {moment(createdAt).format('ll')}
              </div>
            </div>
            <div className="text-center font-weight-bold font-size-12">
              {intl.formatMessage({
                id: 'PORTFOLIO.END_DATE',
              })}
            </div>
          </div>
        </div>
        <div className="my-4">
          <GoalTragetAmountProgressBar
            min={0}
            max={goalAmount}
            cuurentValue={
              estimatedBalance
                ? estimatedBalance + depositPendingAmount
                : depositPendingAmount
            }
          />
        </div>
        <GoalAmount
          estimatedBalance={
            estimatedBalance
              ? estimatedBalance + depositPendingAmount
              : depositPendingAmount
          }
          goalAmount={goalAmount}
          currency={currency}
        />
      </div>
    </div>
  );
};

export default injectIntl(SingleGoal);
