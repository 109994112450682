import * as goal from '../../Goal/_redux/goalReducers';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import PlanCard from '../components/PlanCard';
import { fetchPlans } from '../_redux/plansActions';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import PlanTypeComponent from '../components/PlanTypeComponent';
import { FundType } from '../../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const AllPlans = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(false);

  const fetchAllPlans = async () => {
    try {
      setLoading(true);
      const res = await fetchPlans(FundType.INVEST);
      setPlans(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPlans();
  }, []);

  const getThePlans = () => {
    const categories = Object.keys(plans);

    return (
      <>
        {categories?.length > 0 && (
          <>
            {categories.map((category) => {
              return (
                <div key={category} className="mt-5">
                  <PlanTypeComponent
                    planTypeName={category && firstLetterUpperCase(category)}
                    fontSize={'25px'}
                  />

                  <div className="p-3 plans-container px-md-5 px-lg-5">
                    {plans[category] &&
                      plans[category].map((eachRecommendedCash) => (
                        <PlanCard
                          classes="cursor-pointer"
                          data={eachRecommendedCash}
                          key={eachRecommendedCash?.id}
                          planTypeName={firstLetterUpperCase(
                            eachRecommendedCash?.planType?.name
                          )}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <div className="card gutter-b">
          <div className={classes.root}>{getThePlans()}</div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ goal, profile, auth: { authToken } }) => ({
  userRiskProfileId: profile?.riskProfile?.id,
  goal,
  bondPlans: goal.selectedBondPlans,
  cashPlans: goal.selectedCashPlans,
  equityPlans: goal.selectedEquityPlans,
});

export default connect(mapStateToProps, goal.actions)(AllPlans);
