import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as goalActions from '../../Goal/_redux/goalReducers';
import * as pieChartActions from '../_redux/profileReducers';

import GoalPieChart from '../../Common/GoalPieChart';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';
import { getPieChartDataByProfile } from '../_redux/profileaxiosRequest';
import { PageNumbers } from '../../Common/PageNumbers';
import ChangeRiskLevelDiscraimer from '../components/ChangeRiskLevelDiscraimer';
import RiskProfile from '../components/RiskProfile';
import ChangeRiskProfile from '../components/ChangeRiskProfile';

const InvestorProfile = ({ intl, updateGoalDetails, getPieChartData }) => {
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const { goalDetails } = useSelector((state) => state.goal);
  const { pieChartData } = useSelector((state) => state.pieChartData);
  const {
    profile: { riskProfile, riskProfileLevels },
  } = useSelector((state) => state);

  const [riskProfileId, setRiskProfileId] = useState();
  const [riskProfileAlias, setRiskProdileAlias] = useState();
  const [changeRiskLevel, setChangeRiskLevel] = useState(false);

  const generatePieChartData = async () => {
    try {
      const response = await getPieChartDataByProfile(profile.riskProfile.id);
      getPieChartData(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    localStorage.removeItem('retakingWizard');
    profile?.riskProfile?.id && generatePieChartData();
  }, []);

  const generateRiskProfileLevel = () => {
    const currenctRiskProfile =
      riskProfileLevels &&
      riskProfileLevels.find(
        (riskLevel) => riskLevel.id === Number(riskProfile?.id)
      );
    return currenctRiskProfile;
  };

  const handleChange = (event) => {
    const { value } = event?.target;
    const currenctRiskProfile =
      riskProfileLevels &&
      riskProfileLevels.find((riskLevel) => riskLevel.id === Number(value));
    updateGoalDetails({
      ...goalDetails,
      investmentProfile: {
        ...goalDetails.investmentProfile,
        riskProfile: currenctRiskProfile,
      },
    });

    setRiskProfileId(currenctRiskProfile?.id);
    setRiskProdileAlias(currenctRiskProfile?.alias);
  };

  return (
    <>
      <PageTitle title={'Your Investment Personality'} insideContainer />
      {riskProfileLevels?.length ? (
        <div className="mt-25">
          <PageNumbers currentPageNumber={'4'} />
          {changeRiskLevel ? (
            <>
              <ChangeRiskProfile
                riskProfileId={
                  goalDetails?.investmentProfile?.riskProfile?.id
                    ? goalDetails?.investmentProfile?.riskProfile?.id
                    : riskProfileId
                }
                riskProfileAlias={
                  goalDetails?.investmentProfile?.riskProfile?.alias
                    ? goalDetails?.investmentProfile?.riskProfile?.alias
                    : riskProfileAlias
                }
                generateRiskProfileLevel={generateRiskProfileLevel}
                handleChange={handleChange}
              />
              <ChangeRiskLevelDiscraimer
                riskProfile={
                  goalDetails?.investmentProfile?.riskProfile?.alias
                    ? goalDetails?.investmentProfile?.riskProfile?.alias
                    : profile?.riskProfile?.alias
                }
              />
            </>
          ) : null}

          <RiskProfile
            currentRiskLevel={
              goalDetails?.investmentProfile?.riskProfile?.alias
            }
            profile={profile}
          />

          {pieChartData ? (
            <div className="profile-container py-10 px-5 mt-5 text-center">
              <h2 className="chart-title font-weight-bold">
                {intl.formatMessage({ id: 'PROFILE_PAGE.PORTFOLIO' })}
              </h2>
              <div className="chart-sub-title font-weight-bold">
                {intl.formatMessage({ id: 'PROFILE_PAGE.PORTFOLIO_SUBTITLE' })}
              </div>

              <GoalPieChart
                data={[pieChartData['FIXED'], pieChartData['VARIABLE']]}
              />
            </div>
          ) : null}

          <div className="mt-5">
            <Button
              buttonText={intl.formatMessage({ id: 'BUTTONS.NEXT' })}
              onClick={() => {
                setChangeRiskLevel(false);
                history.push(
                  goalDetails && goalDetails?.goalNameIsRequired
                    ? '/portfolio/goal/new'
                    : goalDetails && goalDetails?.name
                    ? '/portfolio/goal/calculator'
                    : '/portfolio/goal/select-goal'
                );
              }}
              classes={'w-100 bg-red white-text font-size-20 py-5'}
            />
          </div>

          <div
            className="red-text font-weight-bold font-size-15 mt-10 text-center "
            onClick={() => setChangeRiskLevel(true)}
          >
            {intl.formatMessage(
              { id: 'PROFILE_PAGE.CHANGE_RISK_PROFILE' },
              {
                riskProfile: goalDetails?.investmentProfile?.riskProfile?.alias
                  ? goalDetails?.investmentProfile?.riskProfile?.alias
                  : riskProfileAlias,
              }
            )}
          </div>

          <p className="font-size-11 mt-5 text-justify">
            {intl.formatMessage({ id: 'PROFILE_PAGE.DISCLAIMER' })}
          </p>
        </div>
      ) : null}
    </>
  );
};
export default injectIntl(
  connect(null, { ...goalActions.actions, ...pieChartActions.actions })(
    InvestorProfile
  )
);
