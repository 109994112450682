import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchCurrencies } from './currencyActions';

export const actionTypes = {
  Currency: '[Currecy] Action',
  FetchCurrencies: '[FetchCurrencies] Action',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.Currency: {
      const { payload } = action;
      return {
        ...state,
        currencies: payload,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  currencyAction: (currencies) => ({
    type: actionTypes.Currency,
    payload: currencies,
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.FetchCurrencies,
    function* fetchCurrrencyAction() {
      const {
        data: { data },
      } = yield call(fetchCurrencies);
      yield put(actions.currencyAction(data));
    }
  );
}
