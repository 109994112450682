import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import * as referral from '../_redux/ReferalReducer';
import sendReferalCode from '../_redux/ReferalAction';
import { onboardingCopy } from '../../../utils/onboardingCopy';

import Button from '../../Common/Button';
import Input from '../../Common/Input/Input';
import RiskProfileImage from './RiskProfileImage';
import Alert from '../../Common/Alert';
import elephantWithDollars from '../../../images/elephantWithDolars.svg';

import '../index.scss';

function OfferCodes(props) {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [skipLoading, setSkipLoading] = useState(false);

  const initialValues = {
    referralCode: '',
    voucher: '',
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const referalCodeSchema = Yup.object().shape({
    referralCode: Yup.string()
      .min(8, 'Minimum 8 digits')
      .max(8, 'Maximum 8 digits'),
  });

  const handleSkip = async () => {
    const referralCode = null;
    try {
      setSkipLoading(true);
      const response = await sendReferalCode(referralCode);

      if (response.status === 200) {
        setSkipLoading(false);
        localStorage.setItem('referralCompleted', true);
        history.push('/onboarding/instructions');
        return;
      } else {
        setSkipLoading(false);
        return;
      }
    } catch (error) {
      setSkipLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      return;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: referalCodeSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const referralCodeUpperCase = values.referralCode.toUpperCase();
      try {
        const response = await sendReferalCode(referralCodeUpperCase);

        if (response.status === 200) {
          setLoading(false);
          formik.resetForm();
          localStorage.setItem('referralCompleted', true);
          history.push('/onboarding/instructions');
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'alert-light-danger',
        });
      }
    },
  });

  return (
    <>
      <div className="container text-center referal-container">
        {alertOn ? <div className="mb-5"></div> : null}
        <div>
          <div className="text-center">
            <RiskProfileImage image={elephantWithDollars} />

            <span className="bonus-text">{onboardingCopy?.referal?.title}</span>
            <br />
            <span className="font-weight-bolder font-size-16">
              {onboardingCopy?.referal?.subTitle}
            </span>
          </div>
          <div className="mt-3 font-size-16">
            {onboardingCopy?.referal?.description}
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <div className="form-group fv-plugins-icon-container">
              <div className="mx-20 mt-10">
                <Input
                  placeholder="Referral Code"
                  type="text"
                  className={`form-control input-field ${getInputClasses(
                    'referralCode'
                  )}`}
                  name="referralCode"
                  formik={formik}
                />
                <Alert alert={alert} />
                {formik.touched.referralCode && formik.errors.referralCode ? (
                  <div className="fv-plugins-message-container">
                    <div className="text-center fv-help-block">
                      {formik.errors.referralCode}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="my-10 d-flex justify-content-between">
              <button type="button" onClick={handleSkip} className="skip-btn">
                Skip
                {skipLoading && <span className="ml-3 spinner spinner-white" />}
              </button>

              <Button
                loading={loading}
                btnText="Use Code"
                className="py-3 pl-8 pr-10 common-btn"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>

      {/* </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  referral: state.referral,
});

export default connect(mapStateToProps, referral.actions)(OfferCodes);
