import React from 'react';

const BankDetails = ({ account }) => {
  return (
    <div className="pb-5 pt-3">
      <div className="d-flex justify-content-between">
        <span>Account Number:</span>
        <span className="font-weight-bold">
          {account?.accountNumber ? account.accountNumber : '---'}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span>Account Name:</span>
        <span className="font-weight-bold">
          {account?.accountName ? account.accountName : '---'}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span>Bank Name:</span>
        <span className="font-weight-bold">
          {account?.bankName ? account.bankName : '---'}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span>Bank Branch:</span>
        <span className="font-weight-bold">
          {account?.bankBranch ? account.bankBranch : '---'}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span>Swift Code:</span>
        <span className="font-weight-bold">
          {account?.swiftCode ? account.swiftCode : '---'}
        </span>
      </div>
    </div>
  );
};

export default BankDetails;
